import { useQueryMe } from '@api/hooks/auth/authQuery';
import CustomPagination from '@utils/components/CustomPagination/CustomPagination';
import TableLoader from '@utils/components/TableLoader/TableLoader';
import TableRowsShownSelect from '@utils/components/TableRowsShownSelect/TableRowsShownSelect';
import UserDefaultAvatar from '@utils/components/UserDefaultAvatar/UserDefaultAvatar';
import { useActiveProject } from '@utils/hooks/useActiveProject';
import { usePaginate } from '@utils/hooks/usePaginate';
import { useState } from 'react';
import DataTable from 'react-data-table-component';
import { ChevronDown, Edit2, MoreVertical, Trash } from 'react-feather';
import {
  Button,
  Card,
  CardBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';
import { useGetProjectMembers } from '../hooks/useGetProjectMembers';
import { InviteUserModal, RemoveMemberModal, UpdateRoleModal } from './modals';
import { useTranslation } from 'react-i18next';
import BlockRemovingMemberModal from './modals/blockRemovingMember/BlockRemovingMemberModal';

const MODALS = {
  INVITE_USER: 'INVITE_USER',
  REMOVE_USER: 'REMOVE_USER',
  BLOCK_REMOVING_USER: 'BLOCK_REMOVING_USER',
  UPDATE_USER_ROLE: 'UPDATE_USER_ROLE',
};

const UsersAndPermissions = () => {
  const { t } = useTranslation();
  const { data: userStore } = useQueryMe();
  const { pagination, onPerPage, onPageChange } = usePaginate({ perPage: 10 });
  const [currentModal, setCurrentModal] = useState(null);
  const [selectedMember, setSelectedMember] = useState(null);
  const projectUuid = useActiveProject();

  const {
    isLoading,
    data: projectMembers,
    refetch,
  } = useGetProjectMembers({
    activeProject: projectUuid,
    skip: pagination.skip,
    rowsPerPage: pagination.rowsPerPage,
  });

  const handleSelectMember = (value) => {
    setSelectedMember({
      firstName: value.firstName,
      lastName: value.lastName,
      email: value.email,
      role: {
        label: value.roleName,
        value: value.roleUuid,
      },
      uuid: value.uuid,
      status: value.status,
    });
  };

  const updateRoleClicked = (member) => {
    handleSelectMember(member);
    setCurrentModal(MODALS.UPDATE_USER_ROLE);
  };

  const removeMemberClicked = (member) => {
    handleSelectMember(member);

    if (member.isOrganizationOwner) setCurrentModal(MODALS.BLOCK_REMOVING_USER);
    else setCurrentModal(MODALS.REMOVE_USER);
  };

  const handleAddNewMember = () => {
    setCurrentModal(MODALS.INVITE_USER);
  };

  const handleCloseModal = () => {
    setCurrentModal(null);
  };

  return (
    <Row className="m-0 mt-2">
      <Card className="p-0 py-2">
        <CardBody className="p-0">
          <div className="m-0 d-flex px-2 justify-content-between">
            <TableRowsShownSelect onPerPage={onPerPage} rowsPerPage={pagination.rowsPerPage} />
            <div className="d-flex align-items-center table-header-actions">
              <Button color="primary" onClick={handleAddNewMember} data-testid="add-member-button">
                {t('projects.addNewUser')}
              </Button>
            </div>
          </div>
          <div className="react-dataTable mt-2">
            <DataTable
              sortServer
              pagination
              responsive
              paginationServer
              columns={[
                {
                  name: 'NAME',
                  sortable: true,
                  width: '50%',
                  sortField: 'name',
                  selector: (row) => row.uuid,
                  cell: (row) => (
                    <div className="d-flex justify-content-start">
                      <UserDefaultAvatar name={row.firstName || row.email} style={{ height: '40px', width: '40px' }} />
                      <div className="ms-2">
                        <p className="m-0">
                          {row.uuid === userStore.uuid ? (
                            <span className="fw-bolder">
                              {row.firstName} {row.lastName} (You)
                            </span>
                          ) : (
                            <span className="fw-bold">
                              {row.firstName} {row.lastName}
                            </span>
                          )}
                        </p>
                        <p className="user-status m-0">{row.email}</p>
                      </div>
                    </div>
                  ),
                },
                {
                  name: 'ROLE',
                  width: '30%',
                  sortable: true,
                  sortField: 'roleName',
                  selector: (row) => row.roleName,
                  cell: (row) => <div className="d-flex justify-content-left align-items-center">{row.roleName}</div>,
                },
                {
                  name: 'ACTIONS',
                  width: '20%',
                  selector: (row) => row.uuid,
                  cell: (row) => {
                    if (row.uuid === userStore?.uuid) return <></>;
                    return (
                      <div className="d-flex justify-content-end align-items-center position-absolute">
                        <UncontrolledDropdown className="dropdown-user" data-testid="project-member-dropdown">
                          <DropdownToggle
                            href="/"
                            tag="a"
                            className="dropdown-user-link"
                            onClick={(e) => e.preventDefault()}
                          >
                            <MoreVertical />
                          </DropdownToggle>
                          <DropdownMenu end>
                            <DropdownItem
                              onClick={() => updateRoleClicked(row)}
                              className="w-100"
                              data-testid="project-member-edit-button"
                            >
                              <Edit2 />
                              <span className="align-middle ms-1">{t('projects.changeRole')}</span>
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => removeMemberClicked(row)}
                              className="w-100"
                              data-testid="project-member-remove-button"
                            >
                              <Trash />
                              <span className="align-middle ms-1">{t('projects.removeFromProject')}</span>
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                    );
                  },
                },
              ]}
              sortIcon={<ChevronDown />}
              className="react-dataTable overflow-visible"
              paginationComponent={() => {
                <CustomPagination
                  currentPage={pagination.currentPage}
                  pageCount={Number(Math.ceil(projectMembers.length / pagination.rowsPerPage))}
                  onPageChange={onPageChange}
                />;
              }}
              progressPending={isLoading}
              progressComponent={<TableLoader />}
              data={projectMembers?.data}
            />
          </div>
        </CardBody>
      </Card>
      {currentModal === MODALS.UPDATE_USER_ROLE && (
        <UpdateRoleModal
          isOpen
          toggle={handleCloseModal}
          member={selectedMember}
          refetchOrganizationMembers={refetch}
        />
      )}
      {currentModal === MODALS.BLOCK_REMOVING_USER && (
        <BlockRemovingMemberModal isOpen toggle={handleCloseModal} member={selectedMember} />
      )}
      {currentModal === MODALS.REMOVE_USER && (
        <RemoveMemberModal
          isOpen
          toggle={handleCloseModal}
          member={selectedMember}
          refetchOrganizationMembers={refetch}
        />
      )}
      {currentModal === MODALS.INVITE_USER && (
        <InviteUserModal isOpen toggle={handleCloseModal} refetchOrganizationMembers={refetch} />
      )}
    </Row>
  );
};

export default UsersAndPermissions;
