import { DeveloperApiToken } from '@api/hooks/organization/types';
import { TextCell, UserCell } from '@utils/components/cells';
import { useMemo } from 'react';
import moment from 'moment';
import { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';

type Props = {
  onDeactivateToken: (token: DeveloperApiToken['tokenId']) => void;
};

export function useColumns({ onDeactivateToken }: Props): TableColumn<DeveloperApiToken>[] {
  const { t } = useTranslation();

  return useMemo<TableColumn<DeveloperApiToken>[]>(
    () => [
      {
        name: t('organizations.settings.apiTokens.tokenId'),
        grow: 2,
        selector: (row) => row.tokenId,
        cell: (row) => <TextCell text={row.tokenId} />,
      },
      {
        name: t('organizations.settings.apiTokens.createdBy'),
        selector: (row) => row.tokenId,
        grow: 2,
        cell: ({ createdBy }) => (
          <UserCell
            user={{
              email: createdBy.email,
              firstName: createdBy.firstName,
              lastName: createdBy.lastName,
            }}
          />
        ),
      },
      {
        name: t('organizations.settings.apiTokens.creationDate'),
        grow: 2,
        selector: (row) => row.createdBy.createdAt,
        cell: ({ createdBy }) => <TextCell text={moment(createdBy.createdAt).format('LLLL')} />,
      },
      // {
      //   name: t('organizations.settings.apiTokens.active'),
      //   selector: (row) => row.active,
      //   cell: ({ active }) => (
      //     <TextCell
      //       text={
      //         active ? t('organizations.settings.apiTokens.active') : t('organizations.settings.apiTokens.inactive')
      //       }
      //     />
      //   ),
      // },
      {
        name: t('organizations.settings.apiTokens.actions'),
        cell: ({ tokenId, active }) =>
          active && (
            <div className="py-2">
              <Button
                color="outline-danger"
                onClick={() => onDeactivateToken(tokenId)}
                data-testid="api-token-deactivate-button"
              >
                {t('organizations.settings.apiTokens.deactivate')}
              </Button>
            </div>
          ),
      },
    ],
    [onDeactivateToken, t]
  );
}
