import SpinnerComponent from '@components/spinner/Fallback-spinner';
import { useActiveOrganization } from '@utils/hooks/useActiveOrganization';
import { useActiveProject } from '@utils/hooks/useActiveProject';
import { Outlet } from 'react-router-dom';
import { useValidateProject } from './hooks/useValidateProject';

function ProjectRoutes() {
  const selectedProjectUuid = useActiveProject();
  const organizationUuid = useActiveOrganization();

  const { isFetching } = useValidateProject();

  if (!selectedProjectUuid || !organizationUuid || isFetching) {
    return <SpinnerComponent />;
  }

  return (
    <>
      <Outlet />
    </>
  );
}

export default ProjectRoutes;
