import { useIsAuthorized } from '@utils/hooks/useIsAuthorized';
import { Navigate } from 'react-router-dom';
import { PATHS } from '../paths';

function ProtectedRoute({ children }) {
  const isLogged = useIsAuthorized();

  if (!isLogged) {
    return <Navigate to={PATHS.AUTH.LOGIN.FULL_PATH} />;
  }

  return <>{children}</>;
}

export default ProtectedRoute;
