import { useQueryMe } from '@api/hooks/auth/authQuery';
import UserDefaultAvatar from '@utils/components/UserDefaultAvatar/UserDefaultAvatar';
import FormActionsNext from '@utils/components/form/FormActions/FormActionsNext';
import { useOrganizationRoles } from '@utils/hooks/dictionaries';
import { useWizardFormStepperContext } from '@utils/context/WizardFormStepperContext';
import { useMemo } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import OrganizationMemberPicker from '@src/views/pages/organizations/create-organization/steps/usersStep/components/OrganizationMemberPicker/OrganizationMemberPicker';

const UsersStep = ({ stepInfo }) => {
  const { t } = useTranslation();
  const { data: userStore } = useQueryMe();
  const { data: organizationRoles } = useOrganizationRoles();
  const { onNextStep, onPrevStep } = useWizardFormStepperContext();
  const { control } = useFormContext();

  const handleNext = () => {
    onNextStep(stepInfo.fields);
  };

  const { append, fields: currentMembers } = useFieldArray({
    control,
    name: 'members',
  });

  const defaultUserOwnershipRole = useMemo(() => {
    return organizationRoles.find((role) => role.label.toLowerCase() === 'owner');
  }, [organizationRoles]);

  const handleAddUser = (data) => {
    append(data);
  };

  return (
    <>
      <div className="modal-form-content">
        <OrganizationMemberPicker onSubmit={handleAddUser} />

        <Row className="mt-2 mb-5">
          <Row>
            <h5>{t('organizations.createOrganization.users.members')}</h5>
          </Row>
          {userStore.email && defaultUserOwnershipRole && (
            <div className="d-flex justify-content-start mb-2">
              <UserDefaultAvatar
                name={userStore.firstName || userStore.email}
                style={{ height: '40px', width: '40px' }}
              />
              <Row className="w-100">
                <Col xs={6}>
                  <div className="ms-2">
                    <span className="fw-bolder">
                      {userStore.firstName} {userStore.lastName} {t('general.yourAccount')}
                    </span>
                    <br />
                    <p className="user-status m-0">{userStore.email}</p>
                  </div>
                </Col>
                <Col xs={4} className="d-flex align-items-center text-right">
                  <span className="user-status">{defaultUserOwnershipRole.label}</span>
                </Col>
              </Row>
            </div>
          )}
          {currentMembers.map((member) => (
            <Row
              key={member.id}
              className="mb-2 mx-0"
              style={{ paddingLeft: 55 }}
              data-testid={`invited-member-${member.id}`}
            >
              <Col xs={6} className="ps-2">
                <Row>
                  <span className="user-name fw-bold">
                    {member.firstName} {member.lastName}
                  </span>
                </Row>
                <span className="user-status">{member.email}</span>
              </Col>
              <Col xs={4} className="d-flex align-items-center text-right">
                <span className="user-status">{member.role.label}</span>
              </Col>
            </Row>
          ))}
        </Row>
      </div>
      <FormActionsNext onPrevious={onPrevStep} onNext={handleNext} />
    </>
  );
};

export default UsersStep;
