import Wizard from '@components/wizard';
import '@styles/react/pages/modal-create-app.scss';
import { useMemo, useRef, useState } from 'react';
import { Check, CreditCard, Home, Users } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { BillingStep, DetailsStep, SubmitStep, UsersStep } from './steps';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { createOrganizationSchema } from './schema';
import { WizardFormStepperContextProvider } from '@utils/context/WizardFormStepperContext';

const stepsInfo = [
  { step: 1, fields: ['organizationName'] },
  { step: 2, fields: ['companyName', 'taxId', 'vatNumber', 'country', 'billingAddress', 'state', 'zipCode'] },
  { step: 3, fields: ['members'] },
  { step: 4, fields: [] },
];

const CreateOrganization = ({ toggle, refetchOrganizations }) => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const [stepper, setStepper] = useState(null);
  const methods = useForm({
    defaultValues: {
      organizationName: '',
      companyName: '',
      taxId: '',
      vatNumber: '',
      country: '',
      billingAddress: '',
      state: '',
      zipCode: '',
      members: [],
    },
    resolver: yupResolver(createOrganizationSchema),
    mode: 'onChange',
  });

  const steps = useMemo(
    () => [
      {
        id: 'details',
        title: t('organizations.createOrganization.details.title'),
        subtitle: t('organizations.createOrganization.details.subtitle'),
        icon: <Home className="font-medium-3" />,
        content: <DetailsStep stepInfo={stepsInfo[0]} />,
      },
      {
        id: 'billing',
        title: t('organizations.createOrganization.billing.title'),
        subtitle: t('organizations.createOrganization.billing.subtitle'),
        icon: <CreditCard className="font-medium-3" />,
        content: <BillingStep stepInfo={stepsInfo[1]} />,
      },
      {
        id: 'users',
        title: t('organizations.createOrganization.users.title'),
        subtitle: t('organizations.createOrganization.users.subtitle'),
        icon: <Users className="font-medium-3" />,
        content: <UsersStep stepInfo={stepsInfo[2]} />,
      },
      {
        id: 'submit',
        title: t('organizations.createOrganization.submit.title'),
        subtitle: t('organizations.createOrganization.submit.subtitle'),
        icon: <Check className="font-medium-3" />,
        content: <SubmitStep toggle={toggle} refetchOrganizations={refetchOrganizations} />,
      },
    ],
    [refetchOrganizations, t, toggle]
  );

  return (
    <Modal toggle={toggle} isOpen className="modal-dialog-centered" style={{ maxWidth: 1000 }}>
      <ModalHeader className="bg-transparent" toggle={toggle}></ModalHeader>
      <ModalBody className="pb-3 px-sm-3">
        <FormProvider {...methods}>
          <WizardFormStepperContextProvider stepper={stepper} steps={stepsInfo}>
            <h1 className="text-center mb-1">{t('organizations.createOrganization.title')}</h1>
            <p className="text-center mb-2">{t('organizations.createOrganization.subtitle')}</p>
            <Wizard
              ref={ref}
              steps={steps}
              type="vertical"
              headerClassName="border-0"
              options={{ linear: false }}
              instance={(el) => setStepper(el)}
              contentClassName="shadow-none"
              className="bg-transparent create-app-wizard shadow-none border-0"
            />
          </WizardFormStepperContextProvider>
        </FormProvider>
      </ModalBody>
    </Modal>
  );
};

export default CreateOrganization;
