import { useId, useState } from 'react';
import { Tooltip as ReactstrapTooltip, TooltipProps } from 'reactstrap';

interface Props extends Omit<TooltipProps, 'target' | 'toggle'> {
  children: React.ReactNode;
  text: string;
}

function Tooltip({ children, text, ...rest }: Props) {
  const id = useId();

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <>
      <div id={id}>{children}</div>
      <ReactstrapTooltip {...rest} isOpen={tooltipOpen} target={`#${CSS.escape(id)}`} toggle={toggle}>
        {text}
      </ReactstrapTooltip>
    </>
  );
}
export default Tooltip;
