import { Card, CardBody } from 'reactstrap';
import themeConfig from '@configs/themeConfig';

function CardContent({ children }) {
  return (
    <div className="activation-card-content" style={{ minWidth: 450, minHeight: 350 }}>
      <Card className="p-1">
        <img src={themeConfig.app.appLogoFullImage} alt="logo" height={50} className="mt-2 mb-2" />
        <CardBody>{children}</CardBody>
      </Card>
    </div>
  );
}

export default CardContent;
