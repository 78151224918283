import { useQueryProjects } from '@api/hooks/project/projectQuery';
import { handleSelectProject } from '@store/organizationsProjects';
import { useActiveOrganization } from '@utils/hooks/useActiveOrganization';
import { useProjectStore } from '@utils/hooks/useProjectStore';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export function useValidateProject() {
  const { onReadProject, onClearProject } = useProjectStore();
  const projectUuid = onReadProject();
  const dispatch = useDispatch();

  const organizationUuid = useActiveOrganization();
  const navigate = useNavigate();

  const result = useQueryProjects(
    {
      organizationUuid,
    },
    { enabled: !!organizationUuid && !!projectUuid }
  );

  useEffect(() => {
    if (result.isFetching) return;

    const project = result.data?.data.find((project) => project.uuid === projectUuid);
    if (!project) {
      navigate('/projects');
      onClearProject();
    } else {
      dispatch(handleSelectProject({ projectUuid }));
    }
  }, [dispatch, navigate, onClearProject, projectUuid, result.data, result.isFetching]);

  return result;
}
