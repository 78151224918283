/**
 *
 * @param {moment.Moment} date
 */
export const getPeriodOption = (date) => {
  const utcDate = date.utc().clone();

  return {
    label: utcDate.format('MMMM yyyy'),
    value: {
      from: utcDate.startOf('month').toISOString(),
      to: utcDate.endOf('month').toISOString(),
    },
  };
};
