import { useState } from 'react';
import { Row, Button } from 'reactstrap';
import { Cloud, Lock } from 'react-feather';
import Breadcrumbs from '@components/breadcrumbs';
import UsersAndPermissions from './usersAndPermissions';
import General from './tabs/general';

const ProjectSettings = () => {
  const [currentTab, setCurrentTab] = useState('general');

  const handleSetTab = async (value) => {
    setCurrentTab(value);
  };

  return (
    <div className="px-4">
      <Breadcrumbs title={''} data={[{ title: 'Project' }, { title: 'Settings' }]} />
      <Row className="m-0 d-flex justify-content-start">
        <Button
          className="w-auto"
          color={`${currentTab === 'general' ? 'primary' : 'transparent'}`}
          onClick={() => handleSetTab('general')}
        >
          <Cloud /> General
        </Button>
        <Button
          className="w-auto"
          color={`${currentTab === 'users-permissions' ? 'primary' : 'transparent'}`}
          onClick={() => handleSetTab('users-permissions')}
          data-testid="users-and-permissions-tab"
        >
          <Lock /> Users & Permissions
        </Button>
      </Row>
      {currentTab === 'general' && <General />}
      {currentTab === 'users-permissions' && <UsersAndPermissions />}
    </div>
  );
};

export default ProjectSettings;
