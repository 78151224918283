import InputPasswordToggle from '@components/input-password-toggle';
import { PATHS } from '@src/router/paths';
import { ChevronRight } from 'react-feather';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Col, FormFeedback, Input, Label, Row } from 'reactstrap';
import { errorMessage } from '../../../utility/errors/errorMessages';

function RegisterAccountStep({ stepper }) {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { t } = useTranslation();

  return (
    <>
      <div className="content-header mb-2">
        <h2>{t('registration.accessCode')}</h2>
        <span className="text-muted">{t('registration.enterAccessCode')}</span>
      </div>
      <Col md="12" lg="6">
        <div className="mb-1">
          <Label className="form-label" for="register-accesscode">
            {t('registration.accessCode')}
          </Label>
          <Controller
            id="register-accesscode"
            name="accessCode"
            control={control}
            render={({ field }) => (
              <Input
                autoFocus
                autoComplete="off"
                placeholder="XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX"
                invalid={errors.accessCode}
                {...field}
              />
            )}
          />
          {errors.accessCode ? <FormFeedback>{errorMessage(t, errors.accessCode.message)}</FormFeedback> : null}
        </div>
      </Col>
      <div className="content-header mb-2">
        <h2>{t('registration.accountDetails')}</h2>
        <span className="text-muted">{t('registration.enterAccountDetails')}</span>
      </div>
      <Row>
        <Col md="12" lg="12">
          <div className="mb-1">
            <Label className="form-label" for="register-email">
              {t('general.email')}
            </Label>
            <Controller
              id="register-email"
              name="emailAddress"
              control={control}
              render={({ field }) => (
                <Input autoComplete="off" placeholder="john@example.com" invalid={errors.emailAddress} {...field} />
              )}
            />
            {errors.emailAddress ? <FormFeedback>{errorMessage(t, errors.emailAddress.message)}</FormFeedback> : null}
          </div>
        </Col>
        <Col md="12" lg="6">
          <div className="mb-1">
            <Label className="form-label" for="register-password">
              {t('general.password')}
            </Label>
            <Controller
              id="register-password"
              name="password"
              control={control}
              render={({ field }) => (
                <InputPasswordToggle
                  autoComplete="off"
                  className="input-group-merge"
                  invalid={errors.password}
                  {...field}
                />
              )}
            />
            {errors.password ? <FormFeedback>{errorMessage(t, errors.password.message)}</FormFeedback> : null}
          </div>
        </Col>
        <Col md="12" lg="6">
          <div className="mb-1">
            <Label className="form-label" for="register-confirm-password">
              {t('general.confirmPassword')}
            </Label>
            <Controller
              id="register-confirm-password"
              name="confirmPassword"
              control={control}
              render={({ field }) => (
                <InputPasswordToggle
                  autoComplete="off"
                  className="input-group-merge"
                  invalid={errors.confirmPassword}
                  {...field}
                />
              )}
            />
            {errors.confirmPassword ? (
              <FormFeedback>{errorMessage(t, errors.confirmPassword.message)}</FormFeedback>
            ) : null}
          </div>
        </Col>
      </Row>
      <div className="d-flex justify-content-end mt-2">
        <Button color="primary" className="btn-next" onClick={() => stepper.next()}>
          <span className="align-middle d-sm-inline-block">{t('general.nextButton')}</span>
          <ChevronRight size={14} className="align-middle ms-sm-25 ms-0"></ChevronRight>
        </Button>
      </div>
      <p className="d-flex justify-content-end mt-2">
        <span className="me-25">{t('registration.alreadyHaveAnAccount')}</span>
        <Link to={PATHS.AUTH.LOGIN.FULL_PATH}>
          <span>{t('registration.signInInstead')}</span>
        </Link>
      </p>
    </>
  );
}
export default RegisterAccountStep;
