import { useTranslation } from 'react-i18next';
import FormActions from './FormActions';

/**
 * @typedef {object} Props
 * @property {void} props.onSubmit
 * @property {boolean} props.isLoading = false
 * @property {boolean} disabled = false
 */

/**
 * @param {Omit<import('./types').FormActionProps, 'onNext'> & Props} props
 * @returns {JSX}
 */
function FormActionsFinish({ onSubmit, disabled = false, isLoading, ...rest }) {
  const { t } = useTranslation();

  return (
    <FormActions
      {...rest}
      nextLabel={t('Finish')}
      onNext={onSubmit}
      nextProps={{
        ...rest.nextProps,
        isLoading,
        disabled,
      }}
    />
  );
}

export default FormActionsFinish;
