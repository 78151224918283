// eslint-disable-next-line unused-imports/no-unused-imports, @typescript-eslint/no-unused-vars
import { UseQueryResult } from '@tanstack/react-query';
import { useGetProjectRolesDictionary } from '@api/hooks/dictionaries';

/**
 *
 * @returns {UseQueryResult<SelectOptionWithDescription[]>}
 */
export function useProjectRoles() {
  return useGetProjectRolesDictionary({
    select(data) {
      return data.map((projectRole) => ({
        label: projectRole.name,
        value: projectRole.uuid,
        description: projectRole.description,
      }));
    },
  });
}
