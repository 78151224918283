import { useMutation, useQuery } from '@tanstack/react-query';
import useProjectApi from '../../useProjectApi';

export const PROJECTS_QUERY_KEY = ({ organizationUuid }) => ['projects', organizationUuid];
export function useQueryProjects({ organizationUuid }, options) {
  return useQuery({
    queryKey: PROJECTS_QUERY_KEY({ organizationUuid }),
    queryFn: async () => {
      const result = await useProjectApi.getMyProjects(organizationUuid);
      return result.data;
    },
    staleTime: 1000 * 60 * 5,
    ...options,
  });
}

export function useQueryProjectUsers({ activeProject, skip, rowsPerPage }, options) {
  return useQuery({
    queryKey: ['projectUsers', activeProject, skip, rowsPerPage],
    queryFn: async () => {
      const result = await useProjectApi.getProjectMembers(activeProject, {
        params: { skip, rowsPerPage },
      });
      return result.data;
    },
    ...options,
  });
}

export function useMutateCreateProject(options) {
  return useMutation(async (body) => {
    const result = await useProjectApi.createProject(body);

    return result.data;
  }, options);
}

export function useMutateUpdateProject(options) {
  return useMutation(async ({ projectUuid, name, regionUuids }) => {
    const result = await useProjectApi.updateProject(projectUuid, {
      name,
      regionUuids,
    });

    return result.data;
  }, options);
}

export function useMutateDeleteProject(options) {
  return useMutation(async ({ projectUuid }) => {
    const result = await useProjectApi.deleteProject(projectUuid);

    return result.data;
  }, options);
}

export function useMutateInviteMember(options = {}) {
  return useMutation(async ({ projectUuid, emailAddress, roleUuid }) => {
    const result = await useProjectApi.inviteToProject(projectUuid, {
      emailAddress,
      roleUuid,
    });

    return result.data;
  }, options);
}

export function useMutateRemoveMember(options = {}) {
  return useMutation(async ({ projectUuid, emailAddress }) => {
    const result = await useProjectApi.removeProjectMembers(projectUuid, emailAddress);

    return result.data;
  }, options);
}

export function useMutateUpdateMemberRole(options = {}) {
  return useMutation(async ({ projectUuid, emailAddress, roleUuid }) => {
    const result = await useProjectApi.updateRoleInProject(projectUuid, {
      emailAddress,
      roleUuid,
    });

    return result.data;
  }, options);
}
