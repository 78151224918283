import { yupResolver } from '@hookform/resolvers/yup';
import { UserPlus } from 'react-feather';
import { useForm } from 'react-hook-form';
import { Button, Col, Row } from 'reactstrap';
import { addMemberSchema } from './schema';
import { useOrganizationRoles } from '@utils/hooks/dictionaries';
import { useTranslation } from 'react-i18next';
import { Input, Select } from '@utils/components/form/Inputs';

function OrganizationMemberPicker({ onSubmit }) {
  const { data: organizationRoles, isLoading: isOrganizationRolesLoading } = useOrganizationRoles();
  const { t } = useTranslation();

  const {
    control: inputControl,
    handleSubmit: onAddMember,
    formState: addMemberFormState,
    reset,
  } = useForm({
    defaultValues: {
      email: '',
      role: null,
    },
    resolver: yupResolver(addMemberSchema),
    mode: 'onChange',
  });

  const handleAddMember = (data) => {
    onSubmit({
      email: data.email,
      role: data.role,
    });
    reset();
  };

  return (
    <form onSubmit={onAddMember(handleAddMember)}>
      <Row>
        <Col sm={6}>
          <Input control={inputControl} name="email" placeholder={t('general.email')} label={t('general.addMembers')} />
        </Col>
        <Col sm={5} className="pe-0">
          <Select
            control={inputControl}
            name="role"
            options={organizationRoles}
            isLoading={isOrganizationRolesLoading}
            isClearable={false}
            label={t('general.selectRole')}
          />
        </Col>
        <Col sm={1} className="d-flex align-items-end">
          <Button
            type="submit"
            outline
            className="px-1"
            disabled={!addMemberFormState.isValid}
            color="primary"
            data-testid="organization-invite-user"
          >
            <UserPlus height="18px" className="text-primary" />
          </Button>
        </Col>
      </Row>
    </form>
  );
}

export default OrganizationMemberPicker;
