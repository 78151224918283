// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit';
import { isUserLoggedIn } from '@utils/Utils';

export const authorizationSlice = createSlice({
  name: 'authorization',
  initialState: {
    isAuthorized: !!isUserLoggedIn(),
  },
  reducers: {
    handleAuthorize: (state) => {
      state.isAuthorized = true;
    },
    handleUnauthorize: (state) => {
      state.isAuthorized = false;
    },
  },
});

export const { handleAuthorize, handleUnauthorize } = authorizationSlice.actions;

export default authorizationSlice.reducer;
