export const APPLICATION_PLATFORM = {
  IOS: 'iOS',
  ANDROID: 'Android',
  WEB: 'web',
  UNITY: 'Unity',
} as const;

export const APPLICATION_TYPE = {
  MOBILE: 'mobile',
  WEB: 'web',
} as const;
