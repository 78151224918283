import { useQueryDeveloperApiToken } from '@api/hooks/organization/organizationQuery';
import { DeveloperApiToken } from '@api/hooks/organization/types';
import { UseQueryOptions } from '@api/types';
import { UseQueryResult } from '@tanstack/react-query';
import { useActiveOrganization } from '@utils/hooks/useActiveOrganization';

export function useGetOrganizationAPITokens(
  options?: UseQueryOptions<DeveloperApiToken[]>
): UseQueryResult<DeveloperApiToken[]> {
  const organizationUuid = useActiveOrganization();

  return useQueryDeveloperApiToken({ organizationUuid }, options) as UseQueryResult<DeveloperApiToken[]>;
}
