import { useState, createContext } from 'react';
import UILoader from '@components/ui-loader';
import './overlay.scss';

const ContentOverlayContext = createContext();
// Overlay for main screen (excluding sidebar)
const ContentOverlayContextProvider = ({ children }) => {
  const [showLoadingOverlay, setShowLoadingOverlay] = useState(false);

  return (
    <ContentOverlayContext.Provider value={{ showLoadingOverlay, setShowLoadingOverlay }}>
      {showLoadingOverlay && <UILoader blocking className="content-overlay" />}
      {children}
    </ContentOverlayContext.Provider>
  );
};

export { ContentOverlayContext, ContentOverlayContextProvider };
