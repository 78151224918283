import { useQueryOrganization } from '@api/hooks/organization/organizationQuery';
import { useQueryProjects } from '@api/hooks/project/projectQuery';
import { useContentOverlayContext } from '@utils/context/ContentOverlayContext/useContentOverlayContext';
import { useActiveOrganization } from '@utils/hooks/useActiveOrganization';
import { useActiveProject } from '@utils/hooks/useActiveProject';
import { useEffect, useMemo } from 'react';

export function useGetProjectAndOrganization() {
  const organizationUuid = useActiveOrganization();
  const projectUuid = useActiveProject();
  const { isFetching: isProjectsFetching, data: projectsData } = useQueryProjects({
    organizationUuid,
  });
  const { isFetching: isOrganizationFetching, data: organizationData } = useQueryOrganization({
    organizationUuid,
  });

  const [showLoading, hideLoading] = useContentOverlayContext();

  useEffect(() => {
    if (isProjectsFetching || isOrganizationFetching) {
      showLoading();
    } else {
      hideLoading();
    }
  }, [hideLoading, isOrganizationFetching, isProjectsFetching, showLoading]);

  const currentProject = useMemo(() => {
    return projectsData?.data?.find((project) => project.uuid === projectUuid);
  }, [projectsData, projectUuid]);

  return {
    project: currentProject,
    organization: organizationData,
  };
}
