import SpinnerComponent from '@components/spinner/Fallback-spinner';
import { useActiveOrganization } from '@utils/hooks/useActiveOrganization';
import { Outlet } from 'react-router-dom';
import { useValidateOrganization } from './hooks/useValidateOrganization';

function OrganizationRoutes() {
  const { isFetching } = useValidateOrganization();
  const organizationUuid = useActiveOrganization();

  if (!organizationUuid || isFetching) {
    return <SpinnerComponent />;
  }

  return (
    <>
      <Outlet />
    </>
  );
}

export default OrganizationRoutes;
