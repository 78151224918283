/**
 *
 * @param {number | undefined} status
 * @returns {string}
 */
export const getErrorMessageTranslationKey = (status = 0) => {
  switch (true) {
    case status === 403:
      return 'apiError.forbidden';

    case status >= 500:
      return 'apiError.serverError';

    default:
      return false;
  }
};
