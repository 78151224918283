import { useQueryMe } from '@api/hooks/auth/authQuery';
import UserDefaultAvatar from '@utils/components/UserDefaultAvatar/UserDefaultAvatar';
import { useTranslation } from 'react-i18next';

type Props = {
  user: {
    email: string;
    uuid?: string;
    firstName?: string;
    lastName?: string;
  };
};

export default function UserCell({ user }: Props) {
  const { data: userStore } = useQueryMe();
  const { t } = useTranslation();

  return (
    <div className="d-flex justify-content-start align-items-center">
      <UserDefaultAvatar name={user.firstName || user.email} style={{ height: '40px', width: '40px' }} />
      <div className="ms-2">
        <p className="m-0">
          {user.uuid && user.uuid === userStore.uuid ? (
            <span className="fw-bolder">
              {user.firstName} {user.lastName} {t('general.yourAccount')}
            </span>
          ) : (
            <span className="fw-bold">
              {user.firstName} {user.lastName}
            </span>
          )}
        </p>
        <p className="user-status m-0">{user.email}</p>
      </div>
    </div>
  );
}
