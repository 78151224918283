import { Link } from 'react-router-dom';
import { CardTitle, CardText, Button, Card, CardBody } from 'reactstrap';
import '@styles/base/pages/authentication.scss';
import '@src/assets/scss/pages/activation-email.scss';
import { PATHS } from '@src/router/paths';

const ActivateAccount = ({ registeredEmail, resendEmailVerification }) => {
  return (
    <div className="activation-card-content">
      <Card className="fit-content p-1">
        <CardBody>
          <CardTitle tag="h2" className="fw-bolder mb-1">
            Activate your account
          </CardTitle>
          <CardText className="mb-2">
            We&apos;ve sent a link to your email address: <span className="fw-bolder">{registeredEmail}</span> Please
            follow the link inside to continue.
          </CardText>
          <Button block tag={Link} to={PATHS.AUTH.LOGIN.FULL_PATH} color="primary">
            Skip for now
          </Button>
          <p className="text-center mt-2">
            <span>Didn&apos;t receive an email? </span>
            <a href="#" onClick={() => resendEmailVerification()}>
              <span>Resend</span>
            </a>
          </p>
        </CardBody>
      </Card>
    </div>
  );
};

export default ActivateAccount;
