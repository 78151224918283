import { PlatformIcon } from '@src/views/components/platformIcon';

export const Application = ({ application }) => {
  return (
    <div className="d-flex justify-content-left align-items-center gap-2">
      <PlatformIcon platformName={application.platformName} height={25} />
      {application.name}
    </div>
  );
};
