import { useTranslation } from 'react-i18next';
import FormActionsNext from '@utils/components/form/FormActions/FormActionsNext';
import { useRegions } from '@utils/hooks/dictionaries';
import { useWizardFormStepperContext } from '@utils/context/WizardFormStepperContext';
import { useFormContext } from 'react-hook-form';
import { Select } from '@utils/components/form/Inputs';

const AvailabilityStep = ({ stepInfo }) => {
  const { data: regions, isLoading: isRegionsLoading } = useRegions();
  const { t } = useTranslation();
  const { onNextStep } = useWizardFormStepperContext();
  const { control } = useFormContext();

  const handleNextClick = () => {
    onNextStep(stepInfo.fields);
  };

  return (
    <>
      <div className="modal-form-content">
        <Select
          control={control}
          name="region"
          options={regions}
          isClearable={false}
          placeholder={t('projects.form.regionHelper')}
          isMulti
          label={t('projects.form.region')}
          isLoading={isRegionsLoading}
        />
      </div>
      <FormActionsNext showPrevious={false} onNext={handleNextClick} />
    </>
  );
};

export default AvailabilityStep;
