import { useMemo } from 'react';
import { Button, Col, Row, Spinner } from 'reactstrap';
import { Trash } from 'react-feather';
import { useQueryMe } from '@api/hooks/auth/authQuery';
import FormActionsNext from '@utils/components/form/FormActions/FormActionsNext';
import { useTranslation } from 'react-i18next';
import { useProjectRoles } from '@utils/hooks/dictionaries';
import { useWizardFormStepperContext } from '@utils/context/WizardFormStepperContext';
import { useFieldArray, useFormContext } from 'react-hook-form';
import UserDefaultAvatar from '@utils/components/UserDefaultAvatar/UserDefaultAvatar';
import ProjectMemberPicker from './components/ProjectMemberPicker';
import { useActiveOrganization } from '@utils/hooks/useActiveOrganization';
import { useOrganizationMembers } from './hooks/useOrganizationMembers';

const UsersStep = ({ stepInfo }) => {
  const { t } = useTranslation();
  const { data: userStore } = useQueryMe();

  const { onNextStep, onPrevStep } = useWizardFormStepperContext();
  const { control } = useFormContext();
  const organizationUuid = useActiveOrganization();
  const { data: organizationMembers, isLoading: isOrganizationMembersLoading } = useOrganizationMembers({
    organizationUuid,
  });

  const handleNext = () => {
    onNextStep(stepInfo.fields);
  };

  const {
    append,
    fields: currentMembers,
    remove,
  } = useFieldArray({
    control,
    name: 'members',
  });

  const { data: projectRoles, isLoading: isLoadingProjects } = useProjectRoles();
  const defaultUserOwnershipRole = useMemo(() => {
    return projectRoles.find((role) => role.label.toLowerCase() === 'owner');
  }, [projectRoles]);

  const handleAddUser = (member) => {
    append(member);
  };

  const handleRemoveUser = (member) => {
    remove(member);
  };

  if (isLoadingProjects || isOrganizationMembersLoading) {
    return (
      <div className="text-center">
        <Spinner color="primary" />
      </div>
    );
  }

  return (
    <>
      <div className="modal-form-content">
        <ProjectMemberPicker
          onSubmit={handleAddUser}
          projectRoles={projectRoles}
          currentMembers={currentMembers}
          organizationMembers={organizationMembers}
        />

        <Row className="mb-5 mt-2">
          <Row>
            <h5>{t('general.members')}</h5>
          </Row>
          {userStore.email && defaultUserOwnershipRole && (
            <Row className="mx-0 mb-1">
              <UserDefaultAvatar
                name={userStore.firstName || userStore.email}
                style={{ height: '40px', width: '40px' }}
              />
              <Col xs={7}>
                <span className="user-name fw-bold">
                  {userStore.firstName} {userStore.lastName} {t('general.yourAccount')}
                </span>
                <br />
                <span className="user-status">{userStore.email}</span>
              </Col>
              <Col xs={2} className="d-flex align-items-center text-right">
                <span className="user-status">{defaultUserOwnershipRole.label}</span>
              </Col>
            </Row>
          )}
          {currentMembers?.map((member, index) => (
            <Row key={index} className="mx-0">
              <UserDefaultAvatar name={member.name || member.email} style={{ height: '40px', width: '40px' }} />
              <Col xs={7}>
                <span className="user-name fw-bold">{member.name}</span>
                <br />
                <span className="user-status">{member.email}</span>
              </Col>
              <Col xs={2} className="d-flex align-items-center text-right">
                <span className="user-status">{member.role.label}</span>
              </Col>
              <Col xs={1} className="d-flex align-items-center">
                <Button color="transparent" className="border-0" onClick={() => handleRemoveUser(member)}>
                  <Trash height="18px" className="text-danger" />
                </Button>
              </Col>
            </Row>
          ))}
        </Row>
      </div>
      <FormActionsNext onPrevious={onPrevStep} onNext={handleNext} />
    </>
  );
};

export default UsersStep;
