import ToastContent from '@src/views/components/toastContent/ToastContent';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useMutateDeleteDeveloperApiToken } from '@api/hooks/organization/organizationQuery';
import { useActiveOrganization } from '@utils/hooks/useActiveOrganization';
import { ConfirmationModal } from '@utils/components/modals';

type Props = {
  isOpen: boolean;
  toggle: () => void;
  token: string;
};

export default function DeactivateTokenModal({ isOpen, toggle, token }: Props) {
  const { t } = useTranslation();
  const organizationUuid = useActiveOrganization();

  const { isLoading, mutateAsync } = useMutateDeleteDeveloperApiToken();

  const handleDeactivate = () => {
    mutateAsync({ tokenId: token, organizationUuid })
      .then(() => {
        toggle();
        toast((t) => <ToastContent t={t} success header="organizations.settings.apiTokens.tokenInvalidated" />);
      })
      .catch(() => {
        toast((t) => (
          <ToastContent t={t} success={false} header="organizations.settings.apiTokens.cantInvalidateToken" />
        ));
      });
  };

  return (
    <ConfirmationModal
      isOpen={isOpen}
      toggle={toggle}
      isLoading={isLoading}
      onConfirm={handleDeactivate}
      title={t('organizations.settings.apiTokens.deactivateToken')}
      buttons={{
        confirmText: t('organizations.settings.apiTokens.deactivate'),
      }}
    >
      {t('organizations.settings.apiTokens.areYouSure')}
    </ConfirmationModal>
  );
}
