import * as yup from 'yup';
import zxcvbn from 'zxcvbn';

export const passwordYup = yup
  .string()
  .required()
  .min(8, { key: 'errors.passwordTooShort', options: { length: 8 } })
  .test('password-strength', 'errors.passwordTooWeak', function (v) {
    const result = zxcvbn(v);
    return result.score >= 3;
  });
