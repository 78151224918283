import { errorMessage } from '@utils/errors/errorMessages';
import classNames from 'classnames';
import { useId } from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line unused-imports/no-unused-imports
import { FormFeedback, Label, Input as BaseInput } from 'reactstrap';

/**
 * @typedef {InputProps & FormInputProps} Props
 *
 * @param {Props} props
 * @returns {JSX}
 */
export const Input = ({ control, label, name, required = false, ...inputProps }) => {
  const { field, fieldState } = useController({ name, control });
  const { t } = useTranslation();
  const id = useId();

  return (
    <>
      <Label className={classNames('form-label', required && 'required')} htmlFor={id}>
        {label}
      </Label>
      <BaseInput name={name} id={id} invalid={Boolean(fieldState.error)} {...field} {...inputProps} />
      {fieldState.error && <FormFeedback>{errorMessage(t, fieldState.error.message)}</FormFeedback>}
    </>
  );
};
