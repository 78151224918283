import { useQueryApplications } from '@api/hooks/application/applicationQuery';
import { useQueryProjects } from '@api/hooks/project/projectQuery';
import { useActiveOrganization } from '@utils/hooks/useActiveOrganization';
import { useActiveProject } from '@utils/hooks/useActiveProject';

export function useGetApplications() {
  const selectedProjectUuid = useActiveProject();
  const selectedOrganizationUuid = useActiveOrganization();
  const applicationResult = useQueryApplications({
    projectUuid: selectedProjectUuid,
  });
  const { data: projects, isFetching: projectsFetching } = useQueryProjects({
    organizationUuid: selectedOrganizationUuid,
  });

  const regions = projects?.data.find((project) => project.uuid === selectedProjectUuid)?.regionNames;
  return { ...applicationResult, regions, isFetching: projectsFetching || applicationResult.isFetching };
}
