import { PATHS } from '@src/router/paths';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Input, Label } from 'reactstrap';

const SubmitCode = ({ accessCode }) => {
  const { t } = useTranslation();
  const [code, setCode] = useState(accessCode);
  const navigate = useNavigate();

  const handleCodeChange = (e) => {
    setCode(e.target.value);
  };

  const handleGoNext = () => {
    navigate(`${PATHS.AUTH.REGISTER.FULL_PATH}?accessCode=${code}`);
  };

  const buttonDisabled = code.length !== 36;

  return (
    <>
      <Label className="form-label" aria-required="true">
        {t('requestDemo.earlyAccessCode')}
      </Label>

      <Input
        autoFocus
        autoComplete="off"
        value={code}
        onChange={handleCodeChange}
        placeholder="xxxxxxxx-xxxx-xxxx-xxxxx-xxxxxxxxxxxx"
      />

      <div className="px-3 mt-2">
        <Button color="primary" block disabled={buttonDisabled} onClick={handleGoNext}>
          {t('Next')}
        </Button>
      </div>
    </>
  );
};

export default SubmitCode;
