// ** Icons Import
import { Link } from 'react-feather';

export default [
  {
    header: 'SUPPORT',
  },
  {
    id: 'pages-documentation',
    title: 'Documentation',
    icon: <Link size={20} />,
    externalLink: true,
    newTab: true,
    navLink: 'https://docs.sync-stage.com',
  },
];
