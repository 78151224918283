import ToastContent from '@src/views/components/toastContent';
import FormActionsFinish from '@utils/components/form/FormActions/FormActionsFinish';
import { useOverlayContext } from '@utils/context/OverlayContext/useOverlayContext';
import { useWizardFormStepperContext } from '@utils/context/WizardFormStepperContext';
import { useFormContext } from 'react-hook-form';
import toast from 'react-hot-toast';
import { mapFormStateToProject } from './helpers';
import { useTranslation } from 'react-i18next';
import { useMutateCreateProject, useMutateInviteMember } from '@api/hooks/project/projectQuery';
import { useActiveOrganization } from '@utils/hooks/useActiveOrganization';
import SubmitConfirmation from '@utils/components/SubmitConfirmation/SubmitConfirmation';

const SubmitStep = ({ toggle, refetchProjects }) => {
  const { t } = useTranslation();
  const [onShowLoader, onHideLoader] = useOverlayContext();
  const { mutateAsync: createProject } = useMutateCreateProject();
  const { mutateAsync: inviteMember } = useMutateInviteMember();
  const { onPrevStep, onSubmit } = useWizardFormStepperContext();
  const organizationUuid = useActiveOrganization();

  const {
    formState: { isValid },
    getValues,
  } = useFormContext();

  const handleCreateProject = () => {
    onShowLoader();

    const formData = getValues();
    const project = mapFormStateToProject({ formData, organizationUuid });

    createProject(project)
      .then(async (result) => {
        toast((t) => <ToastContent t={t} success={true} header="projects.successNewProjectCreation" />);

        try {
          await Promise.all(
            formData?.members?.map((member) =>
              inviteMember({
                projectUuid: result?.uuid,
                emailAddress: member.email,
                roleUuid: member.role.value,
              })
            )
          );
          toast((t) => <ToastContent t={t} success={true} header="projects.successProjectInvite" />);
        } catch (error) {
          toast((t) => <ToastContent t={t} success={false} header="projects.failedProjectInvite" />);
        }

        refetchProjects();
        toggle();
      })
      .catch(() => {
        toast((t) => <ToastContent t={t} success={false} header="projects.failedNewProjectCreation" />);
      })
      .finally(() => {
        onHideLoader();
      });
  };

  const handleSubmit = () => {
    onSubmit(handleCreateProject);
  };

  return (
    <>
      <SubmitConfirmation title={t('general.submitButton')} subtitle={t('projects.form.submitDescription')} />
      <FormActionsFinish onPrevious={onPrevStep} onSubmit={handleSubmit} nextProps={{ disabled: !isValid }} />
    </>
  );
};

export default SubmitStep;
