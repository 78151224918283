// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedOrganizationUuid: null,
  selectedProjectUuid: null,
};

const organizationsProjects = createSlice({
  name: 'organizationsProjects',
  initialState,
  reducers: {
    handleSelectOrganization: (state, action) => {
      const { organizationUuid } = action.payload;
      state.selectedOrganizationUuid = organizationUuid;
    },
    handleSelectProject: (state, action) => {
      const { projectUuid } = action.payload;
      state.selectedProjectUuid = projectUuid;
    },
    handleClearOrganization: (state) => {
      state.selectedOrganizationUuid = null;
    },
    handleClearProject: (state) => {
      state.selectedProjectUuid = null;
    },
  },
});
export const { handleSelectOrganization, handleSelectProject, handleClearOrganization, handleClearProject } =
  organizationsProjects.actions;
export default organizationsProjects.reducer;
