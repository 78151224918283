// eslint-disable-next-line unused-imports/no-unused-imports, @typescript-eslint/no-unused-vars
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import useDictionariesApi from '@api/useDictionariesApi';

const KEYS = {
  REGION: ['region'],
  PRICING_PLAN: ['pricingPlan'],
  PROJECT_ROLE: ['projectRole'],
  ORGANIZATION_ROLE: ['organizationRole'],
  COUNTRY_CODE: ['countryCode'],
  APPLICATION_PLATFORM: ['applicationPlatform'],
};

/**
 * @param {Omit<UseQueryOptions<Region[]>, "queryFn" | "queryKey">} [options]
 */
export function useGetRegionsDictionary(options = {}) {
  return useQuery({
    queryFn: async () => {
      const { data } = await useDictionariesApi.getRegions();

      return data.data;
    },
    queryKey: KEYS.REGION,
    placeholderData: [],
    ...options,
    staleTime: Infinity,
  });
}

/**
 * @param {Omit<UseQueryOptions<PricingPlan[]>, "queryKey" | "queryFn">} [options]
 */
export function useGetPricingPlansDictionary(options = {}) {
  return useQuery({
    queryFn: async () => {
      const { data } = await useDictionariesApi.getPricingPlans();

      return data.data;
    },
    queryKey: KEYS.PRICING_PLAN,
    placeholderData: [],
    ...options,
    staleTime: Infinity,
  });
}

/**
 * @param {Omit<UseQueryOptions<ProjectRole[]>, "queryKey" | "queryFn">} [options]
 */
export function useGetProjectRolesDictionary(options = {}) {
  return useQuery({
    queryFn: async () => {
      const { data } = await useDictionariesApi.getProjectRoles();

      return data.data;
    },
    queryKey: KEYS.PROJECT_ROLE,
    placeholderData: [],
    ...options,
    staleTime: Infinity,
  });
}

/**
 * @param {Omit<UseQueryOptions<OrganizationRole[]>, "queryKey" | "queryFn">} [options]
 */
export function useGetOrganizationRolesDictionary(options = {}) {
  return useQuery({
    queryFn: async () => {
      const { data } = await useDictionariesApi.getOrganizationRoles();

      return data.data;
    },
    queryKey: KEYS.ORGANIZATION_ROLE,
    placeholderData: [],
    ...options,
    staleTime: Infinity,
  });
}

/**
 * @param {Omit<UseQueryOptions<CountryCode[]>, "queryKey" | "queryFn">} [options]
 */
export function useGetCountryCodesDictionary(options = {}) {
  return useQuery({
    queryFn: async () => {
      const { data } = await useDictionariesApi.getCountryCodes();

      return data.data;
    },
    queryKey: KEYS.COUNTRY_CODE,
    placeholderData: [],
    ...options,
    staleTime: Infinity,
  });
}

/**
 * @param {Omit<UseQueryOptions<ApplicationPlatform[]>, "queryKey" | "queryFn">} [options]
 */
export function useGetApplicationPlatformsDictionary(options = {}) {
  return useQuery({
    queryFn: async () => {
      const { data } = await useDictionariesApi.getApplicationPlatforms();

      return data.data;
    },
    queryKey: KEYS.APPLICATION_PLATFORM,
    placeholderData: [],
    ...options,
    staleTime: Infinity,
  });
}
