import { Row } from 'reactstrap';
import { useGetProjectAndOrganization } from '../../hooks/useGetProjectAndOrganization';
import ProjectDetails from './ProjectDetails';
import ProjectDeletion from './ProjectDeletion';
import { useRegions } from '@utils/hooks/dictionaries';
import { Skeleton } from '@src/views/components/skeleton';

function General() {
  const { data: regionsData, isFetching: isRegionsLoading } = useRegions();
  const { project, organization } = useGetProjectAndOrganization();

  if (isRegionsLoading) {
    return (
      <>
        <Skeleton style={{ height: 350 }} className="mt-2" />
        <Skeleton style={{ height: 300 }} className="mt-4" />
      </>
    );
  }

  return (
    <Row className="mt-2">
      <ProjectDetails project={project} organization={organization} regions={regionsData} />
      <div className="mt-4">
        <ProjectDeletion project={project} organization={organization} />
      </div>
    </Row>
  );
}

export default General;
