import Organizations from '../../views/pages/organizations/Organizations';
import { PATHS } from '../paths';

const OrganizationsRoutes = [
  {
    path: PATHS.ORGANIZATIONS.FULL_PATH,
    element: <Organizations />,
  },
];

export default OrganizationsRoutes;
