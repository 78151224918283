//** React Imports
import { Fragment, useEffect, useState } from 'react';

// ** Custom Components
import Breadcrumbs from '@components/breadcrumbs';

// ** Reactstrap Imports
import { Row, Col } from 'reactstrap';

import ToastContent from '@src/views/components/toastContent/ToastContent';
import { useRetrieveApplication } from './hooks/useRetrieveApplication';
import { useGetApplications } from './hooks/useGetApplications';
import { useActiveProject } from '@utils/hooks/useActiveProject';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '@src/router/paths';
import toast from 'react-hot-toast';
import { useQueryMe } from '@api/hooks/auth/authQuery';
import { useTranslation } from 'react-i18next';
import { ConfigureAppModal, TestAppModal, CreateApp } from './modals';
import { Skeleton, SkeletonList } from '@src/views/components/skeleton';
import SingleApplicationListItem from './components/singleApplicationListItem';
import AddApplicationListItem from './components/addApplicationListItem';
import { CONFIGURE_APP_MODES } from './modals/configureApp/consts';
import { AppValues, ConfigurationModalMode } from './modals/configureApp/types';

const Application = () => {
  const { data: applications, regions, isFetching } = useGetApplications();
  const projectUuid = useActiveProject();
  const { data: user } = useQueryMe();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    selectedAppData,
    setSelectedAppData,
    onOpen: onOpenConfigureModal,
    isOpened: isConfigureModalOpened,
    onClose: onCloseConfigureModal,
  } = useRetrieveApplication({ projectUuid });

  const [isNewAppOpen, setIsNewAppOpen] = useState(false);
  const [isTestAppOpen, setIsTestAppOpen] = useState(false);
  const [configureAppModalMode, setConfigureAppModalMode] = useState<ConfigurationModalMode>(
    CONFIGURE_APP_MODES.GENERAL
  );

  const toggleNewApp = () => {
    setIsNewAppOpen(!isNewAppOpen);
  };

  //TEST MODAL
  const handleOpenTestAppModal = (value) => {
    setIsTestAppOpen(true);
    setSelectedAppData(value);
  };

  const handleCloseTestAppModal = () => {
    setIsTestAppOpen(false);
  };

  // CONFIGURE MODAL
  const toggleConfigureApp = async (value?: AppValues, mode?: ConfigurationModalMode) => {
    if (!isConfigureModalOpened) {
      onOpenConfigureModal(value);

      if (mode) {
        setConfigureAppModalMode(mode);
      }
    } else {
      onCloseConfigureModal();
    }
  };

  useEffect(() => {
    if (!user.firstName || !user.lastName) {
      navigate(PATHS.ACCOUNT.PROFILE.FULL_PATH, { state: { goBack: PATHS.PROJECT.APPLICATION.FULL_PATH } });
      toast((t) => <ToastContent t={t} success header="userProfile.pleaseFillYourPersonalInformation" />);
    }
  }, [navigate, user.firstName, user.lastName]);

  return (
    <>
      <Fragment>
        <Breadcrumbs title={''} data={[{ title: t('projects.project') }, { title: t('applications.applications') }]} />
        <h4>{t('applications.applicationsList')}</h4>
        <p>{t('applications.applicationsListDescription')}</p>
        {isFetching && (
          <Row>
            <SkeletonList count={4}>
              <Col lg={{ size: 4, order: 1 }} sm={{ size: 12 }} xs={{ order: 2 }}>
                <Skeleton style={{ height: 118, marginBottom: '2rem' }} />
              </Col>
            </SkeletonList>
          </Row>
        )}
        {!isFetching && (
          <>
            <Row>
              {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore  */}
              {applications?.map((app) => (
                <SingleApplicationListItem
                  key={app.uuid}
                  app={app}
                  regions={regions}
                  onTestApp={handleOpenTestAppModal}
                  onConfigureApp={toggleConfigureApp}
                />
              ))}
              <AddApplicationListItem onNewApp={toggleNewApp} />
            </Row>
          </>
        )}
        {isNewAppOpen && <CreateApp toggle={() => toggleNewApp()} projectUuid={projectUuid} />}
        {/*
        not used right now
        <FinishConfigureApp
          applicationUuid={lastCreatedApplicationUuid}
          isOpen={isNewAppFinishOpen}
          toggle={() => toggleNewAppFinish()}
          formValues={formData}
        /> */}
        {isTestAppOpen && selectedAppData && (
          <TestAppModal
            toggle={handleCloseTestAppModal}
            appValues={selectedAppData}
            onConfigureAppOpen={toggleConfigureApp}
          />
        )}
        {isConfigureModalOpened && selectedAppData && (
          <ConfigureAppModal
            isOpen
            toggle={toggleConfigureApp}
            appValues={selectedAppData}
            mode={configureAppModalMode}
          />
        )}
      </Fragment>
    </>
  );
};

export default Application;
