import { UseMutationOptions, useMutation, useQuery } from '@tanstack/react-query';
import useAuthenticationApi from '../../useAuthenticationApi';
import { queryClient } from '@src/queryClient';
import { PROJECTS_QUERY_KEY } from '../project/projectQuery';

import { LoginBody, LoginResponse, ResetPasswordBody, SendPasswordLinkBody } from './types';

export function useMutateLogin(options?: UseMutationOptions<LoginResponse, unknown, LoginBody>) {
  return useMutation<LoginResponse, unknown, LoginBody>(async (data: LoginBody) => {
    const result = await useAuthenticationApi.login(data);

    return result?.data;
  }, options);
}

export const ME_QUERY_KEY = ['me'];
export function useQueryMe(options = {}) {
  return useQuery({
    queryKey: ME_QUERY_KEY,
    queryFn: async () => {
      const result = await useAuthenticationApi.getMe();
      return result.data;
    },
    staleTime: Infinity,
    ...options,
  });
}

export function useMutateCreateDefaultApp(options) {
  return useMutation(
    async () => {
      const result = await useAuthenticationApi.createDefaultApps();
      return result.data;
    },
    {
      ...options,
      onSuccess(data) {
        if (data.organization.uuid) {
          queryClient.invalidateQueries({ queryKey: PROJECTS_QUERY_KEY({ organizationUuid: data.organization.uuid }) });
        }

        options?.onSuccess?.(data);
      },
    }
  );
}

export function useMutateUpdateUser(options) {
  return useMutation(async (userData) => {
    const result = await useAuthenticationApi.putMe(userData);

    return result.data;
  }, options);
}

export function useMutateRequestAccessCode(options) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return useMutation(async (data: any) => {
    const result = await useAuthenticationApi.applyForAccessCode(data);

    return result.data;
  }, options);
}

export function useMutateSendPasswordLink(options?: UseMutationOptions<unknown, unknown, SendPasswordLinkBody>) {
  return useMutation(async (data: SendPasswordLinkBody) => {
    const result = await useAuthenticationApi.sendResetPasswordLink(data);

    return result.data;
  }, options);
}

export function useMutateResetPassword(options?: UseMutationOptions<unknown, unknown, ResetPasswordBody>) {
  return useMutation(async (data: ResetPasswordBody) => {
    const { resetToken, ...rest } = data;
    const result = await useAuthenticationApi.resetPassword(resetToken, rest);

    return result.data;
  }, options);
}
