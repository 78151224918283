//** React Imports
import { Fragment, useEffect, useState } from 'react';

// ** Custom Components
import Breadcrumbs from '@components/breadcrumbs';

// ** Third Party Components

// ** Reactstrap Imports
import { Row, Col } from 'reactstrap';
import CreateProject from './create-project/CreateProjectModal';

import { useGetProjects } from './hooks/useGetProjects';
import { useSelectProject } from './hooks/useSelectProject';
import { useProjectStore } from '@utils/hooks/useProjectStore';
import { useLocation, useNavigate } from 'react-router-dom';
import { PATHS } from '@src/router/paths';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SingleProjectItem from './components/singleProjectListItem';
import AddProjectListItem from './components/addProjectListItem';
import { Skeleton, SkeletonList } from '@src/views/components/skeleton';

const Projects = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    data: activeProjects,
    refetch: refetchActiveProjects,
    isFetching: isFetchingActiveProjects,
  } = useGetProjects();
  const { onSelectProject } = useSelectProject({ activeProjects });
  const globalStore = useSelector((state) => state.global);

  const { onClearProject } = useProjectStore();
  const [isNewProjectOpen, setIsNewProjectOpen] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    onClearProject();
  }, [onClearProject]);

  useEffect(() => {
    if (
      location.pathname === PATHS.PROJECTS.FULL_PATH &&
      activeProjects?.total === 0 &&
      !globalStore.newEnvironmentSkipped
    ) {
      navigate(PATHS.ENVIRONMENT.FULL_PATH);
    }
  }, [activeProjects, globalStore.newEnvironmentSkipped, location.pathname, navigate]);

  const onNewProjectOpen = async () => {
    setIsNewProjectOpen(!isNewProjectOpen);
  };

  const onOpenProject = async (project) => {
    onSelectProject({ projectUuid: project.uuid });
  };

  return (
    <Fragment>
      <div className="px-4">
        <Breadcrumbs title={''} data={[{ title: t('projects.projects') }]} />
        <h4>{t('projects.projects')}</h4>
        <p>{t('projects.projectsDescription')}</p>
        {isFetchingActiveProjects && (
          <Row>
            <SkeletonList count={5}>
              <Col lg={{ size: 4, order: 1 }} sm={{ size: 12 }} xs={{ order: 2 }} className="mb-2">
                <Skeleton style={{ height: 112 }} />
              </Col>
            </SkeletonList>
          </Row>
        )}
        {!isFetchingActiveProjects && (
          <>
            <Row>
              {activeProjects?.data.map((project) => (
                <SingleProjectItem project={project} onOpenProject={onOpenProject} key={project.uuid} />
              ))}
              <AddProjectListItem onNewProjectOpen={onNewProjectOpen} />
            </Row>
          </>
        )}
        {isNewProjectOpen && (
          <CreateProject toggle={() => onNewProjectOpen()} refetchProjects={refetchActiveProjects} />
        )}
      </div>
    </Fragment>
  );
};

export default Projects;
