import FormActionsFinish from '@utils/components/form/FormActions/FormActionsFinish';
import { Input } from '@utils/components/form/Inputs';
import { useWizardFormStepperContext } from '@utils/context/WizardFormStepperContext';
import { Fragment } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const DetailsStep = ({ onSubmit }) => {
  const { t } = useTranslation();
  const {
    control,
    watch,
    formState: { isValid },
  } = useFormContext();
  const { onPrevStep, onSubmit: handleSubmitWithValidation } = useWizardFormStepperContext();

  const platformName = watch('platformName');

  return (
    <Fragment>
      <Input control={control} name="name" label={t('applications.steps.details.applicationName')} className="mb-2" />
      <Input
        name="packageName"
        control={control}
        label={`${platformName || ''} ${t('applications.steps.details.packageName')}`}
      />
      <FormActionsFinish
        onSubmit={() => handleSubmitWithValidation(onSubmit)}
        disabled={!isValid}
        onPrevious={onPrevStep}
      />
    </Fragment>
  );
};

export default DetailsStep;
