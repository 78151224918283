import { AxiosRequestConfig } from 'axios';
import getAxiosClient from './ApiClient';
import config from './config';
import { CreateDeveloperApiTokenResponse, GetDeveloperApiTokenResponse } from './hooks/organization/types';

const OrganizationApi = () => {
  const client = getAxiosClient();

  const createOrganization = async (organizationUuid, ...args) => {
    const result = await client.post(`${config.organizationEndpoint}/${organizationUuid}`, ...args);
    return result;
  };
  const updateOrganization = async (organizationUuid, ...args) => {
    const result = await client.put(`${config.organizationEndpoint}/${organizationUuid}`, ...args);
    return result;
  };
  const deleteOrganization = async (organizationUuid, ...args) => {
    const result = await client.delete(`${config.organizationEndpoint}/${organizationUuid}`, ...args);
    return result;
  };
  const getMyOrganizations = async (...args) => {
    return await client.get(config.organizationEndpoint, ...args);
  };

  const getOrganization = async (organizationUuid, ...args) => {
    return await client.get(`${config.organizationEndpoint}/${organizationUuid}`, ...args);
  };

  const getOrganizationMembers = async (organizationUuid, ...args) => {
    const result = await client.get(`${config.organizationEndpoint}/${organizationUuid}/members`, ...args);
    return result;
  };

  const getOrganizationApiTokens = async (organizationUuid: string, ...args: AxiosRequestConfig[]) => {
    const result = await client.get<GetDeveloperApiTokenResponse>(
      `${config.organizationEndpoint}/${organizationUuid}/developer-api/token`,
      ...args
    );
    return result;
  };

  const deleteOrganizationApiTokens = async (
    organizationUuid: string,
    tokenId: string,
    ...args: AxiosRequestConfig[]
  ) => {
    const result = await client.delete<unknown>(
      `${config.organizationEndpoint}/${organizationUuid}/developer-api/token/${tokenId}`,
      ...args
    );
    return result;
  };

  const createOrganizationApiToken = async (organizationUuid: string, ...args: AxiosRequestConfig[]) => {
    const result = await client.post<CreateDeveloperApiTokenResponse>(
      `${config.organizationEndpoint}/${organizationUuid}/developer-api/token`,
      ...args
    );
    return result;
  };

  /**
   *
   * @param {string} organizationUuid
   * @param {string} emailAddress
   * @returns
   */
  const removeOrganizationMember = async (organizationUuid, emailAddress) => {
    const result = await client.delete(
      `${config.organizationEndpoint}/${organizationUuid}/remove-user?emailAddress=${encodeURIComponent(emailAddress)}`
    );

    return result;
  };

  // const updateRoleInOrganization = async ({ organizationUuid, ...args }) => {
  //   // BODY
  //   // {
  //   //     "emailAddress": "string",
  //   //     "roleUuid": "string"
  //   //   }
  //   const result = await client.put(`${config.organizationEndpoint}/${organizationUuid}/role`, ...args)
  //   return result
  // }
  const inviteToOrganization = async (organizationUuid, ...args) => {
    // BODY
    // {
    //     "emailAddress": "string",
    //     "roleUuid": "string"
    //   }
    const result = await client.post(`${config.organizationEndpoint}/${organizationUuid}/invite`, ...args);
    return result;
  };

  return {
    createOrganization,
    updateOrganization,
    deleteOrganization,
    getMyOrganizations,
    getOrganization,
    getOrganizationMembers,
    removeOrganizationMember,
    // updateRoleInOrganization,
    inviteToOrganization,
    getOrganizationApiTokens,
    createOrganizationApiToken,
    deleteOrganizationApiTokens,
    config,
  };
};

export default OrganizationApi();
