import { ReactNode, Fragment } from 'react';

interface Props {
  count: number;
  children: ReactNode;
}

function SkeletonList({ children, count }: Props) {
  return (
    <>
      {Array(count)
        .fill(null)
        .map((_, index) => (
          <Fragment key={index}>{children}</Fragment>
        ))}
    </>
  );
}

export default SkeletonList;
