import { useMutateUpdateMemberRole } from '@api/hooks/project/projectQuery';
import SubmitButton from '@src/views/components/buttons/SubmitButton/SubmitButton';
import ToastContent from '@src/views/components/toastContent/ToastContent';
import '@styles/react/pages/modal-create-app.scss';
import { Select } from '@utils/components/form/Inputs';
import { useProjectRoles } from '@utils/hooks/dictionaries';
import { useActiveProject } from '@utils/hooks/useActiveProject';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Col, Form, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';

const UpdateRoleModal = ({ toggle, member, refetchOrganizationMembers }) => {
  const { t } = useTranslation();
  const { data: projectRoles, isLoading: isProjectRolesLoading } = useProjectRoles();
  const { mutateAsync: updateRole, isLoading } = useMutateUpdateMemberRole();
  const {
    handleSubmit,
    control,
    formState: { isDirty },
    watch,
  } = useForm({
    defaultValues: {
      role: member.role,
    },
  });
  const projectUuid = useActiveProject();
  const formRole = watch('role');
  const submitDisabled = isDirty || formRole.value === member.role.value;

  const handleUpdateRole = (formData) => {
    updateRole({ projectUuid, emailAddress: member.email, roleUuid: formData.role.value })
      .then(() => {
        toast((t) => <ToastContent t={t} success header="projects.updateRole.successRoleSaved" />);
        toggle();
        refetchOrganizationMembers();
      })
      .catch(() => {
        toast((t) => <ToastContent t={t} success={false} header="projects.updateRole.failedRoleSaved" />);
      });
  };

  return (
    <Modal isOpen toggle={() => toggle()} className="modal-dialog-centered modal-lg">
      <ModalHeader className="bg-transparent" toggle={() => toggle()}></ModalHeader>
      <ModalBody className="pb-3 px-sm-3">
        <Form onSubmit={handleSubmit(handleUpdateRole)}>
          <h1 className="text-center mb-1">{t('projects.updateRole.changeMemberRole')}</h1>
          <Row className="m-0 align-items-center">
            <Col sm={6} className="ps-2">
              <Row>
                <span className="user-name fw-bold">
                  {member?.firstName} {member?.lastName}
                </span>
              </Row>
              <span className="user-status">{member?.email}</span>
            </Col>
            <Col sm={6} className="pe-0">
              <Select
                isLoading={isProjectRolesLoading}
                options={projectRoles}
                isClearable={false}
                control={control}
                name="role"
                label={t('general.selectRole')}
              />
            </Col>
          </Row>
          <Row className="m-0 mt-2 d-flex justify-content-end ">
            <SubmitButton
              isLoading={isLoading}
              disabled={submitDisabled}
              containerClassName="w-auto p-0"
              block
              color="primary"
              type="submit"
            >
              {t('general.saveChanges')}
            </SubmitButton>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default UpdateRoleModal;
