import { useMutateRequestAccessCode } from '@api/hooks/auth/authQuery';
import { yupResolver } from '@hookform/resolvers/yup';
import SubmitButton from '@src/views/components/buttons/SubmitButton/SubmitButton';
import ToastContent from '@src/views/components/toastContent/ToastContent';
import { Select } from '@utils/components/Select';
import { errorMessage } from '@utils/errors/errorMessages';
import { useState } from 'react';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Col, Form, FormFeedback, Input, Label, Row } from 'reactstrap';
import { applyForAccessCodeSchema } from './applyForAccessCodeSchema';
import { CATEGORIES, COUNTRIES } from './options.mock';

const initData = {
  firstName: '',
  lastName: '',
  emailAddress: '',
  company: '',
  country: '',
  category: '',
  recaptchaResponse: '',
};
const ApplyForCode = ({ setSent }) => {
  const { t } = useTranslation();
  const [selectedCategories, setSelectedCategories] = useState([]);

  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: initData,
    resolver: yupResolver(applyForAccessCodeSchema),
  });
  const { mutateAsync: applyForAccessCode, isLoading } = useMutateRequestAccessCode();

  const handleCategoriesChange = (selected) => {
    setSelectedCategories(selected);
    setValue('category', selected.map((x) => x.value).join(','));
  };

  const handleCaptchaVerified = (token) => {
    setValue('recaptchaResponse', token);
  };

  const handleApplyForCode = (formData) => {
    applyForAccessCode(formData).then(() => {
      toast((t) => <ToastContent t={t} success header="requestDemo.success" />);
      reset();
      setSent(true);
    });
  };

  return (
    <Form className="auth-login-form" onSubmit={handleSubmit(handleApplyForCode)}>
      <Row className="mb-1">
        <Col md="12" lg="6">
          <Label className="form-label required" for="firstName">
            {t('general.firstName')}
          </Label>
          <Controller
            name="firstName"
            control={control}
            render={({ field }) => (
              <Input autoFocus type="text" id="firstName" invalid={!!errors.firstName?.message} {...field} />
            )}
          />
          <FormFeedback>{errorMessage(t, errors.firstName?.message)}</FormFeedback>
        </Col>
        <Col md="12" lg="6">
          <Label className="form-label required" for="lastName">
            {t('general.lastName')}
          </Label>
          <Controller
            name="lastName"
            control={control}
            render={({ field }) => <Input type="text" id="lastName" invalid={!!errors.lastName?.message} {...field} />}
          />
          <FormFeedback>{errorMessage(t, errors.lastName?.message)}</FormFeedback>
        </Col>
      </Row>
      <Row className="mb-1">
        <Col md="12" lg="6">
          <Label className="form-label required" for="emailAddress">
            {t('general.email')}
          </Label>
          <Controller
            name="emailAddress"
            control={control}
            render={({ field }) => (
              <Input type="email" id="emailAddress" invalid={!!errors.emailAddress?.message} {...field} />
            )}
          />
          <FormFeedback>{errorMessage(t, errors.emailAddress?.message)}</FormFeedback>
        </Col>
        <Col md="12" lg="6">
          <Label className="form-label" for="country">
            {t('general.country')}
          </Label>
          <Controller
            name="country"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                id="country"
                value={COUNTRIES.find((country) => country.value === field.value)}
                onChange={(selected) => field.onChange(selected.value)}
                options={COUNTRIES}
              />
            )}
          />
          <FormFeedback>{errorMessage(t, errors.country?.message)}</FormFeedback>
        </Col>
      </Row>
      <Row className="mb-2">
        <Col md="12" lg="6">
          <Label className="form-label" for="company">
            {t('general.company')}
          </Label>
          <Controller
            name="company"
            control={control}
            render={({ field }) => <Input type="text" id="company" invalid={!!errors.company?.message} {...field} />}
          />
          <FormFeedback>{errorMessage(t, errors.company?.message)}</FormFeedback>
        </Col>
        <Col md="12" lg="6">
          <Label className="form-label required" for="category">
            {t('general.category')}
          </Label>
          <Controller
            name="category"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                id="category"
                onChange={handleCategoriesChange}
                value={selectedCategories}
                options={CATEGORIES}
                allowSelectAll={true}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                invalid={!!errors.category?.message}
                isMulti
              />
            )}
          />
          <FormFeedback>{errorMessage(t, errors.category?.message)}</FormFeedback>
        </Col>
      </Row>
      <GoogleReCaptcha onVerify={handleCaptchaVerified} />
      <div className="form-check mb-2">
        <Controller
          name="terms"
          control={control}
          render={({ field }) => <Input {...field} invalid={!!errors.terms?.message} type="checkbox" id="terms" />}
        />
        <Label className="form-label required" for="terms">
          {t('requestDemo.terms')}
        </Label>
        <FormFeedback>{errorMessage(t, errors.terms?.message)}</FormFeedback>
      </div>
      <div className="px-3">
        <SubmitButton type="submit" color="primary" block isLoading={isLoading}>
          {t('general.submitButton')}
        </SubmitButton>
      </div>
    </Form>
  );
};

export default ApplyForCode;
