import { Input, Select } from '@utils/components/form/Inputs';
import { useForm } from 'react-hook-form';
import { Button, Card, CardBody, CardHeader, Col, Form, Label, Row, Input as StrapInput } from 'reactstrap';
import { projectSchema } from './schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useContentOverlayContext } from '@utils/context/ContentOverlayContext/useContentOverlayContext';
import { useQueryClient } from '@tanstack/react-query';
import { PROJECTS_QUERY_KEY, useMutateUpdateProject } from '@api/hooks/project/projectQuery';
import toast from 'react-hot-toast';
import ToastContent from '@src/views/components/toastContent/ToastContent';

/**
 *
 * @param {Object} props
 * @param {Object} props.project
 * @param {Object} props.organization
 * @param {SelectOption[]} props.regions
 * @returns {JSX}
 */
function ProjectDetails({ project, organization, regions }) {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    formState: { isDirty },
    reset,
  } = useForm({
    defaultValues: {
      name: project?.name,
      region: project?.regionNames
        .map((name) => {
          const regionData = regions.find((r) => r.label === name);
          return regionData;
        })
        .filter(Boolean),
    },
    resolver: yupResolver(projectSchema),
  });
  const { mutateAsync } = useMutateUpdateProject();
  const client = useQueryClient();
  const [showLoading, hideLoading] = useContentOverlayContext();

  const handleUpdateProject = (data) => {
    showLoading();

    mutateAsync({
      projectUuid: project?.uuid,
      name: data.name,
      regionUuids: data.region.map((region) => region.value),
    })
      .then(() => {
        client.refetchQueries({
          queryKey: PROJECTS_QUERY_KEY({
            organizationUuid: organization?.uuid,
          }),
        });

        reset(data);
        toast((t) => <ToastContent t={t} success={true} header="projects.successProjectUpdate" />);
      })
      .catch(() => {
        toast((t) => <ToastContent t={t} header="projects.failedProjectUpdate" />);
      })
      .finally(() => {
        hideLoading();
      });
  };

  return (
    <Form onSubmit={handleSubmit(handleUpdateProject)}>
      <Card className="p-0">
        <CardHeader className="border border-bottom">
          <h4 className="m-0">{t('projects.form.projectDetails')}</h4>
        </CardHeader>
        <CardBody className="mt-2">
          <Row>
            <Col sm={6} className="form-field">
              <Input
                placeholder={t('projects.form.projectNameHelper')}
                control={control}
                name="name"
                label={t('projects.form.projectName')}
              />
            </Col>
            <Col sm={6} className="form-field">
              <Label className="form-label">{t('projects.form.projectId')}</Label>
              <StrapInput disabled name="projectId" value={project?.uuid} />
            </Col>
          </Row>
          <Row>
            <Col sm={6} className="form-field">
              <Label className="form-label">{t('organizations.organization')}</Label>
              <StrapInput disabled name="Organization" value={organization?.name} />
            </Col>
            <Col sm={6} className="form-field">
              <Select
                control={control}
                name="region"
                options={regions}
                isClearable={false}
                placeholder={t('projects.form.regionHelper')}
                className="react-select"
                classNamePrefix="select"
                isMulti
                label={t('projects.form.region')}
              />
            </Col>
          </Row>
          <Row className="m-0 d-flex justify-content-start mt-3">
            <Button
              disabled={!isDirty}
              className="w-auto"
              color="primary"
              type="submit"
              containerClassName="w-auto p-0"
            >
              {t('general.saveChanges')}
            </Button>
            <Button className="w-auto ms-2" color="outline-secondary" onClick={() => reset()} disabled={!isDirty}>
              {t('general.discard')}
            </Button>
          </Row>
        </CardBody>
      </Card>
    </Form>
  );
}

export default ProjectDetails;
