import { ReactNode } from 'react';
import { Col, Row } from 'reactstrap';

type Props = {
  title?: string;
  children: ReactNode;
};

function Descriptions({ title, children }: Props) {
  return (
    <Row>
      {title && (
        <Col className="my-2" xs={12}>
          <h3>{title}</h3>
        </Col>
      )}
      {children}
    </Row>
  );
}

type ItemProps = {
  label: string;
  children: ReactNode;
};

function Item({ label, children }: ItemProps) {
  return (
    <>
      <Col xs={3}>
        <p className="fw-bolder">{label}</p>
      </Col>
      <Col xs={9}>{children}</Col>
    </>
  );
}

Descriptions.Item = Item;

export default Descriptions;
