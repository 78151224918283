import { Home, Plus } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { Button, Card, CardBody, Col } from 'reactstrap';

interface Props {
  onAddOrganization: () => void;
}

function AddOrganizationListItem({ onAddOrganization }: Props) {
  const { t } = useTranslation();
  return (
    <Col lg={{ size: 4, order: 1 }} sm={{ size: 12 }} xs={{ order: 2 }} className="mb-2">
      <Card className="organization-box" onClick={() => onAddOrganization()}>
        <CardBody className="d-flex justify-content-between align-items-center m-1">
          <div className="avatar avatar-stats p-50 m-0 bg-light-primary me-2">
            <div className="avatar-content">
              <Home size={20} />
            </div>
          </div>
          <Button className="btn-icon ms-25" color="primary">
            <Plus size={14} />
            <span className="align-middle ms-25" data-testid="add-organization">
              {t('organizations.addNewOrganization')}
            </span>
          </Button>
        </CardBody>
      </Card>
    </Col>
  );
}
export default AddOrganizationListItem;
