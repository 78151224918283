import { ApplicationSecret } from '@api/useApplicationApi.types';
import { useTranslation } from 'react-i18next';
import { Alert, ToastBody, ToastHeader, Table } from 'reactstrap';
import { ConfirmationModal } from '@utils/components/modals';
import { useFreeApplicationSecret } from '../../hooks/useFreeApplicationSecret';

type Props = {
  secret: ApplicationSecret;
  applicationUuid: string;
  projectUuid: string;
  toggle: () => void;
  isOpen: boolean;
};

function FreezeSecretModal({ secret, applicationUuid, projectUuid, toggle, isOpen }: Props) {
  const { onFreezeSecret } = useFreeApplicationSecret({ appUuid: applicationUuid, projectUuid });
  const { t } = useTranslation();

  return (
    <ConfirmationModal
      isOpen={isOpen}
      toggle={toggle}
      isLoading={false}
      onConfirm={() => onFreezeSecret({ isFreezed: secret.isFrozen, secretId: secret.applicationSecretId })}
      title={t('applications.secrets.areYouSure', {
        action: secret.isFrozen ? t('applications.secrets.actions.unfreeze') : t('applications.secrets.actions.freeze'),
      })}
      buttons={{
        confirmText: secret.isFrozen ? t('general.unfreeze') : t('general.freeze'),
      }}
    >
      <Table responsive className="mb-3 mt-1">
        <thead>
          <tr>
            <th>{t('applications.secretId')}</th>
            <th>{t('general.description')}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{secret.applicationSecretId}</td>
            <td>{secret.description}</td>
          </tr>
        </tbody>
      </Table>
      <Alert color="warning" className="my-2" data-testid="provisioning-warning">
        <ToastHeader className="alert-warning">{t('general.warning')}</ToastHeader>
        <ToastBody>
          <ul className="text-start">
            <li>
              {secret.isFrozen
                ? t('applications.secrets.unFreezeSecretResult')
                : t('applications.secrets.freezeSecretResult')}
            </li>
            {!secret.isFrozen && <li>{t('applications.secrets.tokensExpirationInfo')}</li>}
          </ul>
          <div></div>
        </ToastBody>
      </Alert>
    </ConfirmationModal>
  );
}
export default FreezeSecretModal;
