import { ApplicationSecret } from '@api/useApplicationApi.types';
import { Alert, ToastBody, ToastHeader, Table } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { ConfirmationModal } from '@utils/components/modals';
import { useDeleteSecret } from '../../hooks/useDeleteSecret';

type Props = {
  secret: ApplicationSecret;
  applicationUuid: string;
  projectUuid: string;
  toggle: () => void;
  isOpen: boolean;
};

function DeleteSecretModal({ secret, applicationUuid, projectUuid, toggle, isOpen }: Props) {
  const { onDeleteSecret } = useDeleteSecret({ applicationUuid, projectUuid });
  const { t } = useTranslation();

  return (
    <ConfirmationModal
      isOpen={isOpen}
      toggle={toggle}
      isLoading={false}
      onConfirm={() => onDeleteSecret(secret.applicationSecretId)}
      title={t('applications.secrets.areYouSure', {
        action: t('applications.secrets.actions.delete'),
      })}
      buttons={{
        confirmText: t('general.delete'),
      }}
    >
      <Table responsive className="mb-3 mt-1">
        <thead>
          <tr>
            <th>{t('applications.secretId')}</th>
            <th>{t('general.description')}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{secret.applicationSecretId}</td>
            <td>{secret.description}</td>
          </tr>
        </tbody>
      </Table>
      <Alert color="warning" className="my-2" data-testid="provisioning-warning">
        <ToastHeader className="alert-warning">{t('general.warning')}</ToastHeader>
        <ToastBody>
          <ul className="text-start">
            <li>{t('applications.secrets.deleteSecretResult')}</li>
            <li>{t('applications.secrets.tokensExpirationInfo')}</li>
          </ul>
          <div></div>
        </ToastBody>
      </Alert>
    </ConfirmationModal>
  );
}
export default DeleteSecretModal;
