import { Fragment } from 'react';
import { FormFeedback, Input, ListGroup, ListGroupItem } from 'reactstrap';
import androidLogo from '@src/assets/images/icons/technology/android.svg';
import appleLogo from '@src/assets/images/icons/technology/apple.svg';
import unityLogo from '@src/assets/images/icons/technology/unity.svg';
import browserLogo from '@src/assets/images/icons/technology/browser.svg';
import FormActionsNext from '@utils/components/form/FormActions/FormActionsNext';
import { useFormContext } from 'react-hook-form';
import { useWizardFormStepperContext } from '@utils/context/WizardFormStepperContext';
import { useTranslation } from 'react-i18next';
import { errorMessage } from '@utils/errors/errorMessages';
import { Radio } from '@utils/components/form/Inputs/Radio/Radio';
import { APPLICATION_PLATFORM } from '@src/views/pages/application/consts';

const PlatformStep = ({ stepInfo }) => {
  const { t } = useTranslation();
  const { onNextStep } = useWizardFormStepperContext();
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const handleNextClick = () => {
    onNextStep(stepInfo.fields);
  };

  return (
    <Fragment>
      <h5 className="pt-1">{t('applications.steps.platform.title')}</h5>
      <ListGroup flush data-testid="platforms">
        <ListGroupItem className="border-0 px-0">
          <label htmlFor="androidRadio" className="d-flex cursor-pointer">
            <span className="avatar avatar-tag bg-dark me-1">
              <img src={androidLogo} alt="androidLogo" height="25" />
            </span>
            <span className="d-flex align-items-center justify-content-between flex-grow-1">
              <div className="me-1">
                <h5 className="d-block fw-bolder">{t('applications.steps.platform.android')}</h5>
                <span>{t('applications.steps.platform.androidDescription')}</span>
              </div>
              <span>
                <Radio id="androidRadio" value={APPLICATION_PLATFORM.ANDROID} {...register('platformName')} />
              </span>
            </span>
          </label>
        </ListGroupItem>
        <ListGroupItem className="border-0 px-0">
          <label htmlFor="iosPlatform" className="d-flex cursor-pointer">
            <span className="avatar avatar-tag bg-dark me-1">
              <img src={appleLogo} alt="appleLogo" height="25" />
            </span>
            <span className="d-flex align-items-center justify-content-between flex-grow-1">
              <div className="me-1">
                <h5 className="d-block fw-bolder">{t('applications.steps.platform.ios')}</h5>
                <span>{t('applications.steps.platform.iphonesAndIpads')}</span>
              </div>
              <span>
                <Radio id="iosPlatform" value={APPLICATION_PLATFORM.IOS} {...register('platformName')} />
              </span>
            </span>
          </label>
        </ListGroupItem>
        <ListGroupItem className="border-0 px-0">
          <label htmlFor="webPlatform" className="d-flex cursor-pointer">
            <span className="avatar avatar-tag bg-dark me-1">
              <img src={browserLogo} alt="browserLogo" height="25" />
            </span>
            <span className="d-flex align-items-center justify-content-between flex-grow-1">
              <div className="me-1">
                <h5 className="d-block fw-bolder">{t('applications.steps.platform.webApp')}</h5>
                <span>{t('applications.steps.platform.webApplications')}</span>
              </div>
              <span>
                <Radio id="webPlatform" value={APPLICATION_PLATFORM.WEB} {...register('platformName')} />
              </span>
            </span>
          </label>
        </ListGroupItem>
        <ListGroupItem className="border-0 px-0 opacity-25" disabled>
          <label htmlFor="unityPlatform" className="d-flex cursor-pointer">
            <span className="avatar avatar-tag bg-dark me-1">
              <img src={unityLogo} alt="unityLogo" height="25" />
            </span>
            <span className="d-flex align-items-center justify-content-between flex-grow-1">
              <div className="me-1">
                <h5 className="d-block fw-bolder">{t('applications.steps.platform.unity')}</h5>
                <span>{t('applications.steps.platform.comingSoon')}</span>
              </div>
              <span>
                <Input type="radio" id="unityPlatform" name="platformName" value={''} />
              </span>
            </span>
          </label>
        </ListGroupItem>
      </ListGroup>
      {!!errors.platformName && (
        <FormFeedback className="d-block">{errorMessage(t, errors.platformName?.message)}</FormFeedback>
      )}
      <FormActionsNext showPrevious={false} onNext={handleNextClick} />
    </Fragment>
  );
};

export default PlatformStep;
