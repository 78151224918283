import { PropsWithChildren } from 'react';

function Steps({ title, children }: PropsWithChildren<{ title: string }>) {
  return (
    <>
      <p>{title}</p>
      <ol>{children}</ol>
    </>
  );
}

function Item({ children }: PropsWithChildren) {
  return <li className="mb-1">{children}</li>;
}

Steps.Item = Item;

export default Steps;
