import moment from 'moment';
import { useMemo } from 'react';
import { Calendar } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Row, Col } from 'reactstrap';
import { useGetOrganization } from '../../organization-settings/hooks/useGetOrganization';
import { getPeriodOption } from '../helpers';
import { Select } from '@utils/components/form/Inputs';
import { useFormContext } from 'react-hook-form';

const UsagePeriod = () => {
  const { t } = useTranslation();
  const { data: organization } = useGetOrganization();
  const { control } = useFormContext();

  const periodOptions = useMemo(() => {
    if (!organization) return [];

    const firstMonth = moment(organization.createdAt).utc().startOf('month');
    const currentMonth = moment().utc().endOf('month');
    const totalMonths = currentMonth.diff(firstMonth, 'months') + 1;

    return Array.from({ length: totalMonths }).map((_, i) => {
      const date = moment(currentMonth).add(i * -1, 'month');

      return getPeriodOption(date);
    });
  }, [organization]);

  return (
    <Card className="d-flex align-items-stretch" style={{ minHeight: 140 }}>
      <CardBody data-testid="period">
        <h4>{t('costExplorer.period')}</h4>
        <Row className="my-1">
          <Col sm={2} className="d-flex align-items-center">
            <Calendar />
          </Col>
          <Col sm={10}>
            <Select
              control={control}
              label=""
              name="requestDates"
              isClearable={false}
              options={periodOptions}
              isLoading={!organization}
              className="react-select w-100"
              classNamePrefix="select"
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default UsagePeriod;
