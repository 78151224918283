import { Link, Navigate } from 'react-router-dom';
import { ChevronLeft } from 'react-feather';
import { Card, CardBody, CardTitle, CardText, Form, Fade } from 'reactstrap';
import themeConfig from '@configs/themeConfig';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { resetPasswordSchema } from './resetPasswordSchema';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import toast from 'react-hot-toast';
import ToastContent from '../../../components/toastContent/ToastContent';
import SubmitButton from '@src/views/components/buttons/SubmitButton/SubmitButton';
import { useResetPassword } from './hooks/useResetPassword';
import '@styles/react/pages/page-authentication.scss';
import { PATHS } from '@src/router/paths';
import { ResetPasswordFormValues } from './types';
import { Input } from '@utils/components/form/Inputs';
import SubmitConfirmation from '@utils/components/SubmitConfirmation/SubmitConfirmation';

const defaultValues = {
  newPassword: '',
  confirmPassword: '',
};

const ResetPasswordBasic = () => {
  const { control, handleSubmit } = useForm<ResetPasswordFormValues>({
    defaultValues,
    resolver: yupResolver(resetPasswordSchema),
  });

  const { mutate: resetPassword, isLoading, isSuccess } = useResetPassword();
  const { t } = useTranslation();

  const resetToken = useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const resetSecret = urlParams.get('resetSecret');
    return resetSecret;
  }, []);

  const onSubmit = async (data) => {
    if (!resetToken) return;

    resetPassword(
      { password: data.newPassword, resetToken },
      {
        onError: () => {
          toast((t) => <ToastContent t={t} success={false} header="passwordReset.failed" />);
        },
      }
    );
  };

  if (!resetToken) {
    return <Navigate to={PATHS.AUTH.ROOT} />;
  }

  return (
    <div className="login-card-content">
      <Card>
        <CardBody>
          <Link className="brand-logo" to={PATHS.AUTH.ROOT}>
            <img src={themeConfig.app.appLogoFullImage} alt="logo" height={50} className="mt-2 mb-2" />
          </Link>
          {isSuccess && (
            <Fade in>
              <div className="mb-5">
                <SubmitConfirmation title={t('passwordReset.success')} subtitle={t('passwordReset.successContent')} />
              </div>
            </Fade>
          )}
          {!isSuccess && (
            <>
              <CardTitle tag="h4" className="mb-1">
                {t('passwordReset.title')}
              </CardTitle>
              <CardText className="mb-2">{t('passwordReset.resetPasswordText')}</CardText>
              <Form className="auth-reset-password-form mt-2" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-field">
                  <Input
                    control={control}
                    name="newPassword"
                    autoFocus
                    type="password"
                    label={t('passwordReset.newPassword')}
                  />
                </div>
                <div className="form-field">
                  <Input
                    control={control}
                    name="confirmPassword"
                    autoFocus
                    type="password"
                    label={t('passwordReset.confirmPassword')}
                  />
                </div>
                <SubmitButton color="primary" className="mt-3" block isLoading={isLoading}>
                  {t('passwordReset.setPassword')}
                </SubmitButton>
              </Form>
            </>
          )}
          <p className="text-center mt-2">
            <Link to={PATHS.AUTH.ROOT}>
              <ChevronLeft className="rotate-rtl me-25" size={14} />
              <span className="align-middle">{t('login.backToLogin')}</span>
            </Link>
          </p>
        </CardBody>
      </Card>
    </div>
  );
};

export default ResetPasswordBasic;
