import useOrganizationApi from '@api/useOrganizationApi';
import { QueryKey, UseMutationOptions, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { nanoid } from '@reduxjs/toolkit';
import {
  CreateDeveloperApiTokenParams,
  CreateDeveloperApiTokenResponse,
  DeleteDeveloperApiTokenParams,
  GetDeveloperApiTokenParams,
  GetDeveloperApiTokenResponse,
} from './types';
import { UseQueryOptions } from '@api/types';

export const ORGANIZATIONS_KEY = ['organizations'];
export function useQueryOrganizations(options) {
  return useQuery({
    queryKey: ORGANIZATIONS_KEY,
    queryFn: async () => {
      const result = await useOrganizationApi.getMyOrganizations();
      return result.data;
    },
    staleTime: 1000 * 60 * 5,
    ...options,
  });
}

export const ORGANIZATION_KEY = ({ organizationUuid }) => ['organizations', organizationUuid];
export function useQueryOrganization({ organizationUuid }, options) {
  return useQuery({
    queryKey: ORGANIZATION_KEY({ organizationUuid }),
    queryFn: async () => {
      const result = await useOrganizationApi.getOrganization(organizationUuid);
      return result.data;
    },
    staleTime: Infinity,
    ...options,
  });
}

export function useQueryOrganizationUsers({ organizationUuid, skip, rowsPerPage }, options) {
  return useQuery({
    queryKey: ['organizationUsers', organizationUuid, skip, rowsPerPage],
    queryFn: async () => {
      const result = await useOrganizationApi.getOrganizationMembers(organizationUuid, {
        params: { skip, rowsPerPage },
      });
      return result.data.data;
    },
    ...options,
  });
}

/**
 *
 * @param {object} data
 * @param {string} data.organizationUuid
 * @param {import('@tanstack/react-query').MutateOptions} [options]
 * @returns
 */
export function useMutateDeleteMemberFromOrganization({ organizationUuid }, options = {}) {
  return useMutation<unknown, unknown, Record<string, unknown>>(
    /**
     *
     * @param {object} body
     * @param {string} body.emailAddress
     * @returns
     */
    async ({ emailAddress }) => {
      const result = await useOrganizationApi.removeOrganizationMember(organizationUuid, emailAddress);

      return result.data;
    },
    options
  );
}

export function useMutateInviteToOrganization({ organizationUuid }, options) {
  return useMutation<unknown, unknown, Record<string, unknown>>(async ({ emailAddress, roleUuid }) => {
    const result = await useOrganizationApi.inviteToOrganization(organizationUuid, {
      emailAddress,
      roleUuid,
    });
    return result.data;
  }, options);
}

export function useMutateUpdateOrganization({ organizationUuid }, options) {
  return useMutation<unknown, unknown, Record<string, unknown>>(async ({ name }) => {
    const result = await useOrganizationApi.updateOrganization(organizationUuid, {
      name,
    });

    return result.data;
  }, options);
}

export function useMutateCreateOrganization(options) {
  return useMutation<unknown, unknown, Record<string, unknown>>(
    async ({ organizationName, companyName, taxId, vatNumber, country, billingAddress, state, zipCode, members }) => {
      const result = await useOrganizationApi
        // to be changed - needs backend
        .createOrganization(nanoid(), {
          name: organizationName,
          companyName,
          taxId,
          vatNumber,
          countryISO3166Code: country,
          billingAddress,
          state,
          zipCode,
          userInvitations: members,
        });
      return result.data;
    },
    options
  );
}

export const ORGANIZATION_API_TOKENS = ({ organizationUuid = '' } = {}): QueryKey =>
  ['organizationDevelopApiToken', organizationUuid].filter(Boolean);
export function useQueryDeveloperApiToken(
  { organizationUuid }: GetDeveloperApiTokenParams,
  options?: UseQueryOptions<GetDeveloperApiTokenResponse['data']>
) {
  return useQuery({
    staleTime: 1000 * 60 * 5,
    ...options,
    queryKey: ORGANIZATION_API_TOKENS({ organizationUuid }),
    queryFn: async () => {
      const result = await useOrganizationApi.getOrganizationApiTokens(organizationUuid, {});
      return result.data.data;
    },
  });
}

export function useMutateCreateDeveloperApiToken(
  options?: UseMutationOptions<CreateDeveloperApiTokenResponse, unknown, CreateDeveloperApiTokenParams>
) {
  const queryClient = useQueryClient();

  return useMutation<CreateDeveloperApiTokenResponse, unknown, CreateDeveloperApiTokenParams>(
    async ({ organizationUuid }) => {
      const result = await useOrganizationApi.createOrganizationApiToken(organizationUuid);

      return result.data;
    },
    {
      ...options,
      onSuccess(data, variables, ...args) {
        const { organizationUuid } = variables;

        queryClient.invalidateQueries({ queryKey: ORGANIZATION_API_TOKENS({ organizationUuid }) });

        options?.onSuccess?.(data, variables, ...args);
      },
    }
  );
}

export function useMutateDeleteDeveloperApiToken(
  options?: UseMutationOptions<unknown, unknown, DeleteDeveloperApiTokenParams>
) {
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, DeleteDeveloperApiTokenParams>(
    async ({ organizationUuid, tokenId }) => {
      const result = await useOrganizationApi.deleteOrganizationApiTokens(organizationUuid, tokenId);

      return result.data;
    },
    {
      ...options,
      onSuccess(...args) {
        queryClient.invalidateQueries({ queryKey: ORGANIZATION_API_TOKENS() });

        options?.onSuccess?.(...args);
      },
    }
  );
}
