function UserDefaultAvatar({ name, ...props }) {
  return (
    <div className="rounded-circle bg-dark text-uppercase" {...props}>
      <div className="d-flex justify-content-center align-items-center text-center h-100">
        <span>{name?.charAt(0)}</span>
      </div>
    </div>
  );
}

export default UserDefaultAvatar;
