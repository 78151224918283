import { Trans, useTranslation } from 'react-i18next';
import Steps from './Steps';
import { getTestAppLink, isAndroid } from './helpers';
import { QRCodeSVG } from 'qrcode.react';

type Props = {
  onOpenConfigureModal: () => void;
  platform: string;
  className?: string;
};

export default function MobileAppSDK({ onOpenConfigureModal, platform, className }: Props) {
  const { t } = useTranslation();

  const deviceName = isAndroid(platform)
    ? t('applications.testApp.getMobileTestApp.androidDevice')
    : t('applications.testApp.getMobileTestApp.iOSDevice');

  const appStore = isAndroid(platform) ? t('applications.testApp.googlePlay') : t('applications.testApp.appleStore');

  const testAppLink = getTestAppLink(platform);

  return (
    <div className={className} data-testid="mobile-sdk-steps">
      <h4>{t('applications.testApp.getMobileTestApp.title', { appStore })}</h4>
      <Steps title={t('applications.testApp.getMobileTestApp.steps')}>
        <Steps.Item>
          <span data-testid="mobile-sdk-step-1-description">
            <Trans
              i18nKey="applications.testApp.getMobileTestApp.firstStep"
              values={{ device: deviceName }}
              components={{
                a: <a href={testAppLink} target="_blank" rel="noreferrer" />,
              }}
            />
          </span>
          <div className="d-flex align-items-center mt-2 flex-column gap-1">
            <QRCodeSVG value={testAppLink} />
            <p data-testid="mobile-sdk-step-1-qr-code-description">
              {t('applications.testApp.getMobileTestApp.syncStageTestApp', { appStore })}
            </p>
          </div>
        </Steps.Item>
        <Steps.Item>{t('applications.testApp.getMobileTestApp.secondStep')}</Steps.Item>
        <Steps.Item>
          <Trans
            i18nKey="applications.testApp.getMobileTestApp.thirdStep"
            components={{
              a: <span className="text-primary cursor-pointer" onClick={onOpenConfigureModal} />,
            }}
          />
        </Steps.Item>
        <Steps.Item>{t('applications.testApp.getMobileTestApp.fourthStep')}</Steps.Item>
      </Steps>
    </div>
  );
}
