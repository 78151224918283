// ** React Imports
import { Link, Outlet } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';

// ** Custom Hooks
import { useSkin } from '@hooks/useSkin';

// ** Third Party Components
import classnames from 'classnames';
import themeConfig from '@configs/themeConfig';

import '../assets/scss/pages/unauthorized.scss';
import moment from 'moment';
import { PATHS } from '@src/router/paths';
import { useTranslation } from 'react-i18next';

function UnauthorizedLayout({ redirectUrl }) {
  // ** States
  const [isMounted, setIsMounted] = useState(false);

  // ** Hooks
  const { skin } = useSkin();

  const { t } = useTranslation();

  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  const isExternalLink = useMemo(() => {
    return redirectUrl?.startsWith('http');
  }, [redirectUrl]);

  if (!isMounted) {
    return null;
  }

  return (
    <div
      className={classnames('blank-page', {
        'dark-layout': skin === 'dark',
      })}
    >
      <div className="app-content content">
        <div className="unauthorized gradient-bg" />
        <div className="unauthorized pattern-bg" />
        <div className="content-wrapper">
          <div className="content-body">
            <div className="unauthorized-wrapper">
              <div className="m-0 unauthorized-header">
                {isExternalLink ? (
                  <a rel="noopener noreferrer" href={redirectUrl}>
                    <img src={themeConfig.app.appLogoFullImage} alt="logo" height={50} />
                  </a>
                ) : (
                  <Link to={redirectUrl}>
                    <img src={themeConfig.app.appLogoFullImage} alt="logo" height={50} />
                  </Link>
                )}
              </div>
              <div className="unauthorized-content">
                <Outlet />
              </div>
              <div className="unauthorized-wrapper unauthorized-footer">
                <a href={PATHS.EXTERNAL.PRIVACY_POLICY} target="_blank" rel="noopener noreferrer">
                  {t('general.privacyPolicy')}
                </a>
                <a href={PATHS.EXTERNAL.TERMS_AND_CONDITIONS} target="_blank" rel="noopener noreferrer">
                  {t('general.termsAndConditions')}
                </a>
                <span>Copyright @ {moment().year()} Open Sesame Media, Inc.</span>
                <span>Console version: {process.env.REACT_APP_VERSION || '1.0.0'}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default UnauthorizedLayout;
