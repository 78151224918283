// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit';

export const layoutSlice = createSlice({
  name: 'global',
  initialState: {
    isLoading: false,
    newEnvironmentSkipped: false,
  },
  reducers: {
    handleLoadingOn: (state) => {
      state.isLoading = true;
    },
    handleLoadingOff: (state) => {
      state.isLoading = false;
    },
    handleNewEnvironmentSkipped: (state) => {
      state.newEnvironmentSkipped = true;
    },
  },
});

export const { handleLoadingOn, handleLoadingOff, handleNewEnvironmentSkipped } = layoutSlice.actions;

export default layoutSlice.reducer;
