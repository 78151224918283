// eslint-disable-next-line unused-imports/no-unused-imports, @typescript-eslint/no-unused-vars
import { UseQueryResult } from '@tanstack/react-query';
import { useGetCountryCodesDictionary } from '@api/hooks/dictionaries';

/**
 *
 * @returns {UseQueryResult<CountryCodeSelectOption[]>}
 */
export function useCountryCodes() {
  return useGetCountryCodesDictionary({
    select(data) {
      return data.map((countryCode) => ({
        label: countryCode.name,
        value: countryCode.code,
        numberCode: countryCode.numberCode,
      }));
    },
  });
}
