import { useQueryOrganizationUsers } from '@api/hooks/organization/organizationQuery';
import { useMutateInviteMember } from '@api/hooks/project/projectQuery';
import { yupResolver } from '@hookform/resolvers/yup';
import SubmitButton from '@src/views/components/buttons/SubmitButton/SubmitButton';
import ToastContent from '@src/views/components/toastContent/ToastContent';
import { useGetProjectMembers } from '@src/views/pages/project-settings/hooks/useGetProjectMembers';
import '@styles/react/pages/modal-create-app.scss';
import { useProjectRoles } from '@utils/hooks/dictionaries';
import { useActiveOrganization } from '@utils/hooks/useActiveOrganization';
import { useActiveProject } from '@utils/hooks/useActiveProject';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Col, Form, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { inviteUserSchema } from './schema';
import { Select } from '@utils/components/form/Inputs';

const InviteUserModal = ({ isOpen, toggle, refetchOrganizationMembers }) => {
  const { t } = useTranslation();
  const { data: projectRoles, isLoading: isProjectRolesLoading } = useProjectRoles();
  const organizationUuid = useActiveOrganization();
  const projectUuid = useActiveProject();
  const { data: projectMembers, isLoading: isProjectMembersLoading } = useGetProjectMembers({
    activeProject: projectUuid,
  });
  const { data: organizationMembers, isLoading: isMembersLoading } = useQueryOrganizationUsers({ organizationUuid });
  const { mutateAsync: inviteUser, isLoading: isSendingInvitation } = useMutateInviteMember();
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    defaultValues: {
      emailAddress: '',
      roleUuid: '',
    },
    resolver: yupResolver(inviteUserSchema),
  });

  const handleInviteUser = (formData) => {
    inviteUser({
      projectUuid,
      emailAddress: formData.emailAddress.value,
      roleUuid: formData.roleUuid.value,
    })
      .then(() => {
        toast((t) => <ToastContent t={t} success header="projects.successProjectInvite" />);
        refetchOrganizationMembers();
        toggle();
      })
      .catch(() => {
        toast((t) => <ToastContent t={t} success={false} header="projects.failedProjectInvite" />);
      });
  };

  const availableMembers = useMemo(() => {
    if (!organizationMembers || !projectMembers?.data) return [];

    return organizationMembers
      .filter(
        (organizationMember) =>
          projectMembers.data.findIndex((projectMember) => projectMember.uuid === organizationMember.uuid) === -1
      )
      .map((member) => ({ value: member.email, label: member.email }));
  }, [organizationMembers, projectMembers]);

  return (
    <Modal isOpen={isOpen} toggle={() => toggle()} className="modal-dialog-centered modal-lg">
      <ModalHeader className="bg-transparent" toggle={() => toggle()}></ModalHeader>
      <ModalBody className="pb-3 px-sm-3">
        <Form onSubmit={handleSubmit(handleInviteUser)}>
          <h1 className="text-center mb-1">{t('projects.settings.shareProject')}</h1>
          <h5 className="text-center mb-1">{t('projects.settings.shareProjectWithMember')}</h5>
          <Row className="m-0 my-2">
            <Col sm={6} className="ps-0">
              <Select
                name="emailAddress"
                label={t('projects.settings.addMember')}
                control={control}
                options={availableMembers}
                isLoading={isMembersLoading || isProjectMembersLoading}
                isClearable={false}
              />
            </Col>
            <Col sm={6} className="pe-0">
              <Select
                name="roleUuid"
                label={t('projects.settings.selectRole')}
                control={control}
                options={projectRoles}
                isClearable={false}
                isLoading={isProjectRolesLoading}
              />
            </Col>
          </Row>
          <Row className="m-0 d-flex justify-content-end">
            <SubmitButton
              containerClassName="w-auto p-0 "
              color="primary"
              type="submit"
              isLoading={isSendingInvitation}
              disabled={!isValid}
            >
              {t('general.invite')}
            </SubmitButton>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default InviteUserModal;
