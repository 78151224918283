import { useState } from 'react';

export function useModal(): [boolean, () => void, () => void] {
  const [isShowing, setIsShowing] = useState(false);

  const handleToggle = () => {
    setIsShowing(!isShowing);
  };

  const handleClose = () => {
    setIsShowing(false);
  };
  return [isShowing, handleToggle, handleClose];
}
