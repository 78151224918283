/* eslint-disable nonblock-statement-body-position */
// ** Config

// ** Store & Actions
import { useDispatch, useSelector } from 'react-redux';

// ** Reactstrap Imports
import { CardTitle, CardText, Button } from 'reactstrap';

// ** Styles
import '@styles/base/pages/authentication.scss';
import '@src/assets/scss/pages/activation-email.scss';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { handleNewEnvironmentSkipped } from '../../../redux/global';
import { useApplicationSetup } from './hooks/useApplicationSetup';
import SettingEnvironmentScreen from './SettingEnvironmentScreen';
import ComponentSpinner from '../../../@core/components/spinner/Loading-spinner';
import CardContent from './CardContent';
import { useGetOrganizations } from './hooks/useGetOrganizations';
import { PATHS } from '@src/router/paths';
import { useActiveOrganization } from '@utils/hooks/useActiveOrganization';
import { useQueryProjects } from '@api/hooks/project/projectQuery';

const EnvironmentSetUp = () => {
  const dispatch = useDispatch();
  const organizationUuid = useActiveOrganization();
  const { data: projects } = useQueryProjects({ organizationUuid });
  const { data: organizations, isLoading } = useGetOrganizations();
  const globalStore = useSelector((state) => state.global);
  const navigate = useNavigate();

  const { onSetupEnvironment, isLoading: isSettingEnvironment } = useApplicationSetup();

  useEffect(() => {
    if (organizations?.data.length && projects?.total > 0) {
      if (globalStore.newEnvironmentSkipped) {
        navigate(PATHS.PROJECT.APPLICATION.FULL_PATH);
      }
    }
  }, [globalStore, navigate, organizations, projects?.total]);

  const disableEnvironmentModal = async () => {
    dispatch(handleNewEnvironmentSkipped());
    navigate(PATHS.PROJECTS.FULL_PATH);
  };

  if (isLoading)
    return (
      <CardContent>
        <ComponentSpinner />
      </CardContent>
    );

  if (isSettingEnvironment)
    return (
      <CardContent>
        <SettingEnvironmentScreen />
      </CardContent>
    );

  return (
    <CardContent>
      <CardTitle tag="h2" className="fw-bolder mb-1">
        Setting up your environment
      </CardTitle>
      <CardText className="mb-2">Do you want SyncStage to prepare you an environment automatically?</CardText>
      <Button block onClick={onSetupEnvironment} color="primary" data-testid="environment-auto-setup">
        <span className="text-black">Yes, create everything for me (recommended)</span>
      </Button>
      <p className="text-center mt-2" data-testid="environment-manual-setup">
        <a href="#" onClick={() => disableEnvironmentModal()}>
          <span>No, I want to configure SyncStage myself.</span>
        </a>
      </p>
    </CardContent>
  );
};

export default EnvironmentSetUp;
