import { useState } from 'react';

export function useRetrieveApplication({ projectUuid }) {
  const [isOpened, setIsOpened] = useState(false);
  const [selectedAppData, setSelectedAppData] = useState(null);

  const handleClose = () => {
    setIsOpened(false);
  };

  const handleOpen = (appData) => {
    setSelectedAppData({ ...appData, projectUuid });
    setIsOpened(true);
  };

  return {
    onOpen: handleOpen,
    isOpened,
    selectedAppData,
    setSelectedAppData,
    onClose: handleClose,
  };
}
