import { useQueryMe } from '@api/hooks/auth/authQuery';
import { handleClearProject, handleSelectProject } from '@store/organizationsProjects';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

export function useProjectStore() {
  const dispatch = useDispatch();
  const { data } = useQueryMe();
  const userUuid = data?.uuid;

  const handleSave = useCallback(
    ({ projectUuid }) => {
      if (!userUuid) {
        return;
      }

      localStorage.setItem(`project_${userUuid}`, projectUuid);
      dispatch(handleSelectProject({ projectUuid }));
    },
    [dispatch, userUuid]
  );

  const handleRead = useCallback(() => {
    if (!userUuid) {
      return;
    }

    return localStorage.getItem(`project_${userUuid}`);
  }, [userUuid]);

  const handleClear = useCallback(() => {
    if (!userUuid) {
      return;
    }

    localStorage.removeItem(`project_${userUuid}`);
    dispatch(handleClearProject());
  }, [dispatch, userUuid]);

  return {
    onSaveProject: handleSave,
    onReadProject: handleRead,
    onClearProject: handleClear,
  };
}
