import { ChevronRight } from 'react-feather';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';

/* eslint-disable @typescript-eslint/no-explicit-any */
interface Props {
  project: any;
  onOpenProject: (project: any) => void;
}

function singleProjectListItem({ project, onOpenProject }: Props) {
  if (project.uuid !== null) {
    return (
      <Col
        lg={{ size: 4, order: 1 }}
        sm={{ size: 12 }}
        xs={{ order: 2 }}
        className="mb-2"
        data-testid={`project-${project.uuid}`}
      >
        <Card className="mb-0 h-100 py-2 cursor-pointer" onClick={() => onOpenProject(project)}>
          <CardBody>
            <Row className="m-0">
              <Col sm={10} className="p-0">
                <h4 className="mb-0">{project.name}</h4>
              </Col>
              <Col sm={2}>
                <Button color="transparent" className="border-0 p-0 text-primary">
                  <ChevronRight size="24px" />
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    );
  }

  return <></>;
}
export default singleProjectListItem;
