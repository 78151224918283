// ** React Imports
import { Suspense } from 'react';
import { Navigate } from 'react-router-dom';

// ** Utils
import { getHomeRouteForLoggedInUser } from '@utils/Utils';
import { useIsAuthorized } from '@utils/hooks/useIsAuthorized';

const PublicRoute = ({ children, route }) => {
  const isUserLogged = useIsAuthorized();
  if (route) {
    const restrictedRoute = route.meta && route.meta.restricted;

    if (isUserLogged && restrictedRoute) {
      return <Navigate to={getHomeRouteForLoggedInUser()} />;
    }
  }

  return <Suspense fallback={null}>{children}</Suspense>;
};

export default PublicRoute;
