import { useMutateCreateOrganization } from '@api/hooks/organization/organizationQuery';
import ToastContent from '@src/views/components/toastContent';
import FormActionsFinish from '@utils/components/form/FormActions/FormActionsFinish';
import { useOverlayContext } from '@utils/context/OverlayContext/useOverlayContext';
import { useWizardFormStepperContext } from '@utils/context/WizardFormStepperContext';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import toast from 'react-hot-toast';
import { mapFormOrganizationToOrganization } from './helpers';
import { useTranslation } from 'react-i18next';
import SubmitConfirmation from '@utils/components/SubmitConfirmation/SubmitConfirmation';

const SubmitStep = ({ refetchOrganizations, toggle }) => {
  const { t } = useTranslation();
  const [onShowLoader, onHideLoader] = useOverlayContext();
  const { mutateAsync, isLoading: isCreatingOrganization } = useMutateCreateOrganization();
  const { onPrevStep, onSubmit } = useWizardFormStepperContext();

  const {
    formState: { isValid },
    getValues,
  } = useFormContext();

  const handleCreateOrganization = useCallback(() => {
    onShowLoader();

    const organization = mapFormOrganizationToOrganization(getValues());

    mutateAsync(organization)
      .then(() => {
        toast((t) => <ToastContent t={t} success={true} header="organizations.successNewOrganizationCreation" />);
        refetchOrganizations();
        toggle();
      })
      .catch(() => {
        toast((t) => <ToastContent t={t} success={false} header="organizations.failedNewOrganizationCreation" />);
      })
      .finally(() => {
        onHideLoader();
      });
  }, [getValues, mutateAsync, onHideLoader, onShowLoader, refetchOrganizations, toggle]);

  const handleSubmit = () => {
    onSubmit(handleCreateOrganization);
  };

  return (
    <>
      <SubmitConfirmation
        title={t('organizations.createOrganization.submit.title')}
        subtitle={t('organizations.createOrganization.submit.subtitle')}
      />

      <FormActionsFinish
        onPrevious={onPrevStep}
        onSubmit={handleSubmit}
        isLoading={isCreatingOrganization}
        nextProps={{ disabled: !isValid }}
      />
    </>
  );
};

export default SubmitStep;
