import { PieChart } from 'react-feather';
import { Row, Col, Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useGetProjects } from '@src/views/pages/project/hooks/useGetProjects';
import { useApplicationsInOrganization } from '../../hooks/useApplicationsInOrganization';
import { useFormContext } from 'react-hook-form';
import { Select } from '@utils/components/form/Inputs';

const PER_PAGE_OPTIONS = [
  { label: 10, value: 10 },
  { label: 20, value: 20 },
  { label: 30, value: 30 },
];

/**
 *
 * @param {object} props
 * @param {() => void} props.onExportAsCSV
 * @returns
 */
const Filters = ({ onExportAsCSV }) => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const PROJECTS_DEFAULT_VALUE = { label: t('costExplorer.allApplications'), value: '' };
  const APPLICATIONS_DEFAULT_VALUE = { label: t('costExplorer.allProjects'), value: '' };

  const { data: applications, isLoading: isApplicationsLoading } = useApplicationsInOrganization({
    select(data) {
      const applications = data.flatMap((projects) => {
        return projects.applications.map((application) => {
          return {
            label: application.applicationName,
            value: application.applicationUuid,
          };
        });
      });

      return [APPLICATIONS_DEFAULT_VALUE, ...applications];
    },
  });
  const { data: projects, isLoading: isProjectsLoading } = useGetProjects({
    select({ data }) {
      const projects = data.map((project) => ({ label: project.name, value: project.uuid }));
      return [PROJECTS_DEFAULT_VALUE, ...projects];
    },
  });

  return (
    <>
      <Row className="m-0 gap-3 align-items-end">
        <Col sm="1" className="p-0">
          <Select control={control} label={t('show')} name="rowsPerPage" options={PER_PAGE_OPTIONS} />
        </Col>
        <Col sm="3" className="p-0">
          <Select
            control={control}
            label={t('costExplorer.application')}
            name="application"
            options={applications}
            isLoading={isApplicationsLoading}
          />
        </Col>
        <Col sm="3" className="p-0">
          <Select
            control={control}
            label={t('costExplorer.project')}
            name="project"
            options={projects}
            isLoading={isProjectsLoading}
          />
        </Col>
        <Col sm="auto" className="p-0 ms-auto">
          <Button color="primary" onClick={onExportAsCSV} data-testid="export-button">
            <PieChart className="font-small-4 me-50" />
            <span className="align-middle">{t('Export')}</span>
          </Button>
        </Col>
      </Row>
    </>
  );
};
export default Filters;
