import themeConfig from '@configs/themeConfig';
import '@src/assets/scss/pages/login.scss';
import { PATHS } from '@src/router/paths';
import { AbilityContext } from '@src/utility/context/Can';
import '@styles/react/pages/page-authentication.scss';
import { useLogin } from '@utils/hooks/useLogin';
import { useCallback, useContext, useEffect } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Card, CardBody, CardText, CardTitle } from 'reactstrap';
import AuthenticationApi from '../../../api/useAuthenticationApi';
import { DefaultRoute } from '../../../router/routes';
import ToastContent from '../../components/toastContent/ToastContent';

const TokenLogin = () => {
  // ** Hooks
  const navigate = useNavigate();
  const ability = useContext(AbilityContext);
  const { onLogin } = useLogin();

  const { t } = useTranslation();

  const attemptLogin = useCallback(async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const invitationSecret = urlParams.get('invitationSecret');
    if (!invitationSecret) {
      navigate(PATHS.AUTH.LOGIN.FULL_PATH);
    } else {
      await AuthenticationApi.activateUser(invitationSecret)
        .then((result) => {
          const signInData = {
            accessToken: result.data.AuthenticationResult.AccessToken,
            refreshToken: result.data.AuthenticationResult.RefreshToken,
            // given all access until proper roles system will be implemented
            role: 'admin',
            ability: [
              {
                action: 'manage',
                subject: 'all',
              },
            ],
          };
          onLogin(signInData);
          ability.update(signInData.ability);
          if (result.data.userId) {
            navigate(DefaultRoute);
            toast((t) => (
              <ToastContent t={t} success={true} header="login.successLogin" content="login.successLoginContent" />
            ));
          } else {
            toast((t) => <ToastContent t={t} success={false} header="login.failedLogin" />);
            navigate(PATHS.AUTH.LOGIN.FULL_PATH);
          }
        })
        .catch(() => {
          toast((t) => <ToastContent t={t} success={false} title="login.failedLogin" />);
          navigate(PATHS.AUTH.LOGIN.FULL_PATH);
        });
    }
  }, [navigate, onLogin, ability]);

  useEffect(() => {
    attemptLogin();
  }, [attemptLogin]);

  return (
    <div className="login-card-content">
      <Card className="fit-content">
        <img src={themeConfig.app.appLogoFullImage} alt="logo" height={50} className="mt-2 mb-2" />
        <CardBody>
          <CardTitle tag="h2" className="fw-bold mb-1">
            {t('tokenLogin.welcomeToSyncStage')}
          </CardTitle>
          <CardText className="mb-3">{t('tokenLogin.loggingIn')}</CardText>
        </CardBody>
      </Card>
    </div>
  );
};

export default TokenLogin;
