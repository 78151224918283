import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Row, Col, Card, Button, CardBody, CardHeader, Form, Input as StrapInput, Label } from 'reactstrap';
import toast from 'react-hot-toast';
import { profileSchema } from './profileSchema';
import { useTranslation } from 'react-i18next';
import { ME_QUERY_KEY, useMutateUpdateUser } from '@api/hooks/auth/authQuery';
import ToastContent from '@src/views/components/toastContent/ToastContent';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import SubmitButton from '@src/views/components/buttons/SubmitButton/SubmitButton';
import { Input } from '@utils/components/form/Inputs';

const ProfileDetails = ({ user }) => {
  const { t } = useTranslation();
  const { state: locationState } = useLocation();
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm({
    resolver: yupResolver(profileSchema),
    defaultValues: {
      firstName: user.firstName,
      lastName: user.lastName,
    },
  });
  const { mutateAsync: updateUser, isLoading: isSaving } = useMutateUpdateUser();
  const client = useQueryClient();

  const handleUpdateUserData = (data) => {
    updateUser(data).then(() => {
      client.setQueryData(ME_QUERY_KEY, (oldData) => ({
        ...oldData,
        firstName: data.firstName,
        lastName: data.lastName,
      }));

      reset(data);
      toast((t) => <ToastContent t={t} success header="userProfile.successUserProfileUpdate" />);

      if (locationState?.goBack) {
        navigate(locationState.goBack);
      }
    });
  };

  return (
    <Form className="p-0" onSubmit={handleSubmit(handleUpdateUserData)}>
      <Card className="p-0">
        <CardHeader className="border border-bottom">
          <h4 className="m-0">{t('userProfile.general.profileDetails')}</h4>
        </CardHeader>
        <CardBody className="mt-2">
          <Row>
            <Col sm={6} className="form-field">
              <Input
                control={control}
                name="firstName"
                label={t('general.firstName')}
                autoFocus
                type="text"
                placeholder="John"
              />
            </Col>
            <Col sm={6} className="form-field">
              <Input name="lastName" control={control} label={t('general.lastName')} type="text" placeholder="Doe" />
            </Col>
          </Row>
          <Row>
            <Col sm={6} className="form-field">
              <Label className="form-label" for="email">
                {t('general.email')}
              </Label>
              <StrapInput readOnly name="email" id="email" value={user.email} />
            </Col>
          </Row>
          <div className="m-0 d-flex justify-content-start mt-3">
            <SubmitButton className="w-auto" color="primary" type="submit" disabled={!isDirty} isLoading={isSaving}>
              {t('general.saveChanges')}
            </SubmitButton>
            <Button
              className="w-auto ms-2"
              color="outline-secondary"
              disabled={!isDirty || isSaving}
              onClick={() => reset()}
              data-testid={`discard`}
            >
              {t('general.discard')}
            </Button>
          </div>
        </CardBody>
      </Card>
    </Form>
  );
};

export default ProfileDetails;
