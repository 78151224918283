import { useInitial } from './hooks/useInitial';
import SpinnerComponent from '@components/spinner/Fallback-spinner';

function UserProtectedRoute({ children }) {
  const { isLoading: isLoadingInitial } = useInitial();

  if (isLoadingInitial) {
    return <SpinnerComponent />;
  }

  return <>{children}</>;
}

export default UserProtectedRoute;
