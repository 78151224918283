import { useCallback, useContext } from 'react';
import { OverlayContext } from './OverlayContext';

export function useOverlayContext() {
  const { setShowLoadingOverlay } = useContext(OverlayContext);

  return [
    useCallback(() => setShowLoadingOverlay(true), [setShowLoadingOverlay]),
    useCallback(() => setShowLoadingOverlay(false), [setShowLoadingOverlay]),
  ];
}
