import config from './config';
import getAxiosClient, { setToken, setRefreshToken, removeToken, removeRefreshToken } from './ApiClient';

const AuthenticationApi = () => {
  const client = getAxiosClient();

  const login = async (...args) => {
    try {
      const res = await client.post(config.loginEndpoint, ...args, {
        skipAuthRefresh: true,
      });
      setToken(res.data.AuthenticationResult.IdToken);
      setRefreshToken(res.data.AuthenticationResult.RefreshToken);
      return res;
    } catch (error) {
      return null;
    }
  };

  const register = async (...args) => {
    return await client.post(config.registerEndpoint, ...args);
  };

  const resendActivationEmail = async (...args) => {
    return await client.post(config.resendActivationEmailEndpoint, ...args);
  };

  const sendResetPasswordLink = async (...args) => {
    return await client.post(config.sendResetPasswordLink, ...args);
  };

  const resetPassword = async (resetSecret, ...args) => {
    return client.post(`${config.resetPassword}?resetSecret=${resetSecret}`, ...args);
  };

  const activateUser = async (invitationSecret, ...args) => {
    const res = await client.get(`${config.activateUser}?invitationSecret=${invitationSecret}`, ...args);
    setToken(res.data.AuthenticationResult.IdToken);
    setRefreshToken(res.data.AuthenticationResult.RefreshToken);
    return res;
  };

  const createDefaultApps = async (...args) => {
    return await client.post(`${config.createDefaultApps}`, ...args);
  };

  const getMe = async (...args) => {
    return await client.get(`${config.getUserData}`, ...args);
  };

  const putMe = async (data, options = {}) => {
    return await client.put(`${config.getUserData}`, data, options);
  };

  /**
   *
   * @param {Object} data
   * @param {string} data.firstName
   * @param {string} data.lastName
   * @param {string} data.emailAddress
   * @param {string} data.company
   * @param {string} data.country
   * @param {string} data.category
   * @param {string} data.recaptchaResponse
   * @param {import('axios').AxiosRequestConfig} [options]
   * @returns
   */
  const applyForAccessCode = async (data, options = {}) => {
    return await client.post(config.applyForAccessCode, data, options);
  };

  const logout = () => {
    removeToken();
    removeRefreshToken();
  };

  return {
    login,
    register,
    logout,
    resendActivationEmail,
    sendResetPasswordLink,
    resetPassword,
    activateUser,
    createDefaultApps,
    getMe,
    putMe,
    applyForAccessCode,
  };
};

export default AuthenticationApi();
