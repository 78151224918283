// ** React Imports
import { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';

// ** Config
import themeConfig from '@configs/themeConfig';

// ** Third Party Components
import toast from 'react-hot-toast';
import { useForm, Controller } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

// ** Context
import { AbilityContext } from '@src/utility/context/Can';

// ** Custom Components
import InputPasswordToggle from '@components/input-password-toggle';

// ** Reactstrap Imports
import { Form, Label, CardText, CardTitle, Card, CardBody, FormFeedback } from 'reactstrap';

// ** Styles
import '@styles/react/pages/page-authentication.scss';
import '@src/assets/scss/pages/login.scss';

// ** Other
import { yupResolver } from '@hookform/resolvers/yup';
import { loginSchema } from '../schemas/loginSchema';
import { ApplicationRoute } from '@src/router/routes';
import SubmitButton from '@src/views/components/buttons/SubmitButton/SubmitButton';
import { useLogin } from '@utils/hooks/useLogin';
import { PATHS } from '@src/router/paths';
import { LoginFormState } from './types';
import { useMutateLogin } from '@api/hooks/auth/authQuery';
import { AuthData } from '@src/models/user/auth';
import ToastContent from '@src/views/components/toastContent';
import { errorMessage } from '@utils/errors/errorMessages';
import { Checkbox, Input } from '@utils/components/form/Inputs';

const defaultValues = {
  password: '',
  emailAddress: '',
};

const Login = () => {
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormState>({ defaultValues, resolver: yupResolver(loginSchema) });

  const { mutateAsync: login, isLoading } = useMutateLogin();

  const ability = useContext(AbilityContext);
  const { onLogin } = useLogin();

  const { t } = useTranslation();

  const onSubmit = async (data: LoginFormState) => {
    login({ emailAddress: data.emailAddress, password: data.password })
      .then((res) => {
        if (res !== null) {
          const signInData: AuthData = {
            accessToken: res.AuthenticationResult.AccessToken,
            refreshToken: res.AuthenticationResult.RefreshToken,
            userId: res.userId,
            // given all access until proper roles system will be implemented
            role: 'admin',
            ability: [
              {
                action: 'manage',
                subject: 'all',
              },
            ],
          };

          ability?.update?.(signInData.ability);
          onLogin(signInData);
          navigate(ApplicationRoute);
          <ToastContent t={t} success={true} header="login.successLogin" content="login.successLoginContent" />;
        } else {
          toast((t) => <ToastContent t={t} success={false} header="login.failedLogin" />);
        }
      })
      .catch(() => {
        toast((t) => <ToastContent t={t} success={false} header="login.failedLogin" />);
      });
  };

  return (
    <div className="login-card-content">
      <Card className="fit-content">
        <img src={themeConfig.app.appLogoFullImage} alt="logo" height={50} className="mt-2 mb-2" />
        <CardBody>
          <CardTitle tag="h2" className="fw-bold mb-1">
            {t('login.welcomeToSyncStage')}
          </CardTitle>
          <CardText className="mb-3">{t('login.pleaseSignIn')}</CardText>
          <Form className="auth-login-form mt-2" onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-2">
              <Input
                control={control}
                name="emailAddress"
                autoFocus
                id="emailAddress"
                type="email"
                placeholder="john@example.com"
                label={t('general.email')}
                tabIndex={1}
              />
            </div>
            <div className="mb-2">
              <div className="d-flex justify-content-between">
                <Label className="form-label" for="loginPassword">
                  {t('general.password')}
                </Label>
                <Link to={PATHS.AUTH.FORGOT_PASSWORD.FULL_PATH} tabIndex={5}>
                  <small>{t('login.forgotPassword')}</small>
                </Link>
              </div>
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <InputPasswordToggle
                    id="password"
                    {...field}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    tabIndex={2}
                  />
                )}
              />
              <FormFeedback>{errorMessage(t, errors.password?.message)}</FormFeedback>
            </div>
            <div className="form-check mb-2">
              <Checkbox control={control} name="rememberMe" label={t('login.rememberMe')} tabIndex={3} />
            </div>
            <SubmitButton type="submit" color="primary" block disabled={isLoading} isLoading={isLoading} tabIndex={4}>
              {t('general.signIn')}
            </SubmitButton>
          </Form>
          <p className="text-center mt-2">
            <Trans
              t={t}
              i18nKey="login.createAccount"
              components={{ a: <Link to={PATHS.AUTH.REGISTER.FULL_PATH} /> }}
            />
          </p>
        </CardBody>
      </Card>
    </div>
  );
};

export default Login;
