import { useQueryProvisioningCode, useQueryProvisioningCodeStatus } from '@api/hooks/application/applicationQuery';
import { useTranslation } from 'react-i18next';
import { AppValues, ConfigurationModalMode } from '../../types';
import ProvisioningCode from './ProvisioningCode';
import { getSecondsToDate } from '@utils/Utils';
import UILoader from '@components/ui-loader';
import ProvisioningAcceptance from '../provisioningAcceptance';
import toast from 'react-hot-toast';
import ToastContent from '@src/views/components/toastContent';
import { CONFIGURE_APP_MODES } from '../../consts';

type Props = {
  appValues: AppValues;
  onChangeMode: (mode: ConfigurationModalMode) => void;
};

const SECOND_IN_MS = 1000;

export default function GenerateKey({ appValues, onChangeMode }: Props) {
  const { t } = useTranslation();
  const { data: provisioningCode, isFetching } = useQueryProvisioningCode(
    { applicationUuid: appValues.uuid },
    {
      refetchInterval(data) {
        if (!data) {
          return false;
        }

        return getSecondsToDate(data.expirationDate) * SECOND_IN_MS;
      },
    }
  );

  const { data: codeStatus } = useQueryProvisioningCodeStatus(
    {
      applicationUuid: appValues.uuid,
      provisioningCode: provisioningCode?.provisioningCode || '',
    },
    {
      enabled: !!provisioningCode?.provisioningCode,
      refetchInterval: (data, query) => {
        if (data?.acceptanceRequestedBy) {
          return false;
        }

        if (data?.requestRejectedBy) {
          query.invalidate();
          toast((t) => (
            <ToastContent t={t} success={false} header="applications.provisioningAcceptance.codeHasBeenRejected" />
          ));
          onChangeMode(CONFIGURE_APP_MODES.GENERAL);

          return false;
        }

        return SECOND_IN_MS;
      },
    }
  );

  return (
    <>
      <h2 data-testid="app-provisioning" className="text-center h3">
        {t('applications.testAppProvisioning')}
      </h2>
      {isFetching ? (
        <UILoader blocking className="modal-form-content" />
      ) : (
        <>
          {!!codeStatus?.acceptanceRequestedBy ? (
            <ProvisioningAcceptance
              appValues={appValues}
              codeStatus={{ ...codeStatus, acceptanceRequestedBy: codeStatus.acceptanceRequestedBy }}
              onChangeMode={onChangeMode}
            />
          ) : (
            <ProvisioningCode
              expirationDate={provisioningCode?.expirationDate || ''}
              provisioningCode={provisioningCode?.provisioningCode || ''}
            />
          )}
        </>
      )}
    </>
  );
}
