import { Activity, Clock, Users } from 'react-feather';
import { Card, CardBody, Row, Col } from 'reactstrap';

const UsageSummary = ({ usage }) => {
  return (
    <Card className="d-flex align-items-stretch" style={{ minHeight: 140 }}>
      <CardBody>
        <h4>Usage Summary</h4>
        {!!usage && (
          <Row className="mt-2">
            <Col sm={4} className="d-flex" data-testid="minutes-used">
              <div className="avatar avatar-stats p-50 m-0 bg-light-primary pe-none">
                <div className="avatar-content">{<Clock />}</div>
              </div>
              <div className="flex-row align-items-center my-auto ms-1">
                <h4 className="mb-0">{usage.totalMinutesUsed}</h4>
                <p className="mb-0">Minutes Used</p>
              </div>
            </Col>
            <Col sm={4} className="d-flex" data-testid="sessions">
              <div className="avatar avatar-stats p-50 m-0 bg-light-danger pe-none">
                <div className="avatar-content">{<Activity />}</div>
              </div>
              <div className="flex-row align-items-center my-auto ms-1">
                <h4 className="mb-0">{usage.sessionCnt}</h4>
                <p className="mb-0">Sessions</p>
              </div>
            </Col>
            <Col sm={4} className="d-flex" data-testid="users">
              <div className="avatar avatar-stats p-50 m-0 bg-light-info pe-none">
                <div className="avatar-content">{<Users />}</div>
              </div>
              <div className="flex-row align-items-center my-auto ms-1">
                <h4 className="mb-0">{usage.userCnt}</h4>
                <p className="mb-0">Users</p>
              </div>
            </Col>
          </Row>
        )}
      </CardBody>
    </Card>
  );
};

export default UsageSummary;
