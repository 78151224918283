import { queryClient } from '@src/queryClient';
import { removeUserData } from './Utils';
import { store } from '@store/store';
import { handleUnauthorize } from '@store/authorization';

export const onInvalidateUser = () => {
  removeUserData();
  queryClient.removeQueries();
  store.dispatch({ type: 'CLEAR_STORE' });
  store.dispatch(handleUnauthorize());
};
