import { useCallback, useContext, useEffect } from 'react';
import { ContentOverlayContext } from './ContentOverlayContext';

export function useContentOverlayContext() {
  const { setShowLoadingOverlay } = useContext(ContentOverlayContext);

  useEffect(() => () => setShowLoadingOverlay(false), [setShowLoadingOverlay]);

  return [
    useCallback(() => setShowLoadingOverlay(true), [setShowLoadingOverlay]),
    useCallback(() => setShowLoadingOverlay(false), [setShowLoadingOverlay]),
  ];
}
