import { Box, Settings } from 'react-feather';

export default [
  {
    header: 'Project',
  },
  {
    id: 'project-application',
    title: 'Application',
    icon: <Box size={20} />,
    navLink: '/application',
  },
  {
    id: 'project-settings',
    title: 'Settings',
    icon: <Settings size={20} />,
    navLink: '/project-settings',
  },
];
