import { yupResolver } from '@hookform/resolvers/yup';
import { UserPlus } from 'react-feather';
import { useForm } from 'react-hook-form';
import { Button, Col, Row } from 'reactstrap';
import { addMemberSchema } from './schema';
import { useTranslation } from 'react-i18next';
import { Select } from '@utils/components/form/Inputs';

function ProjectMemberPicker({ onSubmit, projectRoles, currentMembers, organizationMembers }) {
  const { t } = useTranslation();

  const {
    control,
    handleSubmit: onAddMember,
    formState,
    reset,
  } = useForm({
    defaultValues: {
      user: null,
      role: null,
    },
    resolver: yupResolver(addMemberSchema),
    mode: 'onChange',
  });

  const handleAddMember = (data) => {
    onSubmit({
      email: data.user.email,
      name: data.user.name,
      uuid: data.user.value,
      role: data.role,
    });
    reset();
  };

  const organizationMembersToDisplay = organizationMembers?.filter(
    (member) => !currentMembers.find((currentMember) => currentMember.uuid === member.value)
  );

  return (
    <form onSubmit={onAddMember(handleAddMember)}>
      <Row>
        <Col sm={7}>
          <Select
            control={control}
            name="user"
            options={organizationMembersToDisplay}
            isClearable={false}
            label={t('general.addMembers')}
          />
        </Col>
        <Col sm={4} className="pe-0">
          <Select
            control={control}
            name="role"
            options={projectRoles}
            isClearable={false}
            label={t('general.selectRole')}
          />
        </Col>
        <Col sm={1} className="d-flex align-items-end">
          <Button type="submit" outline className="px-1" disabled={!formState.isValid} color="primary">
            <UserPlus height="18px" className="text-primary" />
          </Button>
        </Col>
      </Row>
    </form>
  );
}

export default ProjectMemberPicker;
