import { useQueryCost } from '@api/hooks/cost/costQuery';
import { useContentOverlayContext } from '@utils/context/ContentOverlayContext/useContentOverlayContext';
import { useActiveOrganization } from '@utils/hooks/useActiveOrganization';
import { useEffect } from 'react';

/**
 *
 * @param {Omit<CostExplorerUsageParams, "organizationUuid">} params
 */
export function useCostData(params) {
  const [onShowOverlay, onHideOverlay] = useContentOverlayContext();
  const organizationUuid = useActiveOrganization();

  const result = useQueryCost(
    { ...params, organizationUuid },
    {
      enabled: !!params.from && !!params.to && !!organizationUuid,
    }
  );

  useEffect(() => {
    if (result.isLoading) onShowOverlay();
    else onHideOverlay();
  }, [onHideOverlay, onShowOverlay, result.isLoading]);

  return result;
}
