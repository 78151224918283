import BreadCrumbs from '@components/breadcrumbs';
import { useState } from 'react';
import { Cloud, Lock } from 'react-feather';
import { Button, Row } from 'reactstrap';
import { General } from './tabs';

const TABS = {
  GENERAL: 'general',
  SECURITY: 'security',
};

const UserProfile = () => {
  const [currentTab, setCurrentTab] = useState(TABS.GENERAL);

  const handleSetTab = (newTab) => () => {
    setCurrentTab(newTab);
  };

  return (
    <div className="px-4">
      <BreadCrumbs title={''} data={[{ title: 'Account' }, { title: 'Settings' }]} />
      <Row className="m-0 d-flex justify-content-start">
        <Button
          className="w-auto"
          color={`${currentTab === TABS.GENERAL ? 'primary' : 'transparent'}`}
          onClick={handleSetTab(TABS.GENERAL)}
        >
          <Cloud /> General
        </Button>
        <Button
          disabled
          className="w-auto"
          color={`${currentTab === TABS.SECURITY ? 'primary' : 'transparent'}`}
          // TODO security tab
          //   onClick={handleSetTab(TABS.SECURITY)}
        >
          <Lock /> Security
        </Button>
      </Row>
      <Row className="m-0 mt-2">{currentTab === TABS.GENERAL && <General />}</Row>
    </div>
  );
};

export default UserProfile;
