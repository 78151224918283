// // ** Core JWT Import
import getAxiosClient from './ApiClient';
import config from './config';

const ProjectApi = () => {
  const client = getAxiosClient();

  const getMyProjects = async (orgId, ...args) => {
    return await client.get(`${config.projectEndpoint}?organizationUuid=${orgId}`, ...args);
  };

  const createProject = async (...args) => {
    const result = await client.post(`${config.projectEndpoint}`, ...args);
    return result;
  };
  const updateProject = async (projectUuid, ...args) => {
    const result = await client.put(`${config.projectEndpoint}/${projectUuid}`, ...args);
    return result;
  };
  const deleteProject = async (projectUuid, ...args) => {
    const result = await client.delete(`${config.projectEndpoint}/${projectUuid}`, ...args);
    return result;
  };

  const getProjectMembers = async (projectUuid, ...args) => {
    const result = await client.get(`${config.projectEndpoint}/${projectUuid}/members`, ...args);
    return result;
  };
  const removeProjectMembers = async (projectUuid, emailAddress, ...args) => {
    const result = await client.delete(
      `${config.projectEndpoint}/${projectUuid}/remove-user?emailAddress=${encodeURIComponent(emailAddress)}`,
      ...args
    );
    return result;
  };
  const updateRoleInProject = async (projectUuid, ...args) => {
    const result = await client.put(`${config.projectEndpoint}/${projectUuid}/role`, ...args);
    return result;
  };
  const inviteToProject = async (projectUuid, ...args) => {
    const result = await client.post(`${config.projectEndpoint}/${projectUuid}/invite`, ...args);
    return result;
  };

  return {
    getMyProjects,
    createProject,
    updateProject,
    deleteProject,
    getProjectMembers,
    removeProjectMembers,
    updateRoleInProject,
    inviteToProject,
    config,
  };
};

// Instantiate the interceptor
export default ProjectApi();
