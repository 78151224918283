import SubmitButton from '@src/views/components/buttons/SubmitButton/SubmitButton';
import classNames from 'classnames';
import { ArrowLeft, ArrowRight } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import './form-actions.scss';

/**
 * @typedef {object} Props
 * @property {string} nextLabel
 */

/**
 * @param {import('./types').FormActionProps & Props} props
 * @returns {JSX}
 */
function FormActions({ onPrevious, onNext, nextProps, prevProps, showPrevious = true, nextLabel }) {
  const { t } = useTranslation();

  const nextStepButtonProps = onNext ? { onClick: onNext, ...nextProps } : nextProps;
  const prevStepButtonProps = onPrevious ? { onClick: onPrevious, ...prevProps } : prevProps;

  return (
    <div className={classNames('form-actions', !showPrevious && 'single-action')}>
      {showPrevious && (
        <Button {...prevStepButtonProps} color="outline-primary" data-testid="wizard-prev">
          <ArrowLeft size={14} className="rotate-rtl align-middle me-sm-50 me-0" />
          <div className="align-middle d-sm-inline-block d-none">{t('Prev')}</div>
        </Button>
      )}
      <SubmitButton {...nextStepButtonProps} block color="primary" data-testid="wizard-next">
        <div className="align-middle d-sm-inline-block d-none text-black">{nextLabel}</div>
        <ArrowRight size={14} className="rotate-rtl align-middle ms-sm-50 ms-0 text-black" />
      </SubmitButton>
    </div>
  );
}

export default FormActions;
