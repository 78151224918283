import { APPLICATIONS_QUERY, useMutateCreateApplication } from '@api/hooks/application/applicationQuery';
import { useGetApplicationPlatformsDictionary } from '@api/hooks/dictionaries';
import ToastContent from '@src/views/components/toastContent/ToastContent';
import { useQueryClient } from '@tanstack/react-query';
import { useOverlayContext } from '@utils/context/OverlayContext/useOverlayContext';
import toast from 'react-hot-toast';

function getPlatformUuid(platforms: ApplicationPlatform[], platformName: string) {
  return platforms.find((platform) => platform.name === platformName)?.uuid || '';
}

export function useCreateApplication({ projectUuid }, { onSuccess }) {
  const result = useMutateCreateApplication({ projectUuid });
  const [onStartLoading, onStopLoading] = useOverlayContext();

  const { data: applicationPlatforms, refetch: refetchApplicationPlatforms } = useGetApplicationPlatformsDictionary();
  const client = useQueryClient();

  const handleCreateApp = async (formData) => {
    onStartLoading();
    let platformUuid = '';

    if (!applicationPlatforms || applicationPlatforms.length === 0) {
      try {
        const { data: applicationPlatforms } = await refetchApplicationPlatforms();
        platformUuid = getPlatformUuid(applicationPlatforms || [], formData.platformName);
      } catch (e) {
        toast((t) => <ToastContent t={t} success={false} header="applications.failedNewAppCreation" />);
        return;
      }
    } else {
      platformUuid = getPlatformUuid(applicationPlatforms || [], formData.platformName);
    }

    result.mutate(
      {
        name: formData.name,
        applicationPlatformUuid: platformUuid,
        packageName: formData.packageName,
      },
      {
        onSettled: () => {
          onStopLoading();
        },
        onSuccess: () => {
          client.refetchQueries({
            queryKey: APPLICATIONS_QUERY({
              projectUuid,
            }),
          });
          toast((t) => <ToastContent t={t} success={true} header="applications.successNewAppCreation" />);
          onSuccess();
        },
        onError: () => {
          toast((t) => <ToastContent t={t} success={false} header="applications.failedNewAppCreation" />);
        },
      }
    );
  };

  return {
    ...result,
    onCreateApp: handleCreateApp,
  };
}
