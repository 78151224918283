import { PATHS } from '@src/router/paths';
import { t } from 'i18next';
import { User, DollarSign, Briefcase } from 'react-feather';

export default [
  {
    header: 'Account',
  },
  {
    id: 'organization',
    title: t('organizations.organization'),
    icon: <Briefcase size={20} />,
    navLink: PATHS.ACCOUNT.ORGANIZATION.FULL_PATH,
  },
  {
    id: 'cost-explorer',
    title: t('costExplorer.title'),
    icon: <DollarSign size={20} />,
    navLink: PATHS.ACCOUNT.COST_EXPLORER.FULL_PATH,
  },
  {
    id: 'profile',
    title: t('Profile'),
    icon: <User size={20} />,
    navLink: PATHS.ACCOUNT.PROFILE.FULL_PATH,
  },
];
