import { useMutateInviteToOrganization } from '@api/hooks/organization/organizationQuery';
import ToastContent from '@src/views/components/toastContent/ToastContent';
import { useOverlayContext } from '@utils/context/OverlayContext/useOverlayContext';
import toast from 'react-hot-toast';

export function useInviteMany({ organizationUuid }, { onSuccess }) {
  const { mutateAsync } = useMutateInviteToOrganization({ organizationUuid });
  const [onShowOverlay, onHideOverlay] = useOverlayContext();

  const handleInviteMany = async (users) => {
    try {
      onShowOverlay();
      await Promise.all(
        users.map((user) => {
          return mutateAsync({
            emailAddress: user.email,
            roleUuid: user.roleUuid,
          });
        })
      );
      toast((t) => <ToastContent t={t} success={true} header="organizations.successOrganizationInvitations" />);
      onSuccess?.();
    } catch (error) {
      toast((t) => <ToastContent t={t} success={false} header="organizations.failedOrganizationInvite" />);
    }

    onHideOverlay();
  };

  return {
    onInviteMany: handleInviteMany,
  };
}
