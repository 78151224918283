import { PATHS } from '@src/router/paths';
import SubmitButton from '@src/views/components/buttons/SubmitButton/SubmitButton';
import { Check, ChevronLeft } from 'react-feather';
import { Controller, useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Col, FormFeedback, Input, Label, Row } from 'reactstrap';
import { errorMessage } from '../../../utility/errors/errorMessages';

function RegisterPersonalInfoStep({ stepper, isLoading }) {
  const {
    control,
    formState: { errors, dirtyFields },
  } = useFormContext();
  const { t } = useTranslation();

  // ** If Errors on Account Form, Go back a step to display the form errors to the user
  if (errors.emailAddress || errors.password || errors.confirmPassword) {
    stepper.previous();
  }

  const submitDisabled =
    [dirtyFields?.lastName, dirtyFields?.firstName, dirtyFields?.organization].filter(Boolean).length < 3;

  return (
    <>
      <div className="content-header mb-2">
        <h2>{t('registration.accountDetails')}</h2>
        <span className="text-muted">{t('registration.enterAccountDetails')}</span>
      </div>
      <Row>
        <Col md="12" lg="6">
          <div className="mb-1">
            <Label className="form-label" for="register-firstName">
              {t('general.firstName')}
            </Label>
            <Controller
              id="register-firstName"
              name="firstName"
              control={control}
              render={({ field }) => (
                <Input autoFocus autoComplete="off" placeholder="John" invalid={errors.firstName} {...field} />
              )}
            />
            {errors.firstName ? <FormFeedback>{errorMessage(t, errors.firstName.message)}</FormFeedback> : null}
          </div>
        </Col>
        <Col md="12" lg="6">
          <div className="mb-1">
            <Label className="form-label" for="register-lastName">
              {t('general.lastName')}
            </Label>
            <Controller
              id="register-lastName"
              name="lastName"
              control={control}
              render={({ field }) => (
                <Input autoComplete="off" placeholder="Doe" invalid={errors.lastName} {...field} />
              )}
            />
            {errors.lastName ? <FormFeedback>{errorMessage(t, errors.lastName.message)}</FormFeedback> : null}
          </div>
        </Col>
        <Col md="12" lg="6">
          <div className="mb-1">
            <Label className="form-label" for="register-organization">
              {t('general.organizationName')}
            </Label>
            <Controller
              id="register-organization"
              name="organization"
              control={control}
              render={({ field }) => (
                <Input autoComplete="off" placeholder="ACME Corporation" invalid={errors.organization} {...field} />
              )}
            />
            {errors.organization ? <FormFeedback>{errorMessage(t, errors.organization.message)}</FormFeedback> : null}
          </div>
        </Col>
        <Col xs="12">
          <div className="form-check mb-1 mt-1">
            <Controller
              name="terms"
              control={control}
              render={({ field }) => (
                <Input {...field} id="terms" type="checkbox" checked={field.value} invalid={errors.terms && true} />
              )}
            />
            <Label className="form-check-label" for="terms">
              <Trans
                t={t}
                i18nKey="registration.readTermsAndConditions"
                components={{
                  a: <a href="https://sync-stage.com/developerservicesagreement" target="_blank" rel="noreferrer" />,
                }}
              />
            </Label>
          </div>
        </Col>
      </Row>

      <div className="d-flex justify-content-between mt-2">
        <Button color="primary" outline className="btn-submit" onClick={() => stepper.previous()} disabled={isLoading}>
          <ChevronLeft size={14} className="align-middle ms-sm-25 ms-0"></ChevronLeft>
          <span className="align-middle d-sm-inline-block">{t('general.previousButton')}</span>
        </Button>
        <SubmitButton
          color="primary"
          className="btn-submit"
          type="submit"
          isLoading={isLoading}
          disabled={submitDisabled}
        >
          <span className="align-middle d-sm-inline-block">{t('general.submitButton')}</span>
          <Check size={14} className="align-middle ms-sm-25 ms-0"></Check>
        </SubmitButton>
      </div>
      <p className="d-flex justify-content-end mt-2">
        <span className="me-25">{t('registration.alreadyHaveAnAccount')}</span>
        <Link to={PATHS.AUTH.LOGIN.FULL_PATH}>
          <span>{t('registration.signInInstead')}</span>
        </Link>
      </p>
    </>
  );
}
export default RegisterPersonalInfoStep;
