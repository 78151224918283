import { ChangeEvent, useState } from 'react';

export type Pagination = {
  skip: number;
  currentPage: number;
  rowsPerPage: number;
};

export type UsePaginate = {
  pagination: Pagination;
  onPageChange: ({ selected }: { selected: number }) => void;
  onPerPage: (e: number | ChangeEvent<HTMLInputElement>) => void;
};

export function usePaginate({ perPage = 10 } = {}): UsePaginate {
  const [skip, setSkip] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(perPage);

  const handlePagination = (page: { selected: number }) => {
    setCurrentPage(page.selected + 1);
    setSkip(page.selected * rowsPerPage);
  };

  const handlePerPage = (e: number | ChangeEvent<HTMLInputElement>) => {
    handlePagination({ selected: 0 });

    if (typeof e === 'number') {
      setRowsPerPage(e);
      return;
    }

    setRowsPerPage(parseInt(e.target.value));
  };

  return {
    pagination: {
      skip,
      currentPage,
      rowsPerPage,
    },
    onPageChange: handlePagination,
    onPerPage: handlePerPage,
  };
}
