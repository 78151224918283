import Wizard from '@components/wizard';
import themeConfig from '@configs/themeConfig';
import { yupResolver } from '@hookform/resolvers/yup';
import '@src/assets/scss/pages/register.scss';
import '@styles/react/pages/page-authentication.scss';
import { useCallback, useRef, useState } from 'react';
import { Home, User } from 'react-feather';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { FormProvider, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { Trans, useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { Form } from 'reactstrap';
import AuthenticationApi from '../../../api/useAuthenticationApi';
import ToastContent from '../../components/toastContent/ToastContent';
import ActivateAccount from './ActivateAccount';
import RegisterAccountStep from './RegisterAccountStep';
import RegisterPersonalInfoStep from './RegisterPersonalInfoStep';
import { registerSchema } from './schemas/registerSchema';

const Register = () => {
  const [queryParams] = useSearchParams();
  const accessCode = queryParams.get('accessCode') || '';

  const defaultValues = {
    accessCode,
    emailAddress: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    organization: '',
    terms: false,
  };

  const methods = useForm({ defaultValues, resolver: yupResolver(registerSchema) });
  const [stepper, setStepper] = useState(null);
  const [hasRegistered, setHasRegistered] = useState(false);
  const [registeredEmail, setRegisteredEmail] = useState('');
  const ref = useRef(null);
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState();
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(0);

  const onVerify = useCallback((token) => {
    setToken(token);
  }, []);

  const onSubmit = (data) => {
    const { accessCode, emailAddress, password, firstName, lastName, terms, organization } = data;
    setIsLoading(true);
    AuthenticationApi.register({
      accessCode,
      emailAddress,
      password,
      firstName,
      lastName,
      termsAndConditionsAccepted: terms,
      organizationName: organization,
      recaptchaResponse: token,
    })
      .then((res) => {
        if (res.status === 201) {
          toast((t) => (
            <ToastContent
              t={t}
              success={true}
              header="registration.successEmail"
              content="registration.successEmailContent"
            />
          ));
          setHasRegistered(true);
          setIsLoading(false);
          setRegisteredEmail(emailAddress);
          setRefreshReCaptcha(refreshReCaptcha + 1);
        }
      })
      .catch(() => {
        toast((t) => <ToastContent t={t} success={false} header="registration.failedEmail" />);
        setIsLoading(false);
        setRefreshReCaptcha(refreshReCaptcha + 1);
      });
  };

  // Activation Page Functionality
  const resendEmailVerification = () => {
    try {
      setIsLoading(true);
      AuthenticationApi.resendActivationEmail({
        emailAddress: registeredEmail,
        recaptchaResponse: token,
      }).then((res) => {
        if (res.status === 204) {
          toast((t) => (
            <ToastContent
              t={t}
              success={true}
              header="registration.resendSuccessEmail"
              content="registration.successEmailContent"
            />
          ));
          setIsLoading(false);
        } else {
          toast((t) => <ToastContent t={t} success={false} header="registration.failedEmail" />);
          setIsLoading(false);
        }
        setRefreshReCaptcha(refreshReCaptcha + 1);
      });
    } catch (error) {
      setIsLoading(false);
      toast((t) => <ToastContent t={t} success={false} header="registration.failedEmail" />);
      setRefreshReCaptcha(refreshReCaptcha + 1);
    }
  };

  const steps = [
    {
      id: 'account-details',
      title: t('general.account'),
      subtitle: t('general.enterUserData'),
      icon: <Home size={18} />,
      content: <RegisterAccountStep stepper={stepper} />,
    },
    {
      id: 'personal-info',
      title: t('general.personalInfo'),
      subtitle: t('general.addPersonalInfo'),
      icon: <User size={18} />,
      content: <RegisterPersonalInfoStep stepper={stepper} isLoading={isLoading} />,
    },
  ];

  return (
    <>
      <GoogleReCaptcha onVerify={onVerify} refreshReCaptcha={refreshReCaptcha} />

      {!hasRegistered ? (
        <div className="register-content-wrapper">
          <div className="register-content">
            <>
              <div className="image-wrapper">
                <img src={themeConfig.app.appLogoFullImage} alt="logo" height={50} className="mt-2" />
              </div>
              <Form action="/" className="auth-register-form mt-2" onSubmit={methods.handleSubmit(onSubmit)}>
                <FormProvider {...methods}>
                  <Wizard
                    type="horizontal"
                    ref={ref}
                    steps={steps}
                    options={{
                      linear: false,
                    }}
                    contentWrapperClassName="register-wizard-content"
                    headerClassName="register-wizard-header"
                    instance={(el) => setStepper(el)}
                  />
                </FormProvider>
              </Form>
              <div className="mt-1 me-2 text-end">
                <Trans
                  i18nKey="general.recaptchaInfo"
                  components={{
                    policy: <a href="https://policies.google.com/privacy"></a>,
                    terms: <a href="https://policies.google.com/terms"></a>,
                  }}
                />
              </div>
            </>
          </div>
        </div>
      ) : (
        <ActivateAccount registeredEmail={registeredEmail} resendEmailVerification={resendEmailVerification} />
      )}
    </>
  );
};

export default Register;
