import config from './config';

import getAxiosClient from './ApiClient';
import {
  AcceptProvisioningCodeResponse,
  ApplicationSecretsResponse,
  GetProvisioningCodeResponse,
  GetProvisioningCodeStatusResponse,
  RejectProvisioningCodeResponse,
} from './useApplicationApi.types';
import { AxiosRequestConfig } from 'axios';
import { ApplicationsInOrganizationResponse } from './hooks/application/types';

const ApplicationApi = () => {
  const client = getAxiosClient();

  const getApplications = async (projectUuid, ...args) => {
    try {
      const res = await client.get(`${config.createApplication}${projectUuid}`, ...args);
      return res;
    } catch (error) {
      return null;
    }
  };

  const getApplicationSecrets = async (applicationUuid: string, projectUuid: string, ...args: AxiosRequestConfig[]) => {
    return await client.get<ApplicationSecretsResponse>(
      `${config.applicationEndpoint}/${applicationUuid}/application-secret?projectUuid=${projectUuid}`,
      ...args
    );
  };

  const getApplicationsInOrganization = async (organizationUuid: string, ...args) => {
    return await client.get<ApplicationsInOrganizationResponse>(
      `${config.applicationsInOrganizationEndpoint}?organizationUuid=${organizationUuid}`,
      ...args
    );
  };

  const createApplicationSecret = async (applicationUuid, projectUuid, ...args) => {
    return await client.post(
      `${config.applicationEndpoint}/${applicationUuid}/application-secret?projectUuid=${projectUuid}`,
      ...args
    );
  };
  const createApplicationSecretJson = async (applicationUuid, projectUuid, ...args) => {
    return await client.post(
      `${config.applicationEndpoint}/${applicationUuid}/application-secret/json?projectUuid=${projectUuid}`,
      ...args
    );
  };
  const createApplicationSecretPlist = async (applicationUuid, projectUuid, ...args) => {
    return await client.post(
      `${config.applicationEndpoint}/${applicationUuid}/application-secret/plist?projectUuid=${projectUuid}`,
      ...args
    );
  };

  const deleteApplicationSecret = async (applicationUuid, secretUuid, projectUuid, ...args) => {
    return await client.delete(
      `${config.applicationEndpoint}/${applicationUuid}/application-secret/${secretUuid}?projectUuid=${projectUuid}`,
      ...args
    );
  };

  const createApplication = async (projectUuid, ...args) => {
    return await client.post(`${config.createApplication}${projectUuid}`, ...args);
  };

  const updateApplication = async (applicationUuid, projectUuid, ...args) => {
    return await client.put(`${config.applicationEndpoint}/${applicationUuid}?projectUuid=${projectUuid}`, ...args);
  };

  const deleteApplication = async (applicationUuid, projectUuid, ...args) => {
    return await client.delete(`${config.applicationEndpoint}/${applicationUuid}?projectUuid=${projectUuid}`, ...args);
  };

  const getProvisioningCode = async (applicationUuid: string, ...args: AxiosRequestConfig[]) => {
    const result = await client.get<GetProvisioningCodeResponse>(
      `${config.applicationEndpoint}/${applicationUuid}/provisioning-code`,
      ...args
    );
    return result;
  };

  const getProvisioningCodeStatus = async (
    { applicationUuid, provisioningCode }: { applicationUuid: string; provisioningCode: string },
    ...args: AxiosRequestConfig[]
  ) => {
    const result = await client.get<GetProvisioningCodeStatusResponse>(
      `${config.applicationEndpoint}/${applicationUuid}/provisioning-code/${provisioningCode}`,
      ...args
    );
    return result;
  };

  const acceptProvisioningCode = async (
    { applicationUuid, provisioningCode }: { applicationUuid: string; provisioningCode: string },
    ...args: AxiosRequestConfig[]
  ) => {
    const result = await client.post<AcceptProvisioningCodeResponse>(
      `${config.applicationEndpoint}/${applicationUuid}/provisioning-code/${provisioningCode}/accept`,
      ...args
    );
    return result;
  };

  const rejectProvisioningCode = async (
    { applicationUuid, provisioningCode }: { applicationUuid: string; provisioningCode: string },
    ...args: AxiosRequestConfig[]
  ) => {
    const result = await client.post<RejectProvisioningCodeResponse>(
      `${config.applicationEndpoint}/${applicationUuid}/provisioning-code/${provisioningCode}/reject`,
      ...args
    );
    return result;
  };

  const freezeApplicationSecret = async (
    {
      applicationUuid,
      projectUuid,
      applicationSecretId,
    }: { applicationUuid: string; projectUuid: string; applicationSecretId: string },
    ...args: AxiosRequestConfig[]
  ) => {
    const result = await client.put<null>(
      `${config.applicationEndpoint}/${applicationUuid}/application-secret/${applicationSecretId}/freeze?projectUuid=${projectUuid}`,
      ...args
    );
    return result;
  };

  const unfreezeApplicationSecret = async (
    {
      applicationUuid,
      projectUuid,
      applicationSecretId,
    }: { applicationUuid: string; projectUuid: string; applicationSecretId: string },
    ...args: AxiosRequestConfig[]
  ) => {
    const result = await client.put<null>(
      `${config.applicationEndpoint}/${applicationUuid}/application-secret/${applicationSecretId}/unfreeze?projectUuid=${projectUuid}`,
      ...args
    );
    return result;
  };

  const updateApplicationSecretDescription = async (
    {
      applicationUuid,
      projectUuid,
      applicationSecretId,
      description,
    }: { applicationUuid: string; projectUuid: string; applicationSecretId: string; description: string },
    ...args: AxiosRequestConfig[]
  ) => {
    const result = await client.put<null>(
      `${config.applicationEndpoint}/${applicationUuid}/application-secret/${applicationSecretId}?projectUuid=${projectUuid}`,
      { description },
      ...args
    );
    return result;
  };

  return {
    getApplications,
    getApplicationSecrets,
    getApplicationsInOrganization,
    createApplication,
    createApplicationSecret,
    createApplicationSecretJson,
    createApplicationSecretPlist,
    updateApplication,
    deleteApplication,
    deleteApplicationSecret,
    getProvisioningCode,
    getProvisioningCodeStatus,
    acceptProvisioningCode,
    rejectProvisioningCode,
    freezeApplicationSecret,
    unfreezeApplicationSecret,
    updateApplicationSecretDescription,
    config,
  };
};

export default ApplicationApi();
