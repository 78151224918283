import Application from '../../views/pages/application/Applications';
import ProjectSettings from '../../views/pages/project-settings/ProjectSettings';
import ProjectRoutesWrapper from '../components/ProjectRoutes';
import { PATHS } from '../paths';

const ProjectRoutes = {
  path: PATHS.ROOT,
  element: <ProjectRoutesWrapper />,
  children: [
    {
      path: PATHS.PROJECT.APPLICATION.NAME,
      index: true,
      element: <Application />,
    },
    {
      path: PATHS.PROJECT.PROJECT_SETTINGS.NAME,
      element: <ProjectSettings />,
    },
  ],
};

export default ProjectRoutes;
