import { PATHS } from '@src/router/paths';
import { APPLICATION_PLATFORM, APPLICATION_TYPE } from '../../consts';

export function getDocumentationLink(platform: string): string {
  return PATHS.EXTERNAL.DOCUMENTATION[platform.toUpperCase()] || PATHS.EXTERNAL.DOCUMENTATION.WEB;
}

export function getTestAppLink(platform: string): string {
  return PATHS.EXTERNAL.WEB_SDK_TEST_APPS[platform.toUpperCase()] || PATHS.EXTERNAL.WEB_SDK_TEST_APPS.WEB;
}

export function getApplicationType(platform: string): string {
  return platform.toLowerCase() === APPLICATION_TYPE.WEB.toLowerCase() ? APPLICATION_TYPE.WEB : APPLICATION_TYPE.MOBILE;
}

export function isAndroid(platform: string): boolean {
  return platform.toLowerCase() === APPLICATION_PLATFORM.ANDROID.toLowerCase();
}

export function isMobile(platform: string): boolean {
  return getApplicationType(platform) === APPLICATION_TYPE.MOBILE;
}
