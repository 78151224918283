import { downloadFile } from '../../../utility/file';

export function getFileType(appValues) {
  if (['Android', 'Unity', 'web'].includes(appValues?.platformName)) {
    return 'json';
  } else if (appValues?.platformName === 'iOS') {
    return 'plist';
  }
}

export function downloadGeneratedFile(type, result) {
  switch (type) {
    case 'json':
      const jsonString = `data:text/json;charset=utf-8, ${encodeURIComponent(JSON.stringify(result?.data))}`;
      downloadFile('SyncStageSecret.json', jsonString);
      break;
    case 'plist':
      const plistString = `data:text/plist;charset=utf-8,${encodeURIComponent(result)}`;
      downloadFile('SyncStageSecret.plist', plistString);
      break;
    default:
      throw new Error(`Unknown type: ${type}`);
  }
}
