// eslint-disable-next-line unused-imports/no-unused-imports, @typescript-eslint/no-unused-vars
import { UseQueryResult } from '@tanstack/react-query';
import { useGetPricingPlansDictionary } from '@api/hooks/dictionaries';

/**
 *
 * @returns {UseQueryResult<SelectOptionWithDescription[]>}
 */
export function usePricingPlans() {
  return useGetPricingPlansDictionary({
    select(data) {
      return data.map((pricingPlan) => ({
        label: pricingPlan.name,
        value: pricingPlan.uuid,
        description: pricingPlan.description,
      }));
    },
  });
}
