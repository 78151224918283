import { Input } from 'reactstrap';

function TableRowsShownSelect({ rowsPerPage, onPerPage }) {
  return (
    <div className="d-flex align-items-center">
      <label htmlFor="rows-per-page">Show</label>
      <Input
        className="mx-50"
        type="select"
        id="rows-per-page"
        value={rowsPerPage}
        onChange={onPerPage}
        style={{ width: '5rem' }}
      >
        <option value="10">10</option>
        <option value="25">25</option>
        <option value="50">50</option>
      </Input>
    </div>
  );
}

export default TableRowsShownSelect;
