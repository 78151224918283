import { useMutateUpdateOrganization } from '@api/hooks/organization/organizationQuery';
import { yupResolver } from '@hookform/resolvers/yup';
import SubmitButton from '@src/views/components/buttons/SubmitButton/SubmitButton';
import ToastContent from '@src/views/components/toastContent';
import { Input } from '@utils/components/form/Inputs';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Input as BaseInput, Button, Card, CardBody, CardHeader, Col, Form, FormGroup, Label, Row } from 'reactstrap';
import { useGetOrganization } from '../../hooks/useGetOrganization';
import { schema } from './schema';

export const General = () => {
  const { t } = useTranslation();
  const { data: organization, refetch } = useGetOrganization();
  const { mutateAsync, isLoading } = useMutateUpdateOrganization({ organizationUuid: organization?.uuid });
  const {
    formState: { isDirty },
    reset,
    handleSubmit,
    control,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: organization?.name || '',
    },
  });

  const updateOrganization = (formData) => {
    mutateAsync({
      name: formData.name,
    })
      .then(() => {
        refetch();
        toast((t) => <ToastContent t={t} success header="organizations.successOrganizationUpdate" />);
      })
      .catch(() => {
        toast((t) => <ToastContent t={t} success={false} header="organizations.failedOrganizationUpdate" />);
      });
  };

  return (
    <Row className="m-0 mt-2">
      <Card className="p-0">
        <Form onSubmit={handleSubmit(updateOrganization)}>
          <CardHeader className="border border-bottom">
            <h4 className="m-0">{t('organizations.settings.general.organizationDetails')}</h4>
          </CardHeader>
          <CardBody>
            <Row className="m-0 mt-2">
              <Col sm={6} className="ps-0">
                <Input
                  name="name"
                  control={control}
                  label={t('organizations.settings.general.organizationName')}
                  placeholder={t('organizations.settings.general.organizationNamePlaceholder')}
                />
              </Col>
              <Col sm={6} className="pe-0">
                <FormGroup>
                  <Label className="form-label">{t('organizations.settings.general.organizationId')}</Label>
                  <BaseInput disabled value={organization?.uuid} />
                </FormGroup>
              </Col>
            </Row>
            <Row className="m-0 d-flex justify-content-start mt-3">
              <SubmitButton disabled={!isDirty} isLoading={isLoading} containerClassName="w-auto p-0" color="primary">
                {t('general.saveChanges')}
              </SubmitButton>
              <Button className="w-auto ms-2" color="outline-secondary" onClick={() => reset()}>
                {t('general.discard')}
              </Button>
            </Row>
          </CardBody>
        </Form>
      </Card>
    </Row>
  );
};
