import classNames from 'classnames';
import { useRef, forwardRef } from 'react';
import ReactSelect from 'react-select';
import { CustomOption } from './Option';
import '@src/assets/scss/pages/custom-select.scss';

/**
 * @typedef {object} InvalidProps
 * @property {boolean} [invalid]
 */

/**
 * @typedef {InvalidProps & import('react-select').Props} SelectProps
 * @param {SelectProps} [props]
 */
const Select = forwardRef(({ invalid = false, ...props }, forwardRef) => {
  const documentRef = useRef(document.querySelector('body'));

  return (
    <ReactSelect
      menuPortalTarget={documentRef.current}
      classNamePrefix="select"
      className={classNames(invalid && 'is-invalid', 'custom-select', props.className)}
      aria-invalid={invalid}
      {...props}
      ref={forwardRef}
      components={{
        Option: CustomOption,
      }}
    />
  );
});

export default Select;
