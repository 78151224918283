import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import toast from 'react-hot-toast';
import config from './config';
import { getErrorMessageTranslationKey } from '../utility/errors/apiError';
import ToastContent from '../views/components/toastContent/ToastContent';
import { onInvalidateUser } from '@utils/auth';

if (process.env.NODE_ENV !== 'development') {
  axios.defaults.baseURL = process.env.REACT_APP_API;
}

const setToken = (value) => {
  localStorage.setItem(config.storageTokenKeyName, value);
};

const getToken = () => {
  return localStorage.getItem(config.storageTokenKeyName);
};

const removeToken = () => {
  return localStorage.removeItem(config.storageTokenKeyName);
};

const getRefreshToken = () => {
  return localStorage.getItem(config.storageRefreshTokenKeyName);
};

const setRefreshToken = (value) => {
  localStorage.setItem(config.storageRefreshTokenKeyName, value);
};

const removeRefreshToken = () => {
  return localStorage.removeItem(config.storageRefreshTokenKeyName);
};

// Function that will be called to refresh authorization
const refreshAuthLogic = (failedRequest) => {
  return axios
    .post(
      config.refreshEndpoint,
      { refreshToken: getRefreshToken() },
      {
        skipAuthRefresh: true,
      }
    )
    .then(async (res) => {
      const accessToken = res.data.AuthenticationResult.IdToken;

      setToken(accessToken);

      failedRequest.response.config.headers['Authorization'] = `Bearer ${accessToken}`;
      return Promise.resolve();
    })
    .catch(() => {
      onInvalidateUser();
    });
};

const getAxiosClient = () => {
  const client = axios.create({
    headers: {
      'Content-Type': 'application/json',
    },
  });

  client.interceptors.request.use((request) => {
    request.headers['Authorization'] = `${config.tokenType} ${getToken()}`;
    return request;
  });

  client.interceptors.response.use(
    (response) => response,
    (error) => {
      if (!axios.isAxiosError(error)) {
        throw error;
      }

      const statusCode = error.response.status;

      if (statusCode !== 401) {
        const toastContentKey = getErrorMessageTranslationKey(statusCode);

        if (toastContentKey) {
          toast((t) => (
            <ToastContent t={t} success={false} header={{ key: toastContentKey, options: { code: status } }} />
          ));
        }
      }

      throw error;
    }
  );

  createAuthRefreshInterceptor(client, refreshAuthLogic);

  return client;
};

export default getAxiosClient;

export { setToken, getToken, removeToken, getRefreshToken, setRefreshToken, removeRefreshToken };
