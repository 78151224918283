import * as yup from 'yup';
import { passwordYup } from './password';

export const registerSchema = yup.object().shape({
  accessCode: yup
    .string()
    .trim()
    .required()
    .matches('[a-f0-9]{8}-?[a-f0-9]{4}-?4[a-f0-9]{3}-?[89ab][a-f0-9]{3}-?[a-f0-9]{12}', 'errors.badFormat'),
  firstName: yup.string().trim().required().min(2).max(100),
  lastName: yup.string().trim().required().min(2).max(100),
  organization: yup.string().trim().required(),
  emailAddress: yup.string().email().trim().required().max(256),
  password: passwordYup.required(),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'errors.passwordsNotMatch')
    .required(),
  terms: yup.bool().oneOf([true], 'errors.mustBeChecked'),
});
