import { Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import '@styles/react/pages/modal-create-app.scss';
import SubmitButton from '@src/views/components/buttons/SubmitButton/SubmitButton';
import { useTranslation } from 'react-i18next';
import { useMutateRemoveMember } from '@api/hooks/project/projectQuery';
import { useActiveProject } from '@utils/hooks/useActiveProject';
import toast from 'react-hot-toast';
import ToastContent from '@src/views/components/toastContent';

const RemoveMemberModal = ({ isOpen, toggle, member, refetchOrganizationMembers }) => {
  const { t } = useTranslation();
  const projectUuid = useActiveProject();
  const { mutateAsync: removeMember, isLoading } = useMutateRemoveMember();

  const handleRemoveMember = () => {
    removeMember({ projectUuid, emailAddress: member.email })
      .then(() => {
        toast((t) => <ToastContent t={t} success header="projects.removeMember.successMemberRemoved" />);
        refetchOrganizationMembers();
        toggle();
      })
      .catch(() => {
        toast((t) => <ToastContent t={t} success={false} header="projects.removeMember.failedMemberRemoved" />);
      });
  };

  const memberName = member.firstName && member.lastName ? `${member.firstName} ${member.lastName}` : member.email;

  return (
    <Modal isOpen={isOpen} toggle={() => toggle()} className="modal-dialog-centered modal-lg">
      <ModalHeader className="bg-transparent" toggle={() => toggle()}></ModalHeader>
      <ModalBody className="pb-3 px-sm-3">
        <h1 className="text-center mb-1">{t('projects.removeMember.youAreAboutToRemove', { memberName })}</h1>
        <Row className="m-0 d-flex justify-content-center">
          <SubmitButton
            className="w-auto"
            containerClassName="text-center"
            color="primary"
            isLoading={isLoading}
            onClick={handleRemoveMember}
            data-testid="project-member-remove-confirm"
          >
            {t('projects.removeMember.removeMember')}
          </SubmitButton>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default RemoveMemberModal;
