import { useQueryProjects } from '@api/hooks/project/projectQuery';
import { useActiveOrganization } from '@utils/hooks/useActiveOrganization';

/**
 *
 * @param {import('@tanstack/react-query').UseQueryOptions} [options]
 * @returns
 */
export function useGetProjects(options) {
  const organizationUuid = useActiveOrganization();
  const result = useQueryProjects(
    {
      organizationUuid,
    },
    options
  );

  return result;
}
