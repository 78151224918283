// ** React Imports
import { Navigate } from 'react-router-dom';
import { Suspense } from 'react';

import { useIsAuthorized } from '@utils/hooks/useIsAuthorized';

const PrivateRoute = ({ children, route }) => {
  const isUserLogged = useIsAuthorized();

  if (route) {
    let restrictedRoute = false;

    if (route.meta) {
      restrictedRoute = route.meta.restricted;
    }
    if (!isUserLogged) {
      return <Navigate to="/auth/login" />;
    }
    if (isUserLogged && restrictedRoute) {
      return <Navigate to="/" />;
    }
  }

  return <Suspense fallback={null}>{children}</Suspense>;
};

export default PrivateRoute;
