import { Box, Plus } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { Button, Card, CardBody, Col } from 'reactstrap';

interface Props {
  onNewApp: () => void;
}

function AddApplicationListItem({ onNewApp }: Props) {
  const { t } = useTranslation();
  return (
    <Col lg={{ size: 4, order: 1 }} sm={{ size: 12 }} xs={{ order: 2 }}>
      <Card className="d-flex align-items-stretch">
        <CardBody className="d-flex justify-content-between align-items-center m-1">
          <div className="avatar avatar-stats p-50 m-0 bg-light-primary">
            <div className="avatar-content">
              <Box size={20} />
            </div>
          </div>
          <Button className="btn-icon ms-25" color="primary" onClick={() => onNewApp()} data-testid="create-new-app">
            <Plus size={14} />
            <span className="align-middle ms-25">{t('applications.addNewApplication')}</span>
          </Button>
        </CardBody>
      </Card>
    </Col>
  );
}
export default AddApplicationListItem;
