// ** Imports createContext function
import { createContext } from 'react';

// ** Imports createContextualCan function
import { createContextualCan } from '@casl/react';
import { PureAbility } from '@casl/ability';

type ManageAction = ['manage', 'all'];
export type AbilityActions = ManageAction;
export type Ability = PureAbility<AbilityActions, unknown>;

// ** Create Context
export const AbilityContext = createContext<Partial<Ability>>({});

// ** Init Can Context
export const Can = createContextualCan<Ability>(AbilityContext.Consumer);
