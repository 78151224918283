// ** Reducers Imports
import layout from './layout';
import global from './global';
import organizationsProjects from './organizationsProjects';
import authorization from './authorization';
import { combineReducers } from 'redux';

const combinedReducers = combineReducers({
  layout,
  global,
  organizationsProjects,
  authorization,
});

const rootReducer = (state, action) => {
  if (action.type === 'CLEAR_STORE') {
    state = undefined;
  }
  return combinedReducers(state, action);
};

export default rootReducer;
