import { useQueryMe } from '@api/hooks/auth/authQuery';
import { handleClearOrganization, handleSelectOrganization } from '@store/organizationsProjects';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

export function useOrganizationStore() {
  const dispatch = useDispatch();
  const { data } = useQueryMe();
  const userUuid = data?.uuid;

  const handleSave = useCallback(
    ({ organizationUuid }) => {
      if (!userUuid) {
        return;
      }

      localStorage.setItem(`organization_${userUuid}`, organizationUuid);
      dispatch(handleSelectOrganization({ organizationUuid }));
    },
    [dispatch, userUuid]
  );

  const handleRead = useCallback(() => {
    if (!userUuid) {
      return;
    }

    return localStorage.getItem(`organization_${userUuid}`);
  }, [userUuid]);

  const handleClear = useCallback(() => {
    if (!userUuid) {
      return;
    }

    localStorage.removeItem(`organization_${userUuid}`);
    dispatch(handleClearOrganization());
  }, [dispatch, userUuid]);

  return {
    onSaveOrganization: handleSave,
    onReadOrganization: handleRead,
    onClearOrganization: handleClear,
  };
}
