import { yupResolver } from '@hookform/resolvers/yup';
import SubmitButton from '@src/views/components/buttons/SubmitButton/SubmitButton';
import { useDeleteApplication } from '@src/views/pages/application/hooks/useDeleteApplication';
import { useUpdateApplication } from '@src/views/pages/application/hooks/useUpdateApplication';
import { useGenerateSecret } from '@src/views/pages/application/hooks/useGenerateSecret';
import { appSchema } from '@src/views/pages/application/schema';
import { getFileType } from '@src/views/pages/application/utils';
import { Input } from '@utils/components/form/Inputs';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import { AppValues, ConfigurationModalMode } from '../../types';
import { CONFIGURE_APP_MODES } from '../../consts';
import SecretsTable from '@src/views/pages/application/components/SecretsTable';

type Props = {
  onChangeMode: (mode: ConfigurationModalMode) => void;
  toggle: () => void;
  appValues: AppValues;
};

export default function GeneralMode({ appValues, toggle, onChangeMode }: Props) {
  const { formState, handleSubmit, control } = useForm({
    defaultValues: appValues,
    resolver: yupResolver(appSchema),
  });
  const { t } = useTranslation();

  const { onUpdateApp } = useUpdateApplication(
    { activeProject: appValues },
    {
      onSuccess: toggle,
    }
  );

  const { onDeleteApplication } = useDeleteApplication(
    { activeProject: appValues },
    {
      onSuccess: toggle,
    }
  );

  const { onCreateSecret, isLoading } = useGenerateSecret({ activeProject: appValues });

  const onSubmit = (data) => {
    onUpdateApp({ name: data.name, packageName: data.customFields.packageName });
  };

  return (
    <>
      <section className="mb-4">
        <form onSubmit={handleSubmit(onSubmit)}>
          <h2 className="mb-2">{t('general.general')}</h2>
          <div className="mb-2">
            <Input
              control={control}
              name="name"
              label={t('applications.applicationName')}
              placeholder={t('applications.applicationNamePlaceholder')}
            />
          </div>
          <div className="mb-2">
            <Input
              control={control}
              name="customFields.packageName"
              placeholder={t('applications.packageNamePlaceholder')}
              label={t('applications.packageName', { platform: appValues?.platformName })}
            />
          </div>
          <div className="d-flex justify-content-end my-2">
            <SubmitButton
              isLoading={formState.isSubmitting}
              disabled={!formState.isDirty}
              containerClassName="w-auto p-0"
              color="primary"
            >
              {t('general.saveChanges')}
            </SubmitButton>
            <Button className="w-auto ms-2" color="outline-secondary" onClick={() => toggle()}>
              {t('general.discard')}
            </Button>
          </div>
        </form>
      </section>
      <section className="mb-4">
        <h2>{t('applications.sdkProvisioning')}</h2>
        <p>
          {t('applications.sdkDownloadFile', {
            platform: appValues?.platformName,
            extension: getFileType(appValues),
          })}
        </p>
        <div className="d-flex gap-2 align-items-center my-2">
          <SubmitButton color="primary" onClick={onCreateSecret} isLoading={isLoading}>
            {t('applications.sdkGenerateNewSecret')}
          </SubmitButton>
          <Button color="outline-primary" onClick={() => onChangeMode(CONFIGURE_APP_MODES.PROVISIONING_GENERATE_KEY)}>
            {t('applications.provisionPrebuiltTestApp')}
          </Button>
          <a className="ms-auto" href="https://docs.sync-stage.com/" target="_blank" rel="noreferrer">
            {t('general.help')}
          </a>
        </div>
        <SecretsTable projectUuid={appValues.projectUuid} applicationUuid={appValues.uuid} />
      </section>
      <section>
        <div className="d-flex justify-content-center mt-2">
          <Button color="outline-danger" onClick={onDeleteApplication}>
            <div className="align-middle d-sm-inline-block d-none">{t('applications.removeApplications')}</div>
          </Button>
        </div>
      </section>
    </>
  );
}
