import classNames from 'classnames';

export const CustomOption = ({ innerProps, data, isFocused, isSelected }) => {
  return (
    <div
      {...innerProps}
      className={classNames(
        'select__option',
        isFocused && 'select__option--is-focused',
        isSelected && 'select__option--is-selected'
      )}
      role="option"
      data-value={data.value}
    >
      {data.label}
    </div>
  );
};
