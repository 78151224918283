import { APPLICATIONS_QUERY, useMutateUpdateApplication } from '@api/hooks/application/applicationQuery';
import ToastContent from '@src/views/components/toastContent/ToastContent';
import { useQueryClient } from '@tanstack/react-query';
import { useOverlayContext } from '@utils/context/OverlayContext/useOverlayContext';
import toast from 'react-hot-toast';

export function useUpdateApplication({ activeProject }, { onSuccess }) {
  const [onStartLoading, onStopLoading] = useOverlayContext();

  const { mutate } = useMutateUpdateApplication({
    appUuid: activeProject.uuid,
    projectUuid: activeProject.projectUuid,
  });
  const client = useQueryClient();

  const handleUpdateApp = async (data) => {
    onStartLoading();

    mutate(
      {
        name: data.name,
        packageName: data.packageName,
      },
      {
        onSettled: () => onStopLoading(),
        onError: () => toast((t) => <ToastContent t={t} success={false} header="applications.failedAppUpdate" />),
        onSuccess: () => {
          client.refetchQueries({
            queryKey: APPLICATIONS_QUERY({
              projectUuid: activeProject.projectUuid,
            }),
          });
          onSuccess();
          toast((t) => <ToastContent t={t} success={true} header="applications.successAppUpdate" />);
        },
      }
    );
  };

  return {
    onUpdateApp: handleUpdateApp,
  };
}
