import { useMutateCreateSecret } from '@api/hooks/application/applicationQuery';
import ToastContent from '@src/views/components/toastContent/ToastContent';
import toast from 'react-hot-toast';
import { downloadGeneratedFile, getFileType } from '../utils';

export function useGenerateSecret({ activeProject }, { onSuccess } = {}) {
  const { mutate, isLoading } = useMutateCreateSecret({
    appUuid: activeProject.uuid,
    projectUuid: activeProject.projectUuid,
  });
  const handleGenerateSecret = async () => {
    const fileType = getFileType(activeProject);
    mutate(
      { type: fileType },
      {
        onError: () => toast((t) => <ToastContent t={t} success={false} header="applications.failedSecretCreated" />),
        onSuccess: async (result) => {
          downloadGeneratedFile(fileType, result);
          toast((t) => <ToastContent t={t} success={true} header="applications.successSecretCreated" />);
          onSuccess?.();
        },
      }
    );
  };

  return {
    onCreateSecret: handleGenerateSecret,
    isLoading,
  };
}
