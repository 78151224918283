import { APPLICATIONS_QUERY, useMutateDeleteApplication } from '@api/hooks/application/applicationQuery';
import ToastContent from '@src/views/components/toastContent/ToastContent';
import { useQueryClient } from '@tanstack/react-query';
import { useOverlayContext } from '@utils/context/OverlayContext/useOverlayContext';
import toast from 'react-hot-toast';

export function useDeleteApplication({ activeProject }, { onSuccess }) {
  const { mutate } = useMutateDeleteApplication({
    appUuid: activeProject.uuid,
    projectUuid: activeProject.projectUuid,
  });
  const [onStartLoading, onStopLoading] = useOverlayContext();
  const client = useQueryClient();

  const handleDeleteApplication = async () => {
    onStartLoading();
    mutate(void 0, {
      onSettled: () => onStopLoading(),
      onError: () => toast((t) => <ToastContent t={t} success={false} header="applications.failedAppDeleted" />),
      onSuccess: () => {
        client.refetchQueries({
          queryKey: APPLICATIONS_QUERY({
            projectUuid: activeProject.projectUuid,
          }),
        });

        toast((t) => <ToastContent t={t} success={true} header="applications.successAppDeleted" />);
        onSuccess();
      },
    });
  };

  return {
    onDeleteApplication: handleDeleteApplication,
  };
}
