// ** Router imports
import { lazy } from 'react';

// ** Router imports
import { useRoutes, Navigate } from 'react-router-dom';

// ** Layouts
import BlankLayout from '@layouts/BlankLayout';

// ** Hooks Imports
import { useLayout } from '@hooks/useLayout';

// ** Utils
import { getUserData } from '../utility/Utils';

// ** GetRoutes
import { DefaultRoute, getRoutes } from './routes';
import UnauthorizedLayout from '../layouts/UnauthorizedLayout';
import Register from '../views/pages/authentication/Register';
import Login from '../views/pages/authentication/login';
import ResetPassword from '../views/pages/authentication/reset-password/ResetPassword';
import ForgotPassword from '../views/pages/authentication/forgot-password/ForgotPassword';
import TokenLogin from '../views/pages/authentication/TokenLogin';
import EnvironmentSetUp from '../views/pages/environmentSetUp';
import ProtectedRoute from './components/ProtectedRoute';
import { PATHS } from './paths';
import RequestDemo from '@src/views/pages/requestDemo';

// ** Components
const Error = lazy(() => import('../views/pages/misc/Error'));
const NotAuthorized = lazy(() => import('../views/pages/misc/NotAuthorized'));

const Router = () => {
  const user = getUserData();

  // ** Hooks
  const { layout } = useLayout();
  const getHomeRoute = () => {
    if (user) {
      return DefaultRoute;
    } else {
      return PATHS.AUTH.LOGIN.FULL_PATH;
    }
  };
  const allRoutes = getRoutes(layout);

  const routes = useRoutes([
    {
      path: PATHS.ROOT,
      index: true,
      element: <Navigate replace to={getHomeRoute()} />,
    },
    {
      path: PATHS.REQUEST_DEMO.FULL_PATH,
      element: <UnauthorizedLayout redirectUrl={process.env.REACT_APP_SYNCSTAGE_REDIRECT_URL} />,
      children: [{ path: '', element: <RequestDemo /> }],
    },
    {
      path: PATHS.ENVIRONMENT.FULL_PATH,
      element: (
        <ProtectedRoute>
          <UnauthorizedLayout />
        </ProtectedRoute>
      ),
      children: [{ path: '', element: <EnvironmentSetUp /> }],
    },
    {
      path: PATHS.AUTH.ROOT,
      element: <UnauthorizedLayout redirectUrl={PATHS.AUTH.LOGIN.FULL_PATH} />,
      children: [
        { index: true, element: <Navigate to={PATHS.AUTH.LOGIN.NAME} /> },
        { path: PATHS.AUTH.LOGIN.NAME, element: <Login /> },
        { path: PATHS.AUTH.REGISTER.NAME, element: <Register /> },
        { path: PATHS.AUTH.FORGOT_PASSWORD.NAME, element: <ForgotPassword /> },
        { path: PATHS.AUTH.TOKEN_LOGIN.NAME, element: <TokenLogin /> },
        { path: PATHS.AUTH.RESET_PASSWORD.NAME, element: <ResetPassword /> },
      ],
    },
    {
      path: PATHS.AUTH.NOT_AUTH.NAME,
      element: <BlankLayout />,
      children: [{ path: PATHS.AUTH.NOT_AUTH.NAME, element: <NotAuthorized /> }],
    },
    {
      path: '*',
      element: <UnauthorizedLayout />,
      children: [{ path: '*', element: <Error /> }],
    },
    ...allRoutes,
  ]);

  return routes;
};

export default Router;
