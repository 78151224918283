import { useContext } from 'react';
import { WizardFormStepperContext } from './WizardFormStepperContext';

/**
 * @typedef {Object} CreateOrganizationContext
 * @property {(step: number) => void} onGoTo
 * @property {() => void} onPrevStep
 * @property {() => void} onNextStep
 * @property {(onSubmit: Function) => void} onSubmit
 * @returns {CreateOrganizationContext}
 */
export const useWizardFormStepperContext = () => {
  return useContext(WizardFormStepperContext);
};
