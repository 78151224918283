// eslint-disable-next-line unused-imports/no-unused-imports, @typescript-eslint/no-unused-vars
import { UseQueryResult } from '@tanstack/react-query';
import { useGetRegionsDictionary } from '@api/hooks/dictionaries';

/**
 *
 * @returns {UseQueryResult<SelectOption[]>}
 */
export function useRegions() {
  return useGetRegionsDictionary({
    select(data) {
      return data.map((region) => ({
        label: region.name,
        value: region.uuid,
      }));
    },
  });
}
