import { Suspense } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import Router from './router/Router';
import { OverlayContextProvider } from './utility/context/OverlayContext/OverlayContext';
import { ContentOverlayContextProvider } from './utility/context/ContentOverlayContext/ContentOverlayContext';

const App = () => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}>
      <OverlayContextProvider>
        <ContentOverlayContextProvider>
          <Suspense fallback={null}>
            <Router />
          </Suspense>
        </ContentOverlayContextProvider>
      </OverlayContextProvider>
    </GoogleReCaptchaProvider>
  );
};

export default App;
