// // ** Core JWT Import
import config from './config';

import getAxiosClient from './ApiClient';

const DictionariesApi = () => {
  const client = getAxiosClient();

  const getRegions = async (...args) => {
    return await client.get(`${config.dictionariesEndpoint}/regions`, ...args);
  };

  const getPricingPlans = async (...args) => {
    return await client.get(`${config.dictionariesEndpoint}/pricing-plans`, ...args);
  };

  const getProjectRoles = async (...args) => {
    return await client.get(`${config.dictionariesEndpoint}/project-roles`, ...args);
  };

  const getOrganizationRoles = async (...args) => {
    return await client.get(`${config.dictionariesEndpoint}/organization-roles`, ...args);
  };

  const getApplicationPlatforms = async (...args) => {
    return await client.get(`${config.getApplicationPlatforms}`, ...args);
  };

  const getCountryCodes = async (...args) => {
    return await client.get(`${config.dictionariesEndpoint}/country-codes`, ...args);
  };

  return {
    getRegions,
    getPricingPlans,
    getProjectRoles,
    getOrganizationRoles,
    getCountryCodes,
    getApplicationPlatforms,
  };
};

// Instantiate the interceptor
export default DictionariesApi();
