import { AuthData } from '@src/models/user/auth';
import { handleAuthorize } from '@store/authorization';
import { setUserData } from '@utils/Utils';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

export function useLogin() {
  const dispatch = useDispatch();
  const handleLoginButton = useCallback(
    (data: AuthData) => {
      setUserData(data);
      dispatch(handleAuthorize());
    },
    [dispatch]
  );

  return { onLogin: handleLoginButton };
}
