import { useMutateFreezeSecret, useMutateUnfreezeSecret } from '@api/hooks/application/applicationQuery';
import ToastContent from '@src/views/components/toastContent/ToastContent';
import { useOverlayContext } from '@utils/context/OverlayContext/useOverlayContext';
import toast from 'react-hot-toast';

export function useFreeApplicationSecret({ appUuid, projectUuid }) {
  const { mutate: freezeApp } = useMutateFreezeSecret({
    appUuid,
    projectUuid,
  });

  const { mutate: unfreezeApp } = useMutateUnfreezeSecret({
    appUuid,
    projectUuid,
  });
  const [showLoad, hideLoad] = useOverlayContext();

  const handleFreezeSecret = async ({ isFreezed, secretId }: { isFreezed: boolean; secretId: string }) => {
    const mutate = isFreezed ? unfreezeApp : freezeApp;

    showLoad();
    mutate(
      { secretId },
      {
        onError: () =>
          toast((t) => (
            <ToastContent
              t={t}
              success={false}
              header={isFreezed ? 'applications.secrets.failedToFreeze' : 'applications.secrets.failedToUnfreeze'}
            />
          )),
        onSuccess: () => {
          toast((t) => (
            <ToastContent
              t={t}
              success={true}
              header={isFreezed ? 'applications.secrets.unfrozen' : 'applications.secrets.frozen'}
            />
          ));
        },
        onSettled() {
          hideLoad();
        },
      }
    );
  };

  return {
    onFreezeSecret: handleFreezeSecret,
  };
}
