import ConfirmLogo from '@src/assets/images/icons/confirm.svg';

/**
 *
 * @param {Object} params
 * @param {string} params.title
 * @param {string} params.subtitle
 * @returns JSX.Element
 */
function SubmitConfirmation({ title, subtitle }) {
  return (
    <div className="text-center">
      <h4 className="pt-1">{title}</h4>
      <p>{subtitle}</p>
      <div className="d-flex justify-content-center mt-1">
        <img src={ConfirmLogo} alt={`confirm-logo`} height="130px" />
      </div>
    </div>
  );
}

export default SubmitConfirmation;
