import getAxiosClient from './ApiClient';
import config from './config';

const CostsApi = () => {
  const client = getAxiosClient();

  /**
   * @param {CostExplorerUsageParams} params
   * @param  {...any} args
   * @returns {Promise<import('axios').AxiosResponse<CostExplorerUsageResponse>>}
   */
  const getUsageForOrganization = async (params, ...args) => {
    const result = await client.get(`${config.getUsageForOrganization}`, {
      params,
      ...args,
    });
    return result;
  };

  /**
   *
   * @param {Object} params
   * @param {string} params.from
   * @param {string} params.to
   * @param {string} params.organizationUuid
   * @param  {...any} args
   * @returns
   */
  const getUsageForProjectCSV = async (params, ...args) => {
    const result = await client.get(`${config.getUsageForProjectCSV}`, { params, ...args });
    return result;
  };

  return {
    getUsageForOrganization,
    getUsageForProjectCSV,
    config,
  };
};

export default CostsApi();
