import * as yup from 'yup';

export const multipleInvitationsSchema = yup.object().shape({
  users: yup
    .array()
    .of(
      yup.object().shape({
        email: yup.string().email().required().max(256).trim(),
        role: yup.object().required(),
      })
    )
    .required()
    .min(1),
});

export const addMemberSchema = yup.object().shape({
  email: yup.string().email().required().max(256).trim(),
  role: yup.object().required(),
});
