import { useState, createContext, useEffect } from 'react';
import UILoader from '@components/ui-loader';
import './overlay.scss';

const OverlayContext = createContext();
// Overlay for entire screen
const OverlayContextProvider = ({ children }) => {
  const [showLoadingOverlay, setShowLoadingOverlay] = useState(false);

  useEffect(() => () => setShowLoadingOverlay(false), [setShowLoadingOverlay]);

  return (
    <OverlayContext.Provider value={{ showLoadingOverlay, setShowLoadingOverlay }}>
      {showLoadingOverlay && <UILoader blocking className="page-overlay" />}
      {children}
    </OverlayContext.Provider>
  );
};

export { OverlayContext, OverlayContextProvider };
