import { useTranslation } from 'react-i18next';
import { Button, Card, CardBody, Row } from 'reactstrap';
import ApiTokensTable from './table/ApiTokensTable';
import { useGetOrganizationAPITokens } from '../../hooks/useGetOrganizationAPITokens';
import GenerateTokenModal from './modals/GenerateToken';
import { useState } from 'react';
import DeactivateTokenModal from './modals/DeactivateToken';

const MODALS = {
  DEACTIVATE: 'DEACTIVATE',
  GENERATE: 'GENERATE',
} as const;

type ModalType = keyof typeof MODALS;

type Modal = {
  type: ModalType | null;
  context?: {
    token: string;
  };
};

export default function ApiTokens() {
  const { t } = useTranslation();
  const [currentModal, setCurrentModal] = useState<Modal>({ type: null });
  const { data, isLoading, isFetching } = useGetOrganizationAPITokens();

  const handleOpenModal = (modal: Modal) => {
    setCurrentModal(modal);
  };

  const handleCloseModal = () => {
    setCurrentModal({ type: null });
  };

  return (
    <Row className="m-0 mt-2">
      <Card className="p-0 py-2">
        <CardBody className="p-0">
          <div className="m-0 px-2 d-flex justify-content-end">
            <Button
              color="primary"
              data-testid="generate-token-button"
              onClick={() => handleOpenModal({ type: MODALS.GENERATE })}
            >
              <span className="align-middle">{t('organizations.settings.apiTokens.generateToken')}</span>
            </Button>
          </div>
          <div className="react-dataTable mt-2">
            <ApiTokensTable
              data={data || []}
              isLoading={isLoading || isFetching}
              onDeactivateToken={(token) => handleOpenModal({ type: MODALS.DEACTIVATE, context: { token } })}
            />
          </div>
        </CardBody>
      </Card>
      {currentModal.type === MODALS.GENERATE && <GenerateTokenModal isOpen toggle={handleCloseModal} />}
      {currentModal.type === MODALS.DEACTIVATE && (
        <DeactivateTokenModal isOpen toggle={handleCloseModal} token={currentModal.context?.token || ''} />
      )}
    </Row>
  );
}
