import '@styles/react/pages/modal-create-app.scss';
import { useTranslation } from 'react-i18next';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { AppValues, ConfigurationModalMode } from '../configureApp/types';
import BuildSyncStageSteps from './BuildSyncStage';
import MobileAppSDK from './MobileAppSDK';
import { isAndroid, isMobile } from './helpers';
import WebAppSDK from './WebAppSDK';
import { CONFIGURE_APP_MODES } from '../configureApp/consts';

type Props = {
  toggle: () => void;
  appValues: AppValues;
  onConfigureAppOpen: (appValues: AppValues, mode?: ConfigurationModalMode) => void;
};

const TestAppModal = ({ toggle, appValues, onConfigureAppOpen }: Props) => {
  const { t } = useTranslation();

  const appStoreTranslation = isAndroid(appValues.platformName)
    ? t('applications.testApp.googlePlay')
    : t('applications.testApp.appleStore');

  const isMobilePlatform = isMobile(appValues.platformName);

  return (
    <Modal isOpen toggle={() => toggle()} className="modal-dialog-centered modal-lg" data-testid="test-app-modal">
      <ModalHeader className="bg-transparent" toggle={() => toggle()} />
      <ModalBody className="pb-3 px-sm-3">
        <h1 className="text-center mb-2">{t('applications.testApp.title')}</h1>
        <p className="mb-3">
          {t('applications.testApp.description', {
            appStore: appStoreTranslation,
          })}
        </p>
        <div className="mb-3">
          {isMobilePlatform ? (
            <MobileAppSDK
              onOpenConfigureModal={() => onConfigureAppOpen(appValues, CONFIGURE_APP_MODES.PROVISIONING_GENERATE_KEY)}
              platform={appValues.platformName}
            />
          ) : (
            <WebAppSDK
              onOpenConfigureModal={() => onConfigureAppOpen(appValues, CONFIGURE_APP_MODES.PROVISIONING_GENERATE_KEY)}
            />
          )}
        </div>
        <BuildSyncStageSteps
          onOpenConfigureModal={() => onConfigureAppOpen(appValues)}
          platform={appValues.platformName}
        />
      </ModalBody>
    </Modal>
  );
};

export default TestAppModal;
