import { useQueryMe } from '@api/hooks/auth/authQuery';
import CustomPagination from '@utils/components/CustomPagination/CustomPagination';
import TableLoader from '@utils/components/TableLoader/TableLoader';
import TableRowsShownSelect from '@utils/components/TableRowsShownSelect/TableRowsShownSelect';
import UserDefaultAvatar from '@utils/components/UserDefaultAvatar/UserDefaultAvatar';
import { useActiveOrganization } from '@utils/hooks/useActiveOrganization';
import { usePaginate } from '@utils/hooks/usePaginate';
import { useState } from 'react';
import DataTable from 'react-data-table-component';
import { ChevronDown, MoreVertical, Trash } from 'react-feather';
import {
  Button,
  Card,
  CardBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';
import { useGetOrganizationMembers } from '../../hooks/useGetOrganizationMembers';
import AddUserModal from './AddUserModal';
import { useTranslation } from 'react-i18next';
import { useRemoveMember } from '../../hooks/useRemoveMember';

const UsersAndPermissions = () => {
  const [isAddMemberOpen, setIsAddMemberOpen] = useState(false);
  const { t } = useTranslation();

  const { pagination, onPerPage, onPageChange } = usePaginate({ perPage: 10 });
  const organizationUuid = useActiveOrganization();

  const {
    isFetching: isLoading,
    data: organizationMembers,
    refetch: refetchMembers,
  } = useGetOrganizationMembers({
    organizationUuid,
    skip: pagination.skip,
    rowsPerPage: pagination.rowsPerPage,
  });

  const { onRemoveMember } = useRemoveMember({
    onSuccess() {
      refetchMembers();
    },
  });

  const { data: userStore } = useQueryMe();

  const addMemberClicked = () => {
    setIsAddMemberOpen((prevState) => !prevState);
  };

  return (
    <Row className="m-0 mt-2">
      <Card className="p-0 py-2">
        <CardBody className="p-0">
          <div className="m-0 d-flex px-2 justify-content-between">
            <TableRowsShownSelect onPerPage={onPerPage} rowsPerPage={pagination.rowsPerPage} />
            <div className="d-flex align-items-center table-header-actions">
              <Button color="primary" onClick={() => addMemberClicked()} data-testid="add-member-button">
                <span className="align-middle">{t('organizations.settings.usersAndPermissions.addNewUser')}</span>
              </Button>
            </div>
          </div>
          <div className="react-dataTable mt-2">
            <DataTable
              sortServer
              pagination
              responsive
              paginationServer
              columns={[
                {
                  name: 'NAME',
                  sortable: true,
                  width: '40%',
                  sortField: 'name',
                  selector: (row) => row.uuid,
                  cell: (row) => (
                    <div className="d-flex justify-content-start">
                      <UserDefaultAvatar name={row.firstName || row.email} style={{ height: '40px', width: '40px' }} />
                      <div className="ms-2">
                        <p className="m-0">
                          {row.uuid === userStore.uuid ? (
                            <span className="fw-bolder">
                              {row.firstName} {row.lastName} (You)
                            </span>
                          ) : (
                            <span className="fw-bold">
                              {row.firstName} {row.lastName}
                            </span>
                          )}
                        </p>
                        <p className="user-status m-0" role="email">
                          {row.email}
                        </p>
                      </div>
                    </div>
                  ),
                },
                {
                  name: 'ROLE',
                  width: '20%',
                  sortable: true,
                  sortField: 'roleName',
                  selector: (row) => row.roleName,
                  cell: (row) => <div className="d-flex justify-content-left align-items-center">{row.roleName}</div>,
                },
                {
                  name: 'STATUS',
                  width: '20%',
                  sortable: true,
                  sortField: 'status',
                  selector: (row) => row.userStatus,
                  cell: (row) => <div className="d-flex justify-content-left align-items-center">{row.userStatus}</div>,
                },
                {
                  name: 'ACTIONS',
                  width: '20%',
                  selector: (row) => row.uuid,
                  cell: (row) => {
                    if (row.uuid === userStore?.uuid) return <></>;
                    return (
                      <div className="d-flex justify-content-end align-items-center position-absolute">
                        <UncontrolledDropdown className="dropdown-user" data-testid="user-actions-dropdown">
                          <DropdownToggle className="p-0" color="none">
                            <MoreVertical />
                          </DropdownToggle>
                          <DropdownMenu end>
                            {/* <DropdownItem onClick={() => console.log('todo', row)} className="w-100">
                              <Edit2 />
                              <span className="align-middle ms-1">Change Role</span>
                            </DropdownItem> */}
                            <DropdownItem onClick={() => onRemoveMember(row.email)} className="w-100">
                              <Trash />
                              <span className="align-middle ms-1">
                                {t('organizations.settings.usersAndPermissions.removeUser')}
                              </span>
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                    );
                  },
                },
              ]}
              sortIcon={<ChevronDown />}
              className="react-dataTable overflow-visible"
              progressPending={isLoading}
              progressComponent={<TableLoader />}
              paginationComponent={() => {
                <CustomPagination
                  currentPage={pagination.currentPage}
                  pageCount={Number(Math.ceil(organizationMembers.length / pagination.rowsPerPage))}
                  onPageChange={onPageChange}
                />;
              }}
              data={organizationMembers}
            />
          </div>
        </CardBody>
      </Card>

      {isAddMemberOpen && <AddUserModal isOpen toggle={addMemberClicked} onRefetch={refetchMembers} />}
    </Row>
  );
};

export default UsersAndPermissions;
