import DataTable from 'react-data-table-component';
import '@styles/react/libs/tables/react-dataTable-component.scss';
import { ChevronDown } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { Application } from './components/cells';
import CustomPagination from '@utils/components/CustomPagination';
import { Filters } from './components';

const CostTable = ({ rowsPerPage, onPageChange, currentPage, usage, onExportAsCSV }) => {
  const { t } = useTranslation();
  const pageCount = Number(Math.ceil(usage?.total / rowsPerPage));

  return (
    <>
      <Filters onExportAsCSV={onExportAsCSV} />
      <div className="react-dataTable mt-2">
        <DataTable
          pagination
          responsive
          paginationServer
          columns={[
            {
              name: t('costExplorer.project'),
              sortable: true,
              minWidth: '152px',
              sortField: 'userId',
              selector: (row) => row.project,
              cell: (row) => <div className="d-flex justify-content-left align-items-center">{row.project.name}</div>,
            },
            {
              name: t('costExplorer.application'),
              sortable: true,
              minWidth: '152px',
              sortField: 'row.application',
              selector: (row) => row.application.name,
              cell: (row) => <Application application={row.application} />,
            },
            {
              name: t('costExplorer.userId'),
              sortable: false,
              minWidth: '243px',
              sortField: 'userId',
              selector: (row) => row.userId,
              cell: (row) => <div className="d-flex justify-content-left align-items-center">{row.userId}</div>,
            },
            {
              name: t('costExplorer.minutesUsed'),
              minWidth: '80px',
              sortable: true,
              sortField: 'minutesUsed',
              selector: (row) => row.minutesUsed,
              cell: (row) => <div className="d-flex justify-content-left align-items-center">{row.minutesUsed}</div>,
            },
            {
              name: t('costExplorer.sessions'),
              minWidth: '80px',
              sortable: true,
              sortField: 'sessions',
              selector: (row) => row.sessionsCnt,
              cell: (row) => <div className="d-flex justify-content-left align-items-center">{row.sessionsCnt}</div>,
            },
          ]}
          sortIcon={<ChevronDown />}
          className="react-dataTable"
          paginationComponent={() => (
            <CustomPagination currentPage={currentPage} onPageChange={onPageChange} pageCount={pageCount} />
          )}
          data={usage?.data}
        />
      </div>
    </>
  );
};
export default CostTable;
