/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line unused-imports/no-unused-imports
import { useMutation, UseMutationOptions, useQuery, useQueryClient } from '@tanstack/react-query';
import { UseQueryOptions } from '../../types';
import useApplicationApi from '../../useApplicationApi';
import {
  AcceptProvisioningCodeResponse,
  ApplicationSecret,
  GetProvisioningCodeResponse,
  GetProvisioningCodeStatusResponse,
  ProvisioningCodePathParams,
  RejectProvisioningCodeResponse,
} from '@api/useApplicationApi.types';
import { ApplicationsInOrganization } from './types';

export const APPLICATIONS_QUERY = ({ projectUuid }) => ['applications', projectUuid];
export function useQueryApplications({ projectUuid }, options) {
  return useQuery({
    queryKey: APPLICATIONS_QUERY({ projectUuid }),
    queryFn: async () => {
      const result = await useApplicationApi.getApplications(projectUuid);
      return result?.data.data;
    },
    staleTime: Infinity,
    ...options,
  });
}

export const APPLICATION_SECRETS_QUERY_KEY = ({ appUuid = '', projectUuid = '' } = {}) =>
  ['applicationSecrets', appUuid, projectUuid].filter(Boolean);

interface ApplicationSecretsQueryParams {
  appUuid: string;
  projectUuid: string;
}
export function useQueryApplicationSecrets(
  { appUuid, projectUuid }: ApplicationSecretsQueryParams,
  options?: UseQueryOptions<ApplicationSecret[]>
) {
  return useQuery({
    queryKey: APPLICATION_SECRETS_QUERY_KEY({ appUuid, projectUuid }),
    queryFn: async () => {
      const result = await useApplicationApi.getApplicationSecrets(appUuid, projectUuid);
      return result.data.data;
    },
    ...options,
  });
}

function useInvalidateQueryApplicationsSecrets() {
  const queryClient = useQueryClient();

  return ({ appUuid = '', projectUuid = '' } = {}) =>
    queryClient.invalidateQueries(APPLICATION_SECRETS_QUERY_KEY({ appUuid, projectUuid }));
}

interface ApplicationsInOrganizationQueryParams {
  organizationUuid: string;
}
export function useQueryApplicationsInOrganization(
  { organizationUuid }: ApplicationsInOrganizationQueryParams,
  options?: UseQueryOptions<ApplicationsInOrganization[]>
) {
  return useQuery({
    queryKey: ['applicationsInOrganization', organizationUuid],
    queryFn: async () => {
      const result = await useApplicationApi.getApplicationsInOrganization(organizationUuid);

      return result.data.data;
    },
    ...options,
  });
}

export function useMutateUpdateApplication({ appUuid, projectUuid }, options) {
  const invalidateSecretsQuery = useInvalidateQueryApplicationsSecrets();
  return useMutation(
    async ({ name, packageName }: any) => {
      const result = await useApplicationApi.updateApplication(appUuid, projectUuid, {
        name,
        packageName,
      });
      return result.data;
    },
    {
      ...options,
      onSuccess(...args) {
        options?.onSuccess?.(...args);

        invalidateSecretsQuery({ appUuid, projectUuid });
      },
    }
  );
}

export function useMutateDeleteApplication({ appUuid, projectUuid }, options) {
  return useMutation(async () => {
    const result = await useApplicationApi.deleteApplication(appUuid, projectUuid);
    return result.data;
  }, options);
}

interface DeleteSecretParams {
  appUuid: string;
  projectUuid: string;
}
export function useMutateDeleteSecret(
  { appUuid, projectUuid }: DeleteSecretParams,
  options?: UseMutationOptions<unknown, unknown, { secretId: string }>
) {
  const invalidateSecretsQuery = useInvalidateQueryApplicationsSecrets();

  return useMutation(
    async ({ secretId }: any) => {
      const result = await useApplicationApi.deleteApplicationSecret(appUuid, secretId, projectUuid);
      return result.data;
    },
    {
      ...options,
      onSuccess(...args) {
        options?.onSuccess?.(...args);

        invalidateSecretsQuery({ appUuid, projectUuid });
      },
    }
  );
}

export function useMutateFreezeSecret(
  { appUuid, projectUuid },
  options?: UseMutationOptions<unknown, unknown, { secretId: string }>
) {
  const invalidateSecretsQuery = useInvalidateQueryApplicationsSecrets();

  return useMutation(
    ['key'],
    async ({ secretId }) => {
      const result = await useApplicationApi.freezeApplicationSecret({
        applicationUuid: appUuid,
        applicationSecretId: secretId,
        projectUuid,
      });
      return result.data;
    },
    {
      ...options,
      onSuccess(...args) {
        options?.onSuccess?.(...args);

        invalidateSecretsQuery({ appUuid, projectUuid });
      },
    }
  );
}

export function useMutateUnfreezeSecret(
  { appUuid, projectUuid },
  options?: UseMutationOptions<unknown, unknown, { secretId: string }>
) {
  const invalidateSecretsQuery = useInvalidateQueryApplicationsSecrets();

  return useMutation(
    async ({ secretId }) => {
      const result = await useApplicationApi.unfreezeApplicationSecret({
        applicationUuid: appUuid,
        applicationSecretId: secretId,
        projectUuid,
      });
      return result.data;
    },
    {
      ...options,
      onSuccess(...args) {
        options?.onSuccess?.(...args);

        invalidateSecretsQuery({ appUuid, projectUuid });
      },
    }
  );
}

export function useMutateUpdateSecretDescription(
  { appUuid, projectUuid },
  options?: UseMutationOptions<unknown, unknown, { secretId: string; description: string }>
) {
  const invalidateSecretsQuery = useInvalidateQueryApplicationsSecrets();

  return useMutation(
    async ({ secretId, description }) => {
      const result = await useApplicationApi.updateApplicationSecretDescription({
        applicationUuid: appUuid,
        applicationSecretId: secretId,
        projectUuid,
        description,
      });
      return result.data;
    },
    {
      ...options,
      onSuccess(...args) {
        options?.onSuccess?.(...args);

        invalidateSecretsQuery({ appUuid, projectUuid });
      },
    }
  );
}

export function useMutateCreateSecret({ appUuid, projectUuid }, options) {
  const invalidateSecretsQuery = useInvalidateQueryApplicationsSecrets();

  return useMutation(
    async ({ type }: any) => {
      switch (type) {
        case 'json': {
          const result = await useApplicationApi.createApplicationSecretJson(appUuid, projectUuid);
          return result;
        }
        case 'plist': {
          const result = await useApplicationApi.createApplicationSecretPlist(appUuid, projectUuid);
          return result.data;
        }
        default:
          throw new Error(`Unknown type: ${type}`);
      }
    },
    {
      ...options,
      onSuccess(...args) {
        options?.onSuccess?.(...args);

        invalidateSecretsQuery({ appUuid, projectUuid });
      },
    }
  );
}

export function useMutateCreateApplication({ projectUuid }, options = {}) {
  return useMutation(async ({ name, applicationPlatformUuid, packageName }: any) => {
    const result = await useApplicationApi.createApplication(projectUuid, {
      name,
      applicationPlatformUuid,
      packageName,
    });
    return result.data;
  }, options);
}

export const PROVISIONING_CODE = (applicationUuid: string) => ['provisioningCode', applicationUuid];
export function useQueryProvisioningCode(
  { applicationUuid }: { applicationUuid: string },
  options?: UseQueryOptions<GetProvisioningCodeResponse>
) {
  return useQuery({
    refetchOnMount: true,
    ...options,
    queryKey: PROVISIONING_CODE(applicationUuid),
    queryFn: async () => {
      const result = await useApplicationApi.getProvisioningCode(applicationUuid);
      return result.data || undefined;
    },
  });
}

export const PROVISIONING_CODE_STATUS = ({ applicationUuid, provisioningCode }: ProvisioningCodePathParams) => [
  'provisioningCodeStatus',
  applicationUuid,
  provisioningCode,
];
export function useQueryProvisioningCodeStatus(
  params: ProvisioningCodePathParams,
  options?: UseQueryOptions<GetProvisioningCodeStatusResponse>
) {
  return useQuery<GetProvisioningCodeStatusResponse>({
    ...options,
    queryKey: PROVISIONING_CODE_STATUS(params),
    queryFn: async () => {
      const result = await useApplicationApi.getProvisioningCodeStatus(params);
      return result.data;
    },
  });
}

export function useMutateAcceptProvisioningCode(
  options?: UseMutationOptions<AcceptProvisioningCodeResponse, unknown, ProvisioningCodePathParams>
) {
  const invalidateSecretsQuery = useInvalidateQueryApplicationsSecrets();

  return useMutation<unknown, unknown, ProvisioningCodePathParams>(
    async (params) => {
      const result = await useApplicationApi.acceptProvisioningCode(params);

      return result.data;
    },
    {
      ...options,
      onSuccess(data, variables, context) {
        options?.onSuccess?.(data, variables, context);

        invalidateSecretsQuery({ appUuid: variables.applicationUuid });
      },
    }
  );
}

export function useMutateRejectProvisioningCode(
  options?: UseMutationOptions<RejectProvisioningCodeResponse, unknown, ProvisioningCodePathParams>
) {
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, ProvisioningCodePathParams>(
    async (params) => {
      const result = await useApplicationApi.rejectProvisioningCode(params);

      return result.data;
    },
    {
      ...options,
      onSuccess(data, variables, context) {
        const { applicationUuid, provisioningCode } = variables;
        queryClient.removeQueries({ queryKey: PROVISIONING_CODE_STATUS({ applicationUuid, provisioningCode }) });

        options?.onSuccess?.(data, variables, context);
      },
    }
  );
}
