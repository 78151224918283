import { Fragment } from 'react';
import BlankLayout from '@layouts/BlankLayout';
import VerticalLayout from '@src/layouts/VerticalLayout';
import LayoutWrapper from '@src/@core/layouts/components/layout-wrapper';
import PublicRoute from '@components/routes/PublicRoute';
import PrivateRoute from '@components/routes/PrivateRoute';
import { isObjEmpty } from '@utils/Utils';

import OrganizationsRoutes from './OrganizationsRoutes';
import OrganizationRoutes from './OrganizationRoutes';
import UserProtectedRoute from '../components/UserProtectedRoute';
import { PATHS } from '../paths';
import ProtectedRoute from '../components/ProtectedRoute';

const getLayout = {
  blank: <BlankLayout />,
  vertical: <VerticalLayout />,
};

// ** Document title
const TemplateTitle = '%s - Win Every Teamfight';

const ApplicationRoute = PATHS.PROJECT.APPLICATION.FULL_PATH;
const DefaultRoute = ApplicationRoute;

// ** Merge Routes
const MainRoutes = [OrganizationRoutes, ...OrganizationsRoutes];

const getRouteMeta = (route) => {
  if (isObjEmpty(route.element.props)) {
    if (route.meta) {
      return { routeMeta: route.meta };
    } else {
      return {};
    }
  }
};

// ** Return Filtered Array of Routes & Paths
const MergeLayoutRoutes = (layout, defaultLayout) => {
  const LayoutRoutes = [];

  if (MainRoutes) {
    MainRoutes.filter((route) => {
      let isBlank = false;
      // ** Checks if Route layout or Default layout matches current layout
      if (
        (route.meta && route.meta.layout && route.meta.layout === layout) ||
        ((route.meta === undefined || route.meta.layout === undefined) && defaultLayout === layout)
      ) {
        let RouteTag = PrivateRoute;

        // ** Check for public or private route
        if (route.meta) {
          route.meta.layout === 'blank' ? (isBlank = true) : (isBlank = false);
          RouteTag = route.meta.publicRoute ? PublicRoute : PrivateRoute;
        }
        if (route.element) {
          const Wrapper =
            // eslint-disable-next-line multiline-ternary
            isObjEmpty(route.element.props) && isBlank === false
              ? // eslint-disable-next-line multiline-ternary
                LayoutWrapper
              : Fragment;

          route.element = (
            <Wrapper {...(isBlank === false ? getRouteMeta(route) : {})}>
              <RouteTag route={route}>{route.element}</RouteTag>
            </Wrapper>
          );
        }

        // Push route to LayoutRoutes
        LayoutRoutes.push(route);
      }
      return LayoutRoutes;
    });
  }
  return LayoutRoutes;
};

const getRoutes = (layout) => {
  const defaultLayout = layout || 'vertical';
  const layouts = ['vertical', 'horizontal', 'blank'];

  const AllRoutes = [];

  layouts.forEach((layoutItem) => {
    const LayoutRoutes = MergeLayoutRoutes(layoutItem, defaultLayout);

    AllRoutes.push({
      path: PATHS.ROOT,
      element: (
        <ProtectedRoute>
          <UserProtectedRoute>{getLayout[layoutItem] || getLayout[defaultLayout]}</UserProtectedRoute>
        </ProtectedRoute>
      ),
      children: LayoutRoutes,
    });
  });
  return AllRoutes;
};

export { DefaultRoute, ApplicationRoute, TemplateTitle, MainRoutes as Routes, getRoutes };
