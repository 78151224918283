import { QRCodeSVG } from 'qrcode.react';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { formatCode } from './helpers';
import { useCountDown } from '@utils/hooks/useCountdown';
import { useEffect } from 'react';

type Props = {
  provisioningCode: string;
  expirationDate: string;
};

export default function ProvisioningCode({ expirationDate, provisioningCode }: Props) {
  const { t } = useTranslation();
  const { counter, start: startCounter } = useCountDown({ finishDate: expirationDate });

  useEffect(() => {
    startCounter();
  }, [startCounter]);

  return (
    <>
      <Row className="py-3">
        <Col sm={6} className="d-flex align-items-center flex-column gap-1">
          <p>{t('applications.readQr')}</p>
          <QRCodeSVG value={provisioningCode} data-testid="qr-code" />
        </Col>
        <Col sm={6} className="d-flex align-items-center flex-column gap-1 border-start ">
          <p>{t('applications.orInputCode')}</p>
          <span data-testid="provisioning-code" className="d-flex align-items-center flex-grow-1 h1 gap-1">
            {formatCode(provisioningCode)}
          </span>
        </Col>
      </Row>
      <p>{t('applications.codeWillRefresh', { seconds: counter })}</p>
    </>
  );
}
