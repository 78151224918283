import ProfileDetails from './ProfileDetails';
import { useQueryMe } from '@api/hooks/auth/authQuery';

const General = () => {
  const { data: user } = useQueryMe();

  return <ProfileDetails user={user} />;
};

export default General;
