import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import GeneralMode from './modes/general/General';
import { CONFIGURE_APP_MODES } from './consts';
import { AppValues, ConfigurationModalMode } from './types';
import GenerateKey from './modes/generateKey/GenerateKey';

type Props = {
  isOpen: boolean;
  toggle: () => void;
  appValues: AppValues;
  mode?: ConfigurationModalMode;
};

const ConfigureAppModal = ({ isOpen, toggle, appValues, mode: defaultMode = CONFIGURE_APP_MODES.GENERAL }: Props) => {
  const [mode, setMode] = useState<ConfigurationModalMode>(defaultMode);
  const { t } = useTranslation();

  const handleSetMode = (mode: ConfigurationModalMode) => {
    setMode(mode);
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="modal-dialog-centered modal-lg" data-testid="configure-app-modal">
      <ModalHeader className="bg-transparent" toggle={toggle} />
      <ModalBody className="pb-3 px-sm-3">
        <h1 className="text-center mb-1">{t('applications.applicationConfiguration')}</h1>
        {mode === CONFIGURE_APP_MODES.GENERAL && (
          <GeneralMode appValues={appValues} toggle={toggle} onChangeMode={handleSetMode} />
        )}
        {mode === CONFIGURE_APP_MODES.PROVISIONING_GENERATE_KEY && (
          <GenerateKey appValues={appValues} onChangeMode={handleSetMode} />
        )}
      </ModalBody>
    </Modal>
  );
};

export default ConfigureAppModal;
