import { useTranslation } from 'react-i18next';
import FormActions from './FormActions';

/**
 * @param {import('./types').FormActionProps} props
 * @returns {JSX}
 */
function FormActionsNext(props) {
  const { t } = useTranslation();

  return <FormActions {...props} nextLabel={t('Next')} />;
}

export default FormActionsNext;
