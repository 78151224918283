export const mapFormOrganizationToOrganization = (formData) => {
  return {
    organizationName: formData.organizationName,
    companyName: formData.companyName,
    taxId: formData.taxId,
    vatNumber: formData.vatNumber,
    country: formData.country.numberCode,
    billingAddress: formData.billingAddress,
    state: formData.state,
    zipCode: formData.zipCode,
    members: formData.members,
  };
};
