import { getSecondsToDate } from '@utils/Utils';
import { useEffect, useRef, useState } from 'react';

type Options = {
  finishDate: string;
  onFinish?: () => void;
  countDownInterval?: number;
};

const ONE_SECOND_IN_MS = 1_000;

export function useCountDown({ finishDate, onFinish, countDownInterval = ONE_SECOND_IN_MS }: Options) {
  const [counter, setCountDown] = useState(getSecondsToDate(finishDate));
  const [startCountDown, setStartCountDown] = useState(false);
  const interval = useRef<ReturnType<typeof setInterval> | null>(null);

  const start = () => setStartCountDown(true);
  const pause = () => setStartCountDown(false);
  const reset = () => {
    clearInterval(interval.current || undefined);
    setStartCountDown(false);
    setCountDown(getSecondsToDate(finishDate));
  };

  useEffect(() => {
    interval.current = setInterval(() => {
      if (startCountDown && counter > 0) {
        setCountDown(getSecondsToDate(finishDate));
      }
    }, countDownInterval);

    if (counter === 0) {
      clearInterval(interval.current);
      onFinish?.();
    }

    return () => clearInterval(interval.current || undefined);
  }, [startCountDown, counter, countDownInterval, onFinish, finishDate]);

  return { counter, start, pause, reset };
}
