import useAuthenticationApi from '@api/useAuthenticationApi';
import ToastContent from '@src/views/components/toastContent/ToastContent';
import { onInvalidateUser } from '@utils/auth';
import toast from 'react-hot-toast';

export function useLogout() {
  const handleLogout = () => {
    toast((t) => <ToastContent t={t} success={true} header="login.successLogOut" />);
    onInvalidateUser();
    useAuthenticationApi.logout();
  };

  return { onLogout: handleLogout };
}
