import * as yup from 'yup';

export const projectSchema = yup.object().shape({
  name: yup.string().trim().required().min(2).max(256),
  region: yup.array().of(yup.object()).required().min(1),
});

export const deleteProjectSchema = yup.object().shape({
  accept: yup.bool().oneOf([true]),
});
