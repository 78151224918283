import { useQueryMe } from '@api/hooks/auth/authQuery';
import { useQueryOrganizationUsers } from '@api/hooks/organization/organizationQuery';

/**
 *
 * @param {Object} params
 * @param {string} params.organizationUuid
 * @returns {UseQueryResult}
 */
export function useOrganizationMembers({ organizationUuid }) {
  const { data: userStore } = useQueryMe();
  return useQueryOrganizationUsers(
    { organizationUuid },
    {
      select(data) {
        return data
          .filter((member) => member.uuid !== userStore.uuid)
          .map((member) => ({
            label: `${member.firstName} ${member.lastName} (${member.email})`,
            value: member.uuid,
            email: member.email,
            name: `${member.firstName} ${member.lastName}`,
          }));
      },
    }
  );
}
