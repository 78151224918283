import UILoader from '@components/ui-loader';
import { ReactNode } from 'react';
import { Button } from 'reactstrap';
import { UIButtonProps } from '../types';

interface Props extends UIButtonProps {
  isLoading: boolean;
  containerClassName?: string;
  children: ReactNode;
}

function SubmitButton({ isLoading, containerClassName, children, ...rest }: Props) {
  return (
    // invalid type definition for UILoader coming from @core
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <UILoader blocking={isLoading} className={containerClassName}>
      <Button type="submit" {...rest}>
        {children}
      </Button>
    </UILoader>
  );
}

export default SubmitButton;
