// ** React Imports
import { useState, useRef } from 'react';

// ** Reactstrap Imports
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

// ** Custom Components
import Wizard from '@components/wizard';

// ** Icons Imports
import { Check, FileText, Users, MapPin } from 'react-feather';

// ** Steps
import UsersStep from './steps/usersStep';
import DetailsStep from './steps/detailsStep';
import SubmitStep from './steps/submitStep';
import AvailabilityStep from './steps/availabilityStep/AvailabilityStep';

// ** Styles
import '@styles/react/pages/modal-create-app.scss';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { createProjectSchema } from './schema';
import { WizardFormStepperContextProvider } from '@utils/context/WizardFormStepperContext';

const stepsInfo = [
  { step: 1, fields: ['projectName', 'projectDescription'] },
  { step: 2, fields: ['region'] },
  { step: 3, fields: ['members'] },
  { step: 4, fields: [] },
];

const CreateProject = ({ toggle, refetchProjects }) => {
  const ref = useRef(null);
  const { t } = useTranslation();
  const [stepper, setStepper] = useState(null);

  const methods = useForm({
    defaultValues: {
      projectName: '',
      projectDescription: '',
      region: null,
      members: [],
    },
    resolver: yupResolver(createProjectSchema),
    mode: 'onChange',
  });

  const steps = [
    {
      id: 'Details',
      title: t('Details'),
      subtitle: t('projects.form.projectDetails'),
      icon: <FileText className="font-medium-3" />,
      content: <DetailsStep stepInfo={stepsInfo[0]} />,
    },
    {
      id: 'Availability',
      title: t('projects.form.availability'),
      subtitle: t('projects.form.regionHelper'),
      icon: <MapPin className="font-medium-3" />,
      content: <AvailabilityStep stepInfo={stepsInfo[1]} />,
    },
    {
      id: 'users',
      title: t('general.users'),
      subtitle: t('projects.form.addPeople'),
      icon: <Users className="font-medium-3" />,
      content: <UsersStep stepInfo={stepsInfo[2]} />,
    },
    {
      id: 'submit',
      title: t('general.submitButton'),
      subtitle: t('projects.form.submitProject'),
      icon: <Check className="font-medium-3" />,
      content: <SubmitStep toggle={toggle} refetchProjects={refetchProjects} />,
    },
  ];

  return (
    <Modal isOpen toggle={() => toggle()} className="modal-dialog-centered modal-lg">
      <ModalHeader className="bg-transparent" toggle={() => toggle()}></ModalHeader>
      <ModalBody className="pb-3 px-sm-3">
        <FormProvider {...methods}>
          <WizardFormStepperContextProvider stepper={stepper} steps={stepsInfo}>
            <h1 className="text-center mb-1">{t('projects.form.createProject')}</h1>
            <p className="text-center mb-2">{t('projects.form.createProjectSubtitle')}</p>
            <Wizard
              ref={ref}
              steps={steps}
              type="vertical"
              headerClassName="border-0"
              options={{ linear: false }}
              instance={(el) => setStepper(el)}
              contentClassName="shadow-none"
              className="bg-transparent create-app-wizard shadow-none border-0"
            />
          </WizardFormStepperContextProvider>
        </FormProvider>
      </ModalBody>
    </Modal>
  );
};

export default CreateProject;
