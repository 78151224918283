// eslint-disable-next-line unused-imports/no-unused-imports
import { createContext, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

export const WizardFormStepperContext = createContext();

/**
 * @typedef {Object} StepsInfo
 * @property {number} step
 * @property {Array<string>} fields
 */

/**
 * NOTE
 * hook form contexts is required to work
 * @param {Object} value
 * @param {Stepper} value.stepper
 * @param {StepsInfo} value.steps
 * @param {ChildNode} value.children
 * @returns
 */
export const WizardFormStepperContextProvider = ({ stepper, steps, children }) => {
  const { trigger, getFieldState } = useFormContext();

  const handleGoToStep = useCallback(
    /**
     * @param {number} nextStep
     */
    (nextStep) => {
      if (!stepper) {
        return;
      }
      const lastStep = steps[steps.length - 1].step;
      const firstStep = 1;

      if (nextStep >= firstStep && nextStep <= lastStep) {
        stepper.to(nextStep);
      }
    },
    [stepper, steps]
  );

  const handlePrevStep = useCallback(() => {
    if (!stepper) {
      return;
    }
    stepper.previous();
  }, [stepper]);

  const handleNextStep = useCallback(
    /**
     * @param {Array<string> | string} [fields]
     */
    async (fields) => {
      if (!stepper) {
        return;
      }
      let isValid = true;

      if (fields) {
        isValid = await trigger(fields);
      }

      if (isValid) {
        stepper.next();
      }
    },
    [stepper, trigger]
  );

  const handleSubmit = useCallback(
    /**
     * @param {Function} onSubmit
     */
    async (onSubmit) => {
      await trigger();
      for (const step of steps) {
        const hasError = step.fields.some((field) => {
          const { error } = getFieldState(field);

          return Boolean(error);
        });

        if (hasError) {
          handleGoToStep(step.step);
          return;
        }
      }

      onSubmit();
    },
    [getFieldState, handleGoToStep, steps, trigger]
  );

  return (
    <WizardFormStepperContext.Provider
      value={{
        onGoTo: handleGoToStep,
        onPrevStep: handlePrevStep,
        onNextStep: handleNextStep,
        onSubmit: handleSubmit,
      }}
    >
      {children}
    </WizardFormStepperContext.Provider>
  );
};
