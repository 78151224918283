import { DeveloperApiToken } from '@api/hooks/organization/types';
import TableLoader from '@utils/components/TableLoader/TableLoader';
import DataTable from 'react-data-table-component';
import { ChevronDown } from 'react-feather';
import { useColumns } from './useColumns';

type Props = {
  data: DeveloperApiToken[];
  isLoading: boolean;
  onDeactivateToken: (token: DeveloperApiToken['tokenId']) => void;
};

export default function ApiTokensTable({ data, isLoading, onDeactivateToken }: Props) {
  const columns = useColumns({ onDeactivateToken });

  return (
    <DataTable
      className="react-dataTable overflow-visible"
      columns={columns}
      data={data}
      responsive
      sortIcon={<ChevronDown />}
      progressPending={isLoading}
      progressComponent={<TableLoader />}
    />
  );
}
