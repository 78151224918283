import { useOrganizationStore } from '@utils/hooks/useOrganizationStore';
import { useProjectStore } from '@utils/hooks/useProjectStore';
import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import CreateOrganizationModal from './create-organization/CreateOrganizationModal';
import { useGetOrganizations } from './hooks/useGetOrganizations';
import { useTranslation } from 'react-i18next';
import './organizations.scss';
import SingleOrganizationListItem from './components/singleOrganizationListItem';
import AddOrganizationListItem from './components/addOrganizationListItem';
import { Skeleton, SkeletonList } from '@src/views/components/skeleton';

const Organizations = () => {
  const [isNewOrganizationOpen, setIsNewOrganizationOpen] = useState(false);
  const navigate = useNavigate();
  const { onSaveOrganization } = useOrganizationStore();
  const { data: organizations, refetch, isFetching: isFetchingOrganizations } = useGetOrganizations();
  const { onClearProject } = useProjectStore();
  const { onClearOrganization } = useOrganizationStore();
  const { t } = useTranslation();

  useEffect(() => {
    onClearProject();
    onClearOrganization();
  }, [onClearOrganization, onClearProject]);

  const toggleNewOrganizationModal = () => {
    setIsNewOrganizationOpen(!isNewOrganizationOpen);
  };

  const onOpenOrganization = async (org) => {
    onSaveOrganization({ organizationUuid: org.uuid });
    navigate('/projects');
  };

  return (
    <Fragment>
      <div className="px-4">
        <h4>{t('organizations.organizations')}</h4>
        <p>{t('organizations.organizationsDescription')}.</p>
        {isFetchingOrganizations && (
          <Row>
            <SkeletonList count={5}>
              <Col lg={{ size: 4, order: 1 }} sm={{ size: 12 }} xs={{ order: 2 }} className="mb-2">
                <Skeleton style={{ height: 120 }} />
              </Col>
            </SkeletonList>
          </Row>
        )}
        {!isFetchingOrganizations && (
          <Row>
            {organizations?.data.map((org) => (
              <SingleOrganizationListItem org={org} key={org.uuid} onOpenOrganization={onOpenOrganization} />
            ))}
            <AddOrganizationListItem onAddOrganization={toggleNewOrganizationModal} />
          </Row>
        )}
        {isNewOrganizationOpen && (
          <CreateOrganizationModal toggle={toggleNewOrganizationModal} refetchOrganizations={refetch} />
        )}
      </div>
    </Fragment>
  );
};

export default Organizations;
