// ** Icons Imports
import { Check, X } from 'react-feather';
// ** Custom Components
import Avatar from '@components/avatar';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import toast from 'react-hot-toast';

const getTranslationKey = (translation) => {
  if (!translation) return '';

  if (typeof translation === 'string') return translation;

  return translation?.key || '';
};

const ToastContent = ({ content, success, header, t }) => {
  return (
    <div className="d-flex" data-testid="snackbar" role="alert">
      <div className="me-1">
        {success ? (
          <Avatar size="sm" color="success" icon={<Check size={14} />} />
        ) : (
          <Avatar size="sm" color="danger" icon={<X size={14} />} />
        )}
      </div>
      <div className="d-flex flex-column">
        <div className="d-flex justify-content-between">
          <h6 data-testid="snackbar-text">{i18n.t(getTranslationKey(header), header?.options)}</h6>
          <X size={12} className="cursor-pointer" onClick={() => toast.dismiss(t.id)} />
        </div>
        {content && <span data-testid="snackbar-content">{i18n.t(getTranslationKey(content), content?.options)}</span>}
      </div>
    </div>
  );
};

ToastContent.propTypes = {
  header: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ key: PropTypes.string, options: PropTypes.shape({}) }),
  ]).isRequired,
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ key: PropTypes.string, options: PropTypes.shape({}) }),
  ]),
  success: PropTypes.bool,
  t: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
};

export default ToastContent;
