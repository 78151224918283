import { useMutateDeleteMemberFromOrganization } from '@api/hooks/organization/organizationQuery';
import ToastContent from '@src/views/components/toastContent/ToastContent';
import { useOverlayContext } from '@utils/context/OverlayContext/useOverlayContext';
import { useActiveOrganization } from '@utils/hooks/useActiveOrganization';
import toast from 'react-hot-toast';

/**
 *
 * @param {import('@tanstack/react-query').MutateOptions} [options]
 * @returns
 */
export function useRemoveMember(options = {}) {
  const organizationUuid = useActiveOrganization();
  const { mutateAsync } = useMutateDeleteMemberFromOrganization({ organizationUuid }, options);
  const [onShowOverlay, onHideOverlay] = useOverlayContext();

  /**
   * @param {string} emailAddress
   */
  const handleRemoveMember = async (emailAddress) => {
    try {
      onShowOverlay();

      await mutateAsync({
        emailAddress,
      });

      toast((t) => (
        <ToastContent t={t} success={true} header="organizations.settings.usersAndPermissions.successMemberRemoved" />
      ));
    } catch (error) {
      toast((t) => (
        <ToastContent t={t} success={false} header="organizations.settings.usersAndPermissions.failedMemberRemoved" />
      ));
    }

    onHideOverlay();
  };

  return {
    onRemoveMember: handleRemoveMember,
  };
}
