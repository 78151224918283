import { Button, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import '@styles/react/pages/modal-create-app.scss';
import { useTranslation } from 'react-i18next';

const BlockRemovingMemberModal = ({ isOpen, toggle, member }) => {
  const { t } = useTranslation();

  const memberName = member.firstName && member.lastName ? `${member.firstName} ${member.lastName}` : member.email;

  return (
    <Modal isOpen={isOpen} toggle={() => toggle()} className="modal-dialog-centered modal-lg">
      <ModalHeader className="bg-transparent" toggle={() => toggle()}></ModalHeader>
      <ModalBody className="pb-3 px-sm-3">
        <h1 className="text-center mb-1">{t('projects.removeMember.cannotRemoveOwner', { memberName })}</h1>
        <Row className="m-0 d-flex justify-content-center">
          <Button className="w-auto" containerClassName="text-center" color="primary" onClick={() => toggle()}>
            {t('general.close')}
          </Button>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default BlockRemovingMemberModal;
