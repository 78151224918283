import classNames from 'classnames';

type Props = {
  text: string;
  truncate?: boolean;
};

export default function TextCell({ text, truncate = true }: Props) {
  return (
    <>
      <div className={classNames('d-flex justify-content-left align-items-center', truncate && 'text-truncate')}>
        {text}
      </div>
    </>
  );
}
