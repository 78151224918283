import { Link } from 'react-router-dom';
import { useCallback, useState } from 'react';
import themeConfig from '@configs/themeConfig';
import { ChevronLeft } from 'react-feather';
import { Card, CardBody, CardTitle, CardText, Form, Fade } from 'reactstrap';
import { forgotPasswordSchema } from './forgotPasswordSchema';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import toast from 'react-hot-toast';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import ToastContent from '../../../components/toastContent/ToastContent';
import SubmitButton from '@src/views/components/buttons/SubmitButton/SubmitButton';
import { useForgotPassword } from './hooks/useForgotPassword';
import { Trans, useTranslation } from 'react-i18next';
import { PATHS } from '@src/router/paths';
import '@styles/react/pages/page-authentication.scss';
import { ForgotPasswordFormValues } from './types';
import { Input } from '@utils/components/form/Inputs';
import SubmitConfirmation from '@utils/components/SubmitConfirmation/SubmitConfirmation';

const defaultValues = {
  emailAddress: '',
};

const ForgotPasswordBasic = () => {
  const { control, handleSubmit } = useForm<ForgotPasswordFormValues>({
    defaultValues,
    resolver: yupResolver(forgotPasswordSchema),
  });
  const { t } = useTranslation();

  const { isLoading, mutate: forgotPassword, isSuccess, reset } = useForgotPassword();

  const [token, setToken] = useState<string | null>(null);

  const onVerify = useCallback((token) => {
    setToken(token);
  }, []);

  const onSubmit = async (data: ForgotPasswordFormValues) => {
    if (!token) {
      toast((t) => <ToastContent t={t} success={false} header="passwordReset.failedEmail" content={null} />);
      return;
    }

    forgotPassword(
      { emailAddress: data.emailAddress, recaptchaResponse: token },
      {
        onError: () => {
          toast((t) => <ToastContent t={t} success={false} header="passwordReset.failedEmail" content={null} />);
        },
      }
    );
  };

  return (
    <div className="login-card-content">
      <Card>
        <CardBody>
          <Link className="brand-logo" to="/" onClick={(e) => e.preventDefault()}>
            <img src={themeConfig.app.appLogoFullImage} alt="logo" height={50} className="mt-2 mb-2" />
          </Link>
          {isSuccess && (
            <Fade in>
              <div className="d-flex align-items-center flex-column">
                <SubmitConfirmation
                  title={t('passwordReset.successEmail')}
                  subtitle={t('passwordReset.successEmailContent')}
                />
                <SubmitButton color="primary" outline className="mt-3" onClick={() => reset()} isLoading={isLoading}>
                  {t('forgotPassword.resendLink')}
                </SubmitButton>
              </div>
            </Fade>
          )}
          {!isSuccess && (
            <>
              <CardTitle tag="h4" className="mb-1">
                {t('passwordReset.title')}
              </CardTitle>
              <Form className="auth-forgot-password-form mt-2" onSubmit={handleSubmit(onSubmit)}>
                <CardText className="mb-2">{t('forgotPassword.forgotPasswordText')}</CardText>
                <div className="mb-3">
                  <Input
                    control={control}
                    name="emailAddress"
                    autoFocus
                    placeholder="john@example.com"
                    label={t('general.email')}
                  />
                </div>

                <SubmitButton color="primary" block isLoading={isLoading}>
                  <span className="text-black">{t('forgotPassword.sendLink')}</span>
                </SubmitButton>
              </Form>
            </>
          )}
          <p className="text-center mt-2">
            <Link to={PATHS.AUTH.ROOT}>
              <ChevronLeft className="rotate-rtl me-25" size={14} />
              <span className="align-middle">{t('login.backToLogin')}</span>
            </Link>
          </p>

          {!isSuccess && <GoogleReCaptcha onVerify={onVerify} />}
          <p className="mt-4 text-center">
            <Trans
              i18nKey="general.recaptchaInfo"
              components={{
                policy: <a href="https://policies.google.com/privacy"></a>,
                terms: <a href="https://policies.google.com/terms"></a>,
              }}
            />
          </p>
        </CardBody>
      </Card>
    </div>
  );
};

export default ForgotPasswordBasic;
