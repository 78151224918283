import { useState, useRef } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Wizard from '@components/wizard';
import { Server, FileText } from 'react-feather';
import PlatformStep from './create-app-steps/PlatformStep';
import DetailsStep from './create-app-steps/DetailsStep';
import { useCreateApplication } from '../../hooks/useCreateApplication';
import { FormProvider, useForm } from 'react-hook-form';
import '@styles/react/pages/modal-create-app.scss';
import { WizardFormStepperContextProvider } from '@utils/context/WizardFormStepperContext';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { createAppSchema } from './createAppSchema';
import { APPLICATION_PLATFORM } from '../../consts';

const stepsInfo = [
  { step: 1, fields: ['platformName'] },
  { step: 2, fields: ['name', 'packageName'] },
];

const CreateApp = ({ toggle, projectUuid }) => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const [stepper, setStepper] = useState(null);
  const methods = useForm({
    defaultValues: {
      platformName: APPLICATION_PLATFORM.IOS,
      name: '',
      packageName: '',
    },
    mode: 'onChange',
    resolver: yupResolver(createAppSchema),
  });

  const { onCreateApp } = useCreateApplication(
    { projectUuid },
    {
      onSuccess: () => {
        toggle();
      },
    }
  );

  const handleSubmit = () => {
    onCreateApp(methods.getValues());
  };

  const steps = [
    {
      id: 'platform',
      title: t('applications.steps.platform.title'),
      subtitle: t('applications.steps.platform.subtitle'),
      icon: <Server className="font-medium-3" />,
      content: <PlatformStep stepInfo={stepsInfo[0]} />,
    },
    {
      id: 'details',
      title: t('applications.steps.details.title'),
      subtitle: t('applications.steps.details.subtitle'),
      icon: <FileText className="font-medium-3" />,
      content: <DetailsStep onSubmit={handleSubmit} />,
    },
  ];

  return (
    <Modal isOpen toggle={() => toggle()} className="modal-dialog-centered modal-lg">
      <ModalHeader className="bg-transparent" toggle={() => toggle()}></ModalHeader>
      <ModalBody className="pb-3 px-sm-3">
        <h1 className="text-center mb-1">{t('applications.createApp')}</h1>
        <p className="text-center mb-2">{t('applications.description')}</p>
        <FormProvider {...methods}>
          <WizardFormStepperContextProvider stepper={stepper} steps={stepsInfo}>
            <Wizard
              ref={ref}
              steps={steps}
              type="vertical"
              headerClassName="border-0"
              options={{ linear: false }}
              instance={(el) => setStepper(el)}
              contentClassName="shadow-none"
              className="bg-transparent create-app-wizard shadow-none border-0 "
            />
          </WizardFormStepperContextProvider>
        </FormProvider>
      </ModalBody>
    </Modal>
  );
};

export default CreateApp;
