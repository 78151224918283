/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChevronRight } from 'react-feather';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';

interface Props {
  org: any;
  onOpenOrganization: (org: any) => void;
}

function SingleOrganizationListItem({ org, onOpenOrganization }: Props) {
  return (
    <Col
      lg={{ size: 4, order: 1 }}
      sm={{ size: 12 }}
      xs={{ order: 2 }}
      className="mb-2"
      data-testid={`organization-${org.uuid}`}
    >
      <Card className="organization-box" onClick={() => onOpenOrganization(org)}>
        <CardBody>
          <Row className="m-0 d-flex justify-content-between align-items-center h-100">
            <Col sm={10} className="p-0 d-flex align-items-center">
              <h4 className="mb-0">{org.name}</h4>
            </Col>
            <Col sm={2} className="d-flex align-items-center">
              <Button color="transparent" className="border-0 p-0 text-primary">
                <ChevronRight size="24px" />
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
}
export default SingleOrganizationListItem;
