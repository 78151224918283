import { ApplicationSecret } from '@api/useApplicationApi.types';
import Snowflake from '@src/assets/images/icons/snowflake.svg';
import { useModal } from '@utils/hooks/useModal';
import classNames from 'classnames';
import moment from 'moment';
import { Edit, MoreVertical, Trash } from 'react-feather';
import { Trans, useTranslation } from 'react-i18next';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import EditDescriptionModal from './EditDesriptionModal';
import DeleteSecretModal from './DeleteSecretModal';
import FreezeSecretConfirmationModal from './FreezeSecretConfirmationModal';

type Props = {
  secret: ApplicationSecret;
  applicationUuid: string;
  projectUuid: string;
};

function SecretsTableItem({ secret, applicationUuid, projectUuid }: Props) {
  const { t } = useTranslation();
  const [isDescriptionModalOpen, openDescriptionModal, closeDescriptionModal] = useModal();
  const [isDeleteModalOpen, openDeleteModal, closeDeleteModal] = useModal();
  const [isFreezeModalOpen, openFreezeModal, closeFreezeModal] = useModal();

  return (
    <>
      <tr key={secret.applicationSecretId}>
        <td className={classNames(secret.isFrozen && 'opacity-50')} role="row-id">
          {secret.applicationSecretId}
        </td>
        <td className={classNames(secret.isFrozen && 'opacity-50')} role="row-description">
          {secret.description}
        </td>
        <td className={classNames(secret.isFrozen && 'opacity-50')} role="row-timestamp">
          <Trans
            i18nKey="applications.secretCreatedRow"
            values={{
              createdAt: moment(secret.createdBy.createdAt).format('LLLL'),
              creator: `${secret.createdBy.firstName} ${secret.createdBy.lastName}`,
            }}
            components={{ b: <strong /> }}
          />
        </td>
        <td>
          <UncontrolledDropdown data-testid="secret-actions-dropdown">
            <DropdownToggle className="p-0 text-white" color="none">
              <MoreVertical />
            </DropdownToggle>
            <DropdownMenu container={document.body} data-testid="secret-actions-dropdown-menu">
              <DropdownItem onClick={openDescriptionModal} className="d-flex align-items-center gap-2 w-100 ">
                <Edit size="18px" />
                <span className="m-0 h5">{t('general.edit')}</span>
              </DropdownItem>
              <DropdownItem onClick={openFreezeModal} className="d-flex align-items-center gap-2 w-100 ">
                <img src={Snowflake} alt="snowflake" sizes="18px" />
                <span className="m-0 h5">{secret.isFrozen ? t('general.unfreeze') : t('general.freeze')}</span>
              </DropdownItem>
              <DropdownItem onClick={openDeleteModal} className="d-flex align-items-center gap-2 w-100 ">
                <Trash size="18px" />
                <span className="m-0 h5">{t('general.remove')}</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </td>
      </tr>
      <EditDescriptionModal
        onClose={closeDescriptionModal}
        applicationUuid={applicationUuid}
        isOpen={isDescriptionModalOpen}
        projectUuid={projectUuid}
        secret={secret}
      />
      <DeleteSecretModal
        toggle={closeDeleteModal}
        applicationUuid={applicationUuid}
        projectUuid={projectUuid}
        secret={secret}
        isOpen={isDeleteModalOpen}
      />
      <FreezeSecretConfirmationModal
        toggle={closeFreezeModal}
        applicationUuid={applicationUuid}
        projectUuid={projectUuid}
        secret={secret}
        isOpen={isFreezeModalOpen}
      />
    </>
  );
}

export default SecretsTableItem;
