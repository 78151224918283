import { useQueryOrganization } from '@api/hooks/organization/organizationQuery';
import { handleSelectOrganization } from '@store/organizationsProjects';
import { useOrganizationStore } from '@utils/hooks/useOrganizationStore';
import axios from 'axios';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export function useValidateOrganization() {
  const { onReadOrganization, onClearOrganization } = useOrganizationStore();
  const navigate = useNavigate();
  const organizationUuid = onReadOrganization();
  const dispatch = useDispatch();

  const result = useQueryOrganization(
    {
      organizationUuid,
    },
    {
      enabled: !!organizationUuid,
      onSuccess: () => {
        dispatch(handleSelectOrganization({ organizationUuid }));
      },
      onError: (err) => {
        if (axios.isAxiosError(err) && err.response?.status === 404) {
          navigate('/organizations');
        }
      },
    }
  );

  useEffect(() => {
    if (!organizationUuid) {
      navigate('/organizations');
      onClearOrganization();
    }
  }, [dispatch, navigate, onClearOrganization, organizationUuid, result.data]);

  return result;
}
