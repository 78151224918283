import androidLogo from '@src/assets/images/icons/technology/android.svg';
import appleLogo from '@src/assets/images/icons/technology/apple.svg';
import unityLogo from '@src/assets/images/icons/technology/unity.svg';
import { APPLICATION_PLATFORM } from '@src/views/pages/application/consts';
import browserLogo from '@src/assets/images/icons/technology/browser.svg';

type Props = {
  platformName: ValueOf<typeof APPLICATION_PLATFORM>;
  height?: number;
};

export const PlatformIcon = ({ platformName, height }: Props) => {
  const imgHeight = height ? `${height}` : '40';

  switch (platformName.toLowerCase()) {
    case APPLICATION_PLATFORM.ANDROID.toLowerCase():
      return <img src={androidLogo} alt="androidLogo" height={imgHeight} />;

    case APPLICATION_PLATFORM.IOS.toLowerCase():
      return <img src={appleLogo} alt="appleLogo" height={imgHeight} />;

    case APPLICATION_PLATFORM.UNITY.toLowerCase():
      return <img src={unityLogo} alt="unityLogo" height={imgHeight} />;

    case APPLICATION_PLATFORM.WEB.toLowerCase():
    default:
      return <img src={browserLogo} alt="browserLogo" height={imgHeight} />;
  }
};
