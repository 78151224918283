import OrganizationSettings from '@src/views/pages/organization-settings';
import UserProfile from '@src/views/pages/user-profile/UserProfile';
import CostExplorer from '@src/views/pages/costExplorer';
import { PATHS } from '../paths';
import ProjectRoutes from '../components/ProjectRoutes';

const AccountRoutes = {
  path: PATHS.ACCOUNT.ROOT,
  element: <ProjectRoutes />, // tmp until project and organization is required to show those routes
  children: [
    {
      path: PATHS.ACCOUNT.ORGANIZATION.NAME,
      element: <OrganizationSettings />,
    },
    {
      path: PATHS.ACCOUNT.COST_EXPLORER.NAME,
      element: <CostExplorer />,
    },
    {
      path: PATHS.ACCOUNT.PROFILE.NAME,
      element: <UserProfile />,
    },
  ],
};

export default AccountRoutes;
