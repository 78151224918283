import { CardText, CardTitle } from 'reactstrap';
import ComponentSpinner from '../../../@core/components/spinner/Loading-spinner';

function SettingEnvironmentScreen() {
  return (
    <>
      <CardTitle tag="h2" className="fw-bolder mb-1 text-center">
        We are preparing everything for you
      </CardTitle>
      <ComponentSpinner />
      <CardText className="mt-2 text-center">This may take a while, please wait</CardText>
    </>
  );
}

export default SettingEnvironmentScreen;
