/**
 * @typedef {object} ErrorObject
 * @property {string} key
 * @property {Record<string, string | number>} options
 *
 *
 * @param {import("i18next").TFunction} t
 * @param {string | ErrorObject | undefined} error
 * @returns {string}
 */
export const errorMessage = (t, error) => {
  if (!error) return '';

  if (typeof error === 'string') return t(error);

  if ('key' in error && 'options' in error) {
    const { key, options } = error;
    return t(key, options);
  }

  return '';
};
