import ReactPaginate from 'react-paginate';

/**
 *
 * @param {Object} props
 * @param {number} props.currentPage
 * @param {number} props.pageCount
 * @param {(newPage: number)} props.onPageChange
 * @returns {JSX.Element}
 */
function CustomPagination({ pageCount, currentPage, onPageChange }) {
  return (
    <ReactPaginate
      previousLabel={''}
      nextLabel={''}
      pageCount={pageCount || 1}
      activeClassName="active"
      forcePage={currentPage !== 0 ? currentPage - 1 : 0}
      onPageChange={(page) => onPageChange(page)}
      pageClassName={'page-item'}
      nextLinkClassName={'page-link'}
      nextClassName={'page-item next'}
      previousClassName={'page-item prev'}
      previousLinkClassName={'page-link'}
      pageLinkClassName={'page-link'}
      containerClassName={'pagination react-paginate justify-content-end my-2 pe-1'}
    />
  );
}

export default CustomPagination;
