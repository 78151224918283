// ** Auth Endpoints

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);
export default {
  loginEndpoint: `${isLocalhost ? '/api' : ''}/login`,
  registerEndpoint: `${isLocalhost ? '/api' : ''}/users/signup`,
  refreshEndpoint: `${isLocalhost ? '/api' : ''}/refresh`,
  resendActivationEmailEndpoint: `${isLocalhost ? '/api' : ''}/users/resend-activation-email`,
  sendResetPasswordLink: `${isLocalhost ? '/api' : ''}/users/forgot-password`,
  resetPassword: `${isLocalhost ? '/api' : ''}/users/reset-password`,
  activateUser: `${isLocalhost ? '/api' : ''}/users/activate`,
  applyForAccessCode: `${isLocalhost ? '/api' : ''}/users/apply-for-access-code`,
  getUserData: `${isLocalhost ? '/api' : ''}/user/me`,

  // **Apps
  createDefaultApps: `${isLocalhost ? '/api' : ''}/create-default-apps`,
  createApplication: `${isLocalhost ? '/api' : ''}/applications?projectUuid=`,
  applicationEndpoint: `${isLocalhost ? '/api' : ''}/applications`,
  applicationsInOrganizationEndpoint: `${isLocalhost ? '/api' : ''}/applications/in-organization`,
  getApplicationPlatforms: `${isLocalhost ? '/api' : ''}/dictionaries/application-platforms`,

  // **Organizations
  organizationEndpoint: `${isLocalhost ? '/api' : ''}/organizations`,

  // ** Projects
  projectEndpoint: `${isLocalhost ? '/api' : ''}/projects`,

  // ** Costs
  getUsageForOrganization: `${isLocalhost ? '/api' : ''}/costs/usage`,
  getUsageForProjectCSV: `${isLocalhost ? '/api' : ''}/costs/usage/csv`,

  // ** Dictionaries
  dictionariesEndpoint: `${isLocalhost ? '/api' : ''}/dictionaries`,

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
};
