import axios from 'axios';

export function getProvisioningCodeErrorTranslationKey(error: unknown): string {
  let translationHeader = 'applications.provisioningAcceptance.codeGenericError';

  if (axios.isAxiosError(error) && error?.response?.status) {
    const statusCode = error.response.status;

    if (statusCode === 428 || statusCode === 404) {
      translationHeader = 'applications.provisioningAcceptance.codeNotFound';
    }

    if (statusCode === 410) {
      translationHeader =
        'applications.provisioningAcceptance.applications.provisioningAcceptance.codeAlreadyAcceptedOrRejected';
    }
  }

  return translationHeader;
}
