// ** React Imports
import { Link } from 'react-router-dom';

// ** Custom Components
// import Avatar from '@components/avatar'

// ** Store & Actions
import switchIcon from '@src//assets/images/icons/switch.svg';

// ** Third Party Components
import { Power } from 'react-feather';

// ** Reactstrap Imports
import { UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import { useQueryMe } from '@api/hooks/auth/authQuery';
import { useLogout } from '@utils/hooks/useLogout';

const UserDropdown = () => {
  const { onLogout } = useLogout();
  const { data: userStore } = useQueryMe();

  if (!userStore) {
    return <></>;
  }

  return (
    <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
      <DropdownToggle
        href="/"
        tag="a"
        className="nav-link dropdown-user-link"
        onClick={(e) => e.preventDefault()}
        data-testid="user-dropdown"
      >
        <div className="user-nav d-sm-flex d-none">
          <span className="user-name fw-bold">
            {userStore.firstName} {userStore.lastName}
          </span>
          <span className="user-status">{userStore.email}</span>
        </div>
        <div className="rounded-circle bg-dark text-uppercase" style={{ height: '40px', width: '40px' }}>
          <div className="d-flex justify-content-center align-items-center text-center h-100">
            {userStore.firstName ? userStore.firstName?.charAt(0) : ''}
          </div>
        </div>
        {/* <Avatar img={null} imgHeight="40" imgWidth="40" status="online" /> */}
      </DropdownToggle>
      <DropdownMenu end>
        <DropdownItem tag={Link} to="/organizations">
          <img src={switchIcon} alt="Switch Icon" size={14} className="me-75" />
          <span className="align-middle">Switch organization</span>
        </DropdownItem>
        <DropdownItem
          tag={Link}
          to="/auth/login"
          onClick={() => {
            onLogout();
          }}
          data-testid="logout-button"
        >
          <Power size={14} className="me-75" />
          <span className="align-middle">Logout</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default UserDropdown;
