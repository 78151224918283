import * as yup from 'yup';
import { passwordYup } from '../schemas/password';

export const resetPasswordSchema = yup.object().shape({
  newPassword: passwordYup,
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], 'errors.passwordsNotMatch')
    .required(),
});
