import { useTranslation } from 'react-i18next';

type Props = {
  onOpenConfigureModal: () => void;
  className?: string;
};

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
export default function WebAppSDK({ onOpenConfigureModal, className }: Props) {
  const { t } = useTranslation();

  return (
    <div className={className} data-testid="web-sdk-steps">
      <h4>{t('applications.testApp.testAppForWeb.title')}</h4>
      <p>{t('applications.testApp.testAppForWeb.notReady')}</p>
    </div>
  );
}
