import { errorMessage } from '@utils/errors/errorMessages';
import { useId } from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormFeedback, Label, Input as BaseInput, FormGroup } from 'reactstrap';

/**
 * @typedef {InputProps & FormInputProps} Props
 *
 * @param {Props} props
 * @returns {JSX}
 */
export const Checkbox = ({ control, label, name, ...inputProps }) => {
  const { field, fieldState } = useController({ name, control });
  const { t } = useTranslation();
  const id = useId();

  return (
    <>
      <FormGroup>
        <BaseInput
          id={id}
          className="me-1"
          type="checkbox"
          invalid={fieldState.error}
          name={name}
          {...field}
          {...inputProps}
        />
        <Label className="form-label" htmlFor={id} check>
          {label}
        </Label>
      </FormGroup>
      {fieldState.error && <FormFeedback>{errorMessage(t, fieldState.error.message)}</FormFeedback>}
    </>
  );
};
