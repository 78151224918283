import Projects from '../../views/pages/project';
import OrganizationRoutesWrapper from '../components/OrganizationRoutes';
import { PATHS } from '../paths';
import AccountRoutes from './AccountRoutes';
import ProjectRoutes from './ProjectRoutes';

const OrganizationRoutes = {
  path: PATHS.ROOT,
  element: <OrganizationRoutesWrapper />,
  children: [
    {
      path: PATHS.PROJECTS.NAME,
      element: <Projects />,
    },
    ProjectRoutes,
    AccountRoutes,
  ],
};

export default OrganizationRoutes;
