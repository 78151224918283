import { useQueryOrganization } from '@api/hooks/organization/organizationQuery';
import { useContentOverlayContext } from '@utils/context/ContentOverlayContext/useContentOverlayContext';
import { useActiveOrganization } from '@utils/hooks/useActiveOrganization';
import { useEffect } from 'react';

export function useGetOrganization() {
  const organizationUuid = useActiveOrganization();
  const result = useQueryOrganization({ organizationUuid });
  const [showLoad, hideLoad] = useContentOverlayContext();

  useEffect(() => {
    if (result.isFetching) {
      showLoad();
    } else {
      hideLoad();
    }
  }, [hideLoad, result.isFetching, showLoad]);

  return result;
}
