import { useMutateExportCost } from '@api/hooks/cost/costQuery';
import ToastContent from '@src/views/components/toastContent/ToastContent';
import { useContentOverlayContext } from '@utils/context/ContentOverlayContext/useContentOverlayContext';
import { downloadFile } from '@utils/file';
import { useActiveOrganization } from '@utils/hooks/useActiveOrganization';
import toast from 'react-hot-toast';

export function useCostDataExport({ from, to }) {
  const [onShowOverlay, onHideOverlay] = useContentOverlayContext();
  const organizationUuid = useActiveOrganization();
  const result = useMutateExportCost({ from, to, organizationUuid });

  const handleExport = () => {
    onShowOverlay();
    result.mutate(void 0, {
      onSettled: () => {
        onHideOverlay();
      },
      onSuccess: (res) => {
        const csvData = `data:text/plist;charset=utf-8,${encodeURIComponent(res)}`;
        downloadFile('usage.csv', csvData);
      },
      onError: () => {
        toast((t) => <ToastContent t={t} success={false} header="errors.failedGeneratingFile" />);
      },
    });
  };

  return {
    onExport: handleExport,
  };
}
