import { yupResolver } from '@hookform/resolvers/yup';
import MemberPicker from '@src/views/pages/organizations/create-organization/steps/usersStep/components/OrganizationMemberPicker';
import '@styles/react/pages/modal-create-app.scss';
import { useActiveOrganization } from '@utils/hooks/useActiveOrganization';
import { useFieldArray, useForm } from 'react-hook-form';
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { useInviteMany } from '../../hooks/useInviteMany';
import { multipleInvitationsSchema } from './schema';
import { useTranslation } from 'react-i18next';

const AddUserModal = ({ isOpen, toggle, onRefetch }) => {
  const organizationUuid = useActiveOrganization();
  const { t } = useTranslation();

  const { onInviteMany } = useInviteMany(
    {
      organizationUuid,
    },
    {
      onSuccess: () => {
        toggle();
        onRefetch();
      },
    }
  );

  const {
    control,
    handleSubmit: onSubmit,
    formState: invitationFormState,
  } = useForm({
    defaultValues: {
      users: [],
    },
    resolver: yupResolver(multipleInvitationsSchema),
  });
  const { append, fields: users } = useFieldArray({
    control,
    name: 'users',
  });

  const handleAddMember = (data) => {
    append({
      email: data.email,
      role: data.role,
    });
  };

  const handleSubmit = (data) => {
    onInviteMany(data.users.map((user) => ({ email: user.email, roleUuid: user.role.value })));
  };

  return (
    <Modal isOpen={isOpen} toggle={() => toggle()} className="modal-dialog-centered modal-lg">
      <ModalHeader className="bg-transparent" toggle={() => toggle()}></ModalHeader>
      <ModalBody className="pb-3 px-sm-3">
        <MemberPicker onSubmit={handleAddMember} />
        <Row className="mx-0">
          {users.length ? <h5 className="pt-1">{t('organizations.settings.usersAndPermissions.members')}</h5> : <></>}
          {users.map((member, key) => (
            <Row key={key} className="mb-2 mx-0">
              <Col sm={6} className="p-0">
                <span className="user-status fw-bold">{member.email}</span>
              </Col>
              <Col sm={4} className="d-flex align-items-center text-right">
                <span className="user-status">{member.role.label}</span>
              </Col>
            </Row>
          ))}
        </Row>
        <div className="d-flex justify-content-center mt-4">
          <Button
            color="primary"
            onClick={onSubmit(handleSubmit)}
            disabled={!invitationFormState.isValid}
            data-testid="organization-invite-members"
          >
            {t('general.invite')}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default AddUserModal;
