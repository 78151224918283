import FormActionsNext from '@utils/components/form/FormActions/FormActionsNext';
import { Input, Select } from '@utils/components/form/Inputs';
import { useCountryCodes } from '@utils/hooks/dictionaries';
import { useWizardFormStepperContext } from '@utils/context/WizardFormStepperContext';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

const BillingStep = ({ stepInfo }) => {
  const { t } = useTranslation();
  const { onNextStep, onPrevStep } = useWizardFormStepperContext();
  const { data: countryCodes, isLoading: isCountryCodesLoading } = useCountryCodes();
  const { control } = useFormContext();

  const handleNext = () => {
    onNextStep(stepInfo.fields);
  };

  return (
    <>
      <div className="modal-form-content">
        <Row className="m-0">
          <Col className="p-0 form-field">
            <Input
              label={t('organizations.createOrganization.billing.companyName')}
              control={control}
              placeholder={t('organizations.createOrganization.billing.placeholder.companyName')}
              name="companyName"
            />
          </Col>
        </Row>
        <Row className="m-0">
          <Col sm={6} className="p-0 pe-1 form-field">
            <Input
              label={t('organizations.createOrganization.billing.taxID')}
              control={control}
              placeholder={t('organizations.createOrganization.billing.placeholder.taxID')}
              name="taxId"
            />
          </Col>
          <Col sm={6} className="p-0 ps-1">
            <Input
              label={t('organizations.createOrganization.billing.VATNumber')}
              control={control}
              placeholder={t('organizations.createOrganization.billing.placeholder.VATNumber')}
              name="vatNumber"
            />
          </Col>
        </Row>
        <Row className="m-0">
          <Col className="p-0 form-field">
            <Select
              label={t('organizations.createOrganization.billing.country')}
              control={control}
              placeholder={t('organizations.createOrganization.billing.placeholder.country')}
              options={countryCodes}
              name="country"
              isLoading={isCountryCodesLoading}
              isClearable={false}
            />
          </Col>
        </Row>
        <Row className="m-0">
          <Col className="p-0 form-field">
            <Input
              label={t('organizations.createOrganization.billing.billingAddress')}
              control={control}
              placeholder={t('organizations.createOrganization.billing.placeholder.billingAddress')}
              name="billingAddress"
            />
          </Col>
        </Row>
        <Row className="m-0">
          <Col sm={6} className="p-0 pe-1 form-field">
            <Input
              label={t('organizations.createOrganization.billing.state')}
              control={control}
              name="state"
              placeholder={t('organizations.createOrganization.billing.placeholder.state')}
            />
          </Col>
          <Col sm={6} className="p-0 ps-1 form-field">
            <Input
              label={t('organizations.createOrganization.billing.zipCode')}
              control={control}
              name="zipCode"
              placeholder={t('organizations.createOrganization.billing.placeholder.zipCode')}
            />
          </Col>
        </Row>
      </div>
      <FormActionsNext onNext={handleNext} onPrevious={onPrevStep} />
    </>
  );
};

export default BillingStep;
