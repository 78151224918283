import classNames from 'classnames';
import { Button } from 'reactstrap';

/**
 *
 * @param {ButtonProps} props
 * @returns {JSX}
 */
const StateButton = ({ active, children, ...props }) => {
  const color = active ? 'primary' : 'secondary';

  return (
    <Button {...props} className={classNames('py-3 px-2', props.className)} block active={active} outline color={color}>
      {children}
    </Button>
  );
};

export default StateButton;
