import {
  APPLICATION_SECRETS_QUERY_KEY,
  useMutateAcceptProvisioningCode,
  useMutateRejectProvisioningCode,
  useQueryProvisioningCodeStatus,
} from '@api/hooks/application/applicationQuery';
import ToastContent from '@src/views/components/toastContent';
import { getSecondsToDate } from '@utils/Utils';
import moment from 'moment';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { getProvisioningCodeErrorTranslationKey } from './helpers';
import { useQueryClient } from '@tanstack/react-query';
import { WAIT_UNTIL_CODE_IS_FETCHED } from '../../consts';

const SECOND_IN_MS = 1000;

type Props = {
  applicationUuid: string;
  provisioningCode: string;
  onFinish: () => void;
};

export function useCodeAcceptance({ applicationUuid, provisioningCode, onFinish }: Props) {
  const [acceptedAt, setAcceptedAt] = useState<string>('');
  const queryClient = useQueryClient();
  const {
    isLoading: isAccepting,
    mutate: acceptCode,
    isSuccess: codeAccepted,
  } = useMutateAcceptProvisioningCode({
    onSuccess() {
      setAcceptedAt(moment().toString());
    },
    onError(error) {
      toast((t) => <ToastContent t={t} success={false} header={getProvisioningCodeErrorTranslationKey(error)} />);
    },
  });

  const { isLoading: isRejecting, mutate: rejectCode } = useMutateRejectProvisioningCode({
    onSettled() {
      onFinish();
    },
    onSuccess() {
      toast((t) => <ToastContent t={t} success={true} header="applications.provisioningAcceptance.rejectSuccess" />);
    },
    onError(error) {
      toast((t) => <ToastContent t={t} success={false} header={getProvisioningCodeErrorTranslationKey(error)} />);
    },
  });

  useQueryProvisioningCodeStatus(
    {
      applicationUuid,
      provisioningCode,
    },
    {
      enabled: codeAccepted,

      refetchInterval: (data) => {
        if (!!data?.secretFetched) {
          queryClient.invalidateQueries({ queryKey: APPLICATION_SECRETS_QUERY_KEY({ appUuid: applicationUuid }) });
          toast((t) => (
            <ToastContent t={t} success={true} header="applications.provisioningAcceptance.acceptanceSuccess" />
          ));

          onFinish();

          return false;
        }

        const timePassed = Math.abs(getSecondsToDate(acceptedAt));

        if (Number.isInteger(timePassed) && timePassed > WAIT_UNTIL_CODE_IS_FETCHED) {
          onFinish();
          toast((t) => (
            <ToastContent t={t} success={false} header="applications.provisioningAcceptance.codeNotFetched" />
          ));

          return false;
        }

        return SECOND_IN_MS;
      },
    }
  );

  return {
    isRejecting,
    rejectCode,
    isAccepting,
    acceptCode,
    codeAccepted,
  };
}
