import { Table } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useQueryApplicationSecrets } from '@api/hooks/application/applicationQuery';
import UILoader from '@components/ui-loader';
import SecretsTableItem from './SecretsTableItem';

interface Props {
  applicationUuid: string;
  projectUuid: string;
}

function SecretsTable({ applicationUuid, projectUuid }: Props) {
  const { t } = useTranslation();
  const {
    isFetching,
    data: secrets,
    isSuccess,
  } = useQueryApplicationSecrets({ appUuid: applicationUuid, projectUuid });

  if (isFetching) {
    return (
      <div className={'py-5'}>
        <UILoader blocking />
      </div>
    );
  }

  if (!isSuccess || secrets?.length === 0) {
    return null;
  }

  return (
    <Table responsive>
      <thead>
        <tr>
          <th>{t('applications.secretId')}</th>
          <th>{t('general.description')}</th>
          <th>{t('general.created')}</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {secrets.map((secret) => (
          <SecretsTableItem
            secret={secret}
            key={secret.applicationSecretId}
            applicationUuid={applicationUuid}
            projectUuid={projectUuid}
          />
        ))}
      </tbody>
    </Table>
  );
}

export default SecretsTable;
