// eslint-disable-next-line unused-imports/no-unused-imports, @typescript-eslint/no-unused-vars
import { useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query';
import useCostsApi from '../../useCostsApi';

/**
 *
 * @param {CostExplorerUsageParams} params
 * @param {Omit<UseQueryOptions<CostExplorerUsageResponse>, "queryKey" | "queryFn">} [options]
 */
export function useQueryCost(params, options) {
  return useQuery({
    queryKey: ['cost', new URLSearchParams(params).toString()],
    queryFn: async () => {
      const result = await useCostsApi.getUsageForOrganization(params);

      return result.data;
    },
    ...options,
  });
}

export function useMutateExportCost(params, options) {
  return useMutation(async () => {
    const result = await useCostsApi.getUsageForProjectCSV(params);
    return result.data;
  }, options);
}
