import FormActionsNext from '@utils/components/form/FormActions/FormActionsNext';
import { Input } from '@utils/components/form/Inputs';
import { useWizardFormStepperContext } from '@utils/context/WizardFormStepperContext';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const DetailsStep = ({ stepInfo }) => {
  const { t } = useTranslation();
  const { onNextStep } = useWizardFormStepperContext();
  const { control } = useFormContext();

  const handleNextClick = () => {
    onNextStep(stepInfo.fields);
  };

  return (
    <>
      <div className="modal-form-content">
        <Input
          placeholder={t('organizations.createOrganization.details.placeholder.organizationName')}
          label={t('organizations.createOrganization.details.organizationName')}
          name="organizationName"
          control={control}
          className="form-field"
        />
      </div>
      <FormActionsNext showPrevious={false} onNext={handleNextClick} />
    </>
  );
};

export default DetailsStep;
