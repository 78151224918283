import SubmitButton from '@src/views/components/buttons/SubmitButton/SubmitButton';
import { useTranslation } from 'react-i18next';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';

interface Props {
  isOpen: boolean;
  toggle: () => void;
  isLoading: boolean;
  onConfirm: () => void;
  title: string;
  children?: React.ReactNode;
  buttons?: {
    confirmText?: string;
  };
}

function ConfirmationModal({ isOpen, toggle, isLoading, onConfirm, title, children, buttons }: Props) {
  const { t } = useTranslation();
  return (
    <Modal isOpen={isOpen} toggle={toggle} className="modal-dialog-centered modal-lg">
      <ModalHeader className="bg-transparent" toggle={toggle} />
      <ModalBody className="pb-3 px-sm-3">
        <h1 className="text-center mb-1">{title}</h1>
        {!!children && <p className="text-center mb-1">{children}</p>}
        <div className="d-flex justify-content-center mt-4 gap-2">
          <SubmitButton
            isLoading={isLoading}
            onClick={onConfirm}
            color="danger"
            data-testid="confirmation-confirm-button"
          >
            {buttons?.confirmText ?? t('general.apply')}
          </SubmitButton>
          <Button
            data-testid="confirmation-cancel-button"
            onClick={toggle}
            disabled={isLoading}
            outline
            color="primary"
          >
            {t('general.cancel')}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
}
export default ConfirmationModal;
