import { useMutateUpdateSecretDescription } from '@api/hooks/application/applicationQuery';
import { ApplicationSecret } from '@api/useApplicationApi.types';
import { Input } from '@utils/components/form/Inputs';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ToastContent from '@src/views/components/toastContent/ToastContent';
import toast from 'react-hot-toast';
import { Button, Form, Modal, ModalBody, ModalHeader } from 'reactstrap';
import * as yup from 'yup';
import SubmitButton from '@src/views/components/buttons/SubmitButton/SubmitButton';
import { yupResolver } from '@hookform/resolvers/yup';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  secret: ApplicationSecret;
  applicationUuid: string;
  projectUuid: string;
};

type EditDescriptionForm = Pick<ApplicationSecret, 'description'>;

const validationSchema = yup.object().shape<Record<keyof EditDescriptionForm, yup.AnySchema>>({
  description: yup.string().min(1).max(256).trim(),
});

export default function EditDescriptionModal({ isOpen, onClose, secret, applicationUuid, projectUuid }: Props) {
  const { t } = useTranslation();
  const { control, handleSubmit } = useForm<EditDescriptionForm>({
    defaultValues: {
      description: secret.description,
    },
    resolver: yupResolver(validationSchema),
  });

  const { mutate, isLoading } = useMutateUpdateSecretDescription(
    { appUuid: applicationUuid, projectUuid },
    {
      onError: () =>
        toast((t) => <ToastContent t={t} success={false} header="applications.secrets.failedDescriptionUpdate" />),
      onSuccess: () => {
        toast((t) => <ToastContent t={t} success={true} header="applications.secrets.successDescriptionUpdate" />);
        onClose();
      },
    }
  );

  const handleUpdateDescription = (data: EditDescriptionForm) => {
    mutate({ secretId: secret.applicationSecretId, description: data.description.trim() });
  };

  return (
    <Modal
      isOpen={isOpen}
      centered
      toggle={onClose}
      className="modal-dialog-centered modal-lg"
      data-testid="edit-description-modal"
    >
      <ModalHeader toggle={onClose} className="bg-transparent py-2"></ModalHeader>
      <ModalBody>
        <h2 className="text-center">{t('applications.secrets.editDescription')}</h2>
        <Form onSubmit={handleSubmit(handleUpdateDescription)}>
          <Input name="description" control={control} required label={t('applications.secrets.description')} />

          <div className="d-flex gap-2 my-2 justify-content-end">
            <SubmitButton color="primary" isLoading={isLoading}>
              {t('general.save')}
            </SubmitButton>
            <Button color="outline-primary" disabled={isLoading} onClick={onClose}>
              {t('general.cancel')}
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
}
