import { Select as BaseSelect } from '@utils/components/Select';
import { errorMessage } from '@utils/errors/errorMessages';
import classNames from 'classnames';
import { useId } from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormFeedback, Label } from 'reactstrap';

/**
 * @typedef {import('@utils/components/Select/Select').SelectProps & FormInputProps} Props
 *
 * @param {Props} props
 * @returns {JSX}
 */
export const Select = ({ control, label, name, required = false, ...selectProps }) => {
  const { field, fieldState } = useController({ name, control });
  const { t } = useTranslation();
  const id = useId();

  return (
    <>
      {label && (
        <Label className={classNames('form-label', required && 'required')} htmlFor={id}>
          {label}
        </Label>
      )}
      <BaseSelect name={name} id={id} invalid={fieldState.error} {...field} {...selectProps} />
      {fieldState.error && <FormFeedback>{errorMessage(t, fieldState.error.message)}</FormFeedback>}
    </>
  );
};
