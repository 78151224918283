/* eslint-disable @typescript-eslint/no-explicit-any */
import { PlatformIcon } from '@src/views/components/platformIcon';
import { useTranslation } from 'react-i18next';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';

interface Props {
  app: any;
  regions: string[];
  onConfigureApp: (app: any) => void;
  onTestApp: (app: any) => void;
}

function SingleApplicationListItem({ app, onConfigureApp, onTestApp, regions }: Props) {
  const { t } = useTranslation();

  return (
    <Col lg={{ size: 4, order: 1 }} sm={{ size: 12 }} xs={{ order: 2 }}>
      <Card className="d-flex align-items-stretch" data-testid="application">
        <CardBody>
          <Row>
            <Col sm={2} className="d-flex align-items-center justify-content-center">
              <PlatformIcon platformName={app.platformName} />
            </Col>
            <Col sm={10}>
              <h4 className="mb-0">{app.name}</h4>
              <p>
                {t('applications.regionsList', {
                  regions: regions?.join(', '),
                })}
              </p>
              <div className="d-flex justify-content-between">
                <Button color="transparent" className="border-0 p-0" onClick={() => onConfigureApp(app)}>
                  <div className="align-middle d-sm-inline-block d-none text-primary">{t('general.configure')}</div>
                </Button>
                <Button color="transparent" className="border-0 p-0" onClick={() => onTestApp(app)}>
                  <div className="align-middle d-sm-inline-block d-none text-primary">{t('general.test')}</div>
                </Button>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
}

export default SingleApplicationListItem;
