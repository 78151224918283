import { useActiveOrganization } from '@utils/hooks/useActiveOrganization';
import ToastContent from '@src/views/components/toastContent/ToastContent';
import toast from 'react-hot-toast';
import { Button, Input, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Trans, useTranslation } from 'react-i18next';
import { Copy, Download } from 'react-feather';
import SubmitButton from '@src/views/components/buttons/SubmitButton/SubmitButton';
import { useMutateCreateDeveloperApiToken } from '@api/hooks/organization/organizationQuery';
import { downloadFile } from '@utils/file';
import Tooltip from '@utils/components/Tooltip';

export default function GenerateTokenModal({ isOpen, toggle }) {
  const organizationUuid = useActiveOrganization();
  const { t } = useTranslation();

  const { data, mutateAsync, isLoading, isSuccess } = useMutateCreateDeveloperApiToken();

  const handleGenerate = () => {
    mutateAsync({ organizationUuid }).catch(() => {
      toast((t) => (
        <ToastContent t={t} success={false} header="organizations.settings.apiTokens.failedToGenerateToken" />
      ));
    });
  };

  const handleCopyToClipboard = () => {
    if (!data?.token) {
      return;
    }

    navigator.clipboard.writeText(data.token);
    toast((t) => <ToastContent t={t} success header="organizations.settings.apiTokens.copiedToClipboard" />);
  };

  const handleDownloadAsTxt = () => {
    if (!data?.token) {
      return;
    }

    const blob = new Blob([data.token], { type: 'text/plain' });
    downloadFile(`api_token_${new Date().toISOString()}.txt`, URL.createObjectURL(blob));
    toast((t) => <ToastContent t={t} success header="organizations.settings.apiTokens.downloaded" />);
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="modal-dialog-centered modal-lg">
      <ModalHeader className="bg-transparent" toggle={toggle} />
      <ModalBody className="pb-3 px-sm-3">
        <h1 className="text-center mb-1">{t('organizations.settings.apiTokens.generateNewToken')}</h1>
        <p className="mb-1">
          <Trans
            i18nKey="organizations.settings.apiTokens.tokenDescription"
            components={{
              b: <b />,
            }}
          />
          <ul className="mt-2">
            <li>{t('organizations.settings.apiTokens.tokenDescriptionClipboard')}</li>
            <li>{t('organizations.settings.apiTokens.tokenDescriptionDownload')}</li>
          </ul>
        </p>
        {isSuccess && (
          <div className="mt-4">
            <p className="mb-1">{t('organizations.settings.apiTokens.tokenSuccess')}</p>
            <div className="d-flex justify-content-center gap-2">
              <Input readOnly value={data.token || ''} data-testid="api-token-value" />
              <Tooltip text={t('organizations.settings.apiTokens.tokenDescriptionClipboard')}>
                <Button outline color="success" onClick={handleCopyToClipboard} data-testid="api-token-copy-clipboard">
                  <Copy />
                </Button>
              </Tooltip>
              <Tooltip text={t('organizations.settings.apiTokens.tokenDescriptionDownload')}>
                <Button outline color="primary" onClick={handleDownloadAsTxt} data-testid="api-token-download">
                  <Download />
                </Button>
              </Tooltip>
            </div>
          </div>
        )}
        <div className="d-flex justify-content-center mt-4">
          <SubmitButton
            isLoading={isLoading}
            disabled={isSuccess}
            onClick={handleGenerate}
            color="primary"
            data-testid="generate-api-token"
          >
            {t('organizations.settings.apiTokens.generate')}
          </SubmitButton>
        </div>
      </ModalBody>
    </Modal>
  );
}
