import * as yup from 'yup';

export const billingStepSchema = yup.object().shape({
  companyName: yup.string().max(256).required().trim(),
  taxId: yup.string().max(256).required().trim(),
  vatNumber: yup.string().max(256).required().trim(),
  country: yup.object().required().nullable(),
  billingAddress: yup.string().max(1024).required().trim(),
  state: yup.string().max(256).required().trim(),
  zipCode: yup.string().max(64).required().trim(),
});
