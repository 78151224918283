// ** React Imports
import { Fragment } from 'react';

// ** Custom Components
import NavbarUser from './NavbarUser';
import NavbarBookmarks from './NavbarBookmarks';
import { Label } from 'reactstrap';
import Select from 'react-select';
import { selectThemeColors } from '../../../../utility/Utils';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';

import { useQueryProjects } from '@api/hooks/project/projectQuery';
import { useActiveOrganization } from '@utils/hooks/useActiveOrganization';
import { useActiveProject } from '@utils/hooks/useActiveProject';
import { useProjectStore } from '@utils/hooks/useProjectStore';
import { CustomOption } from '@utils/components/Select/Option';

const ThemeNavbar = ({ skin, setSkin, setMenuVisibility }) => {
  const organizationUuid = useActiveOrganization();
  const projectUuid = useActiveProject();
  const { onSaveProject } = useProjectStore();
  const { data: projects, isFetching } = useQueryProjects({ organizationUuid }, { enabled: !!organizationUuid });
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const showProjectsSelect =
    [
      matchPath('/projects*', pathname),
      matchPath('/organizations*', pathname),
      matchPath('/account/*', pathname),
    ].filter(Boolean).length === 0;

  const projectOptions = [
    {
      label: 'See all projects',
      value: null,
    },
    {
      label: 'Add a project',
      value: null,
    },
  ];

  projects?.data.map(async (projectObj) => {
    projectOptions.push({
      value: projectObj?.uuid,
      label: projectObj?.name,
    });
  });

  const selectedProject = projectOptions.find((project) => project.value === projectUuid);

  const handleProjectChanged = async (e) => {
    if (e.value === null) {
      navigate('/projects');
    } else {
      onSaveProject({ projectUuid: e.value });
    }
  };

  return (
    <Fragment>
      <div className="bookmark-wrapper d-flex align-items-center">
        {showProjectsSelect && !isFetching && (
          <>
            <NavbarBookmarks setMenuVisibility={setMenuVisibility} />
            <Label className="form-label me-2 mb-0">Project</Label>
            <Select
              theme={selectThemeColors}
              className="react-select projects-select"
              classNamePrefix="select"
              name="project-switch"
              value={selectedProject}
              options={projectOptions}
              onChange={(e) => handleProjectChanged(e)}
              isClearable={false}
              components={{
                Option: CustomOption,
              }}
            />
          </>
        )}
      </div>
      <NavbarUser skin={skin} setSkin={setSkin} />
    </Fragment>
  );
};

export default ThemeNavbar;
