import { useMutateDeleteSecret } from '@api/hooks/application/applicationQuery';
import ToastContent from '@src/views/components/toastContent/ToastContent';
import { useOverlayContext } from '@utils/context/OverlayContext/useOverlayContext';
import toast from 'react-hot-toast';

interface Props {
  applicationUuid: string;
  projectUuid: string;
}
export function useDeleteSecret({ applicationUuid, projectUuid }: Props) {
  const { mutate } = useMutateDeleteSecret({
    appUuid: applicationUuid,
    projectUuid,
  });
  const [showLoad, hideLoad] = useOverlayContext();

  const handleDeleteSecret = async (secretId) => {
    showLoad();
    mutate(
      {
        secretId,
      },
      {
        onError: () => toast((t) => <ToastContent t={t} success={false} header="applications.failedSecretDeleted" />),
        onSuccess: () => {
          toast((t) => <ToastContent t={t} success={true} header="applications.successSecretDeleted" />);
        },
        onSettled() {
          hideLoad();
        },
      }
    );
  };

  return {
    onDeleteSecret: handleDeleteSecret,
  };
}
