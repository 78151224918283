export const PATHS = {
  ROOT: '/',
  PROJECTS: {
    NAME: 'projects',
    FULL_PATH: '/projects',
  },
  ENVIRONMENT: {
    NAME: 'environment',
    FULL_PATH: '/environment',
  },
  ORGANIZATIONS: {
    NAME: 'organizations',
    FULL_PATH: '/organizations',
  },
  REQUEST_DEMO: {
    NAME: 'request-a-demo',
    FULL_PATH: '/request-a-demo',
  },
  PROJECT: {
    APPLICATION: {
      NAME: 'application',
      FULL_PATH: '/application',
    },
    PROJECT_SETTINGS: {
      NAME: 'project-settings',
      FULL_PATH: '/project-settings',
    },
  },
  ACCOUNT: {
    ROOT: '/account',
    ORGANIZATION: {
      NAME: 'organization',
      FULL_PATH: '/account/organization',
    },
    COST_EXPLORER: {
      NAME: 'cost-explorer',
      FULL_PATH: '/account/cost-explorer',
    },
    PROFILE: {
      NAME: 'profile',
      FULL_PATH: '/account/profile',
    },
  },
  AUTH: {
    ROOT: '/auth',
    NOT_AUTH: {
      NAME: 'auth-path',
      FULL_PATH: '/auth/not-auth',
    },
    LOGIN: {
      NAME: 'login',
      FULL_PATH: '/auth/login',
    },
    REGISTER: {
      NAME: 'register',
      FULL_PATH: '/auth/register',
    },
    FORGOT_PASSWORD: {
      NAME: 'forgot-password',
      FULL_PATH: '/auth/forgot-password',
    },
    TOKEN_LOGIN: {
      NAME: 'token-login',
      FULL_PATH: '/auth/token-login',
    },
    RESET_PASSWORD: {
      NAME: 'reset-password',
      FULL_PATH: '/auth/reset-password',
    },
  },
  EXTERNAL: {
    TERMS_AND_CONDITIONS: 'https://sync-stage.com/developerservicesagreement/',
    PRIVACY_POLICY: 'https://sync-stage.com/policy/',
    DOCUMENTATION: {
      ANDROID: 'https://docs.sync-stage.com/android/test-app/',
      IOS: 'https://docs.sync-stage.com/ios/test-app/',
      WEB: 'https://docs.sync-stage.com/web/test-app/',
    },
    WEB_SDK_TEST_APPS: {
      ANDROID: 'https://appdistribution.firebase.dev/i/8c8fdd1218c411f4',
      IOS: 'https://testflight.apple.com/join/0ZkhydkU',
      WEB: 'https://syncstage.web.app/setup',
    },
  },
};
