import * as yup from 'yup';

export const applyForAccessCodeSchema = yup.object().shape({
  firstName: yup.string().trim().required().min(2).max(100),
  lastName: yup.string().trim().required().min(2).max(100),
  emailAddress: yup.string().email().trim().required().max(256),
  company: yup.string().trim().max(256),
  country: yup.string(),
  category: yup.string().required(),
  terms: yup.bool().oneOf([true], 'errors.mustBeChecked').required(),
});
