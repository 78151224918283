// eslint-disable-next-line unused-imports/no-unused-imports
import { useQueryApplicationsInOrganization } from '@api/hooks/application/applicationQuery';
import { useActiveOrganization } from '@utils/hooks/useActiveOrganization';

/**
 * @param {Omit<UseQueryOptions<ApplicationsInOrganization[]>, "queryKey" | "queryFn">} [options]
 */
export function useApplicationsInOrganization(options) {
  const organizationUuid = useActiveOrganization();

  return useQueryApplicationsInOrganization(
    { organizationUuid },
    {
      ...options,
      enabled: options?.enabled && !!organizationUuid,
    }
  );
}
