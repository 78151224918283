import Breadcrumbs from '@components/breadcrumbs';
import { useState } from 'react';
import { Cloud, Flag, Lock, Key } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { Button, Row } from 'reactstrap';
import { ApiTokens, General } from './tabs';
import UsersAndPermissions from './tabs/usersAndPermissions/UsersAndPermissions';

const TABS = {
  GENERAL: 'general',
  USERS_AND_PERMISSIONS: 'users_and_permissions',
  BILLING_AND_PAYMENTS: 'billing_and_payments',
  API_TOKENS: 'api_tokens',
};

const OrganizationSettings = () => {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState(TABS.GENERAL);

  const handleSetTab = (value) => {
    setCurrentTab(value);
  };

  return (
    <div className="px-4">
      <Breadcrumbs title={''} data={[{ title: 'Organization' }, { title: 'Settings' }]} />
      <Row className="m-0 d-flex justify-content-start">
        <Button
          className="w-auto"
          color={`${currentTab === TABS.GENERAL ? 'primary' : 'transparent'}`}
          onClick={() => handleSetTab(TABS.GENERAL)}
        >
          <Cloud /> {t('organizations.settings.general.title')}
        </Button>
        <Button
          className="w-auto"
          color={`${currentTab === TABS.USERS_AND_PERMISSIONS ? 'primary' : 'transparent'}`}
          onClick={() => handleSetTab(TABS.USERS_AND_PERMISSIONS)}
          data-testid="users-and-permissions-tab"
        >
          <Lock /> {t('organizations.settings.usersAndPermissions.title')}
        </Button>
        <Button
          disabled
          className="w-auto"
          color={`${currentTab === TABS.BILLING_AND_PAYMENTS ? 'primary' : 'transparent'}`}
        >
          <Flag /> {t('organizations.settings.billingAndPayments.title')}
        </Button>
        <Button
          className="w-auto"
          color={`${currentTab === TABS.API_TOKENS ? 'primary' : 'transparent'}`}
          onClick={() => handleSetTab(TABS.API_TOKENS)}
          data-testid="api-tokens-tab"
        >
          <Key /> {t('organizations.settings.apiTokens.title')}
        </Button>
      </Row>
      {currentTab === TABS.GENERAL && <General />}
      {currentTab === TABS.USERS_AND_PERMISSIONS && <UsersAndPermissions />}
      {currentTab === TABS.API_TOKENS && <ApiTokens />}
    </div>
  );
};

export default OrganizationSettings;
