import { Trans, useTranslation } from 'react-i18next';
import Steps from './Steps';
import { getDocumentationLink } from './helpers';

type Props = {
  onOpenConfigureModal: () => void;
  platform: string;
  className?: string;
};

export default function BuildSyncStageSteps({ onOpenConfigureModal, platform, className }: Props) {
  const { t } = useTranslation();

  return (
    <div className={className} data-testid="build-own-sdk-steps">
      <h4>{t('applications.testApp.buildTestApp.title')}</h4>
      <Steps title={t('applications.testApp.buildTestApp.steps')}>
        <Steps.Item>
          <Trans
            i18nKey="applications.testApp.buildTestApp.firstStep"
            components={{
              a: <span className="text-primary cursor-pointer" onClick={onOpenConfigureModal} />,
            }}
          />
        </Steps.Item>
        <Steps.Item>
          <Trans
            i18nKey="applications.testApp.buildTestApp.secondStep"
            components={{
              a: <a href={getDocumentationLink(platform)} target="_blank" rel="noreferrer" />,
            }}
          />
        </Steps.Item>
        <Steps.Item>{t('applications.testApp.buildTestApp.thirdStep')}</Steps.Item>
        <Steps.Item>{t('applications.testApp.buildTestApp.fourthStep')}</Steps.Item>
      </Steps>
    </div>
  );
}
