import { PATHS } from '@src/router/paths';
import { useProjectStore } from '@utils/hooks/useProjectStore';
import { useNavigate } from 'react-router-dom';

export function useSelectProject() {
  const navigate = useNavigate();
  const store = useProjectStore();

  const handleSelectProject = ({ projectUuid }) => {
    store.onSaveProject({ projectUuid });

    navigate(PATHS.PROJECT.APPLICATION.FULL_PATH);
  };

  return {
    onSelectProject: handleSelectProject,
  };
}
