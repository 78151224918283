import themeConfig from '@configs/themeConfig';
import { StateButton } from '@src/views/components/buttons';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, CardBody, CardText, CardTitle, Col, Row } from 'reactstrap';
import '@src/assets/scss/pages/request-demo.scss';
import ApplyForCode from './applyForCode';
import SubmitCode from './submitCode';
import { useSearchParams, useNavigate } from 'react-router-dom';

const TABS = {
  APPLY_FOR_CODE: 'apply_for_code',
  SUBMIT_CODE: 'submit_code',
};

const RequestDemo = () => {
  const [queryParams] = useSearchParams();
  const accessCode = queryParams.get('accessCode') || '';
  const [sent, setSent] = useState(false);

  const { t } = useTranslation();
  const [currentTab, setTab] = useState(TABS.APPLY_FOR_CODE);

  const handleTabChange = (tab) => () => {
    setTab(tab);
  };

  useEffect(() => {
    if (!accessCode) return;

    setTab(TABS.SUBMIT_CODE);
  }, [accessCode]);

  const navigate = useNavigate();

  useEffect(() => {
    if (sent) {
      setTimeout(() => {
        window.location.href = 'https://sync-stage.com/';
      }, 5000);
    }
  }, [navigate, sent]);

  return (
    <Card className="m-auto px-2 py-1 request-demo-card">
      <img src={themeConfig.app.appLogoFullImage} alt="logo" height={50} className="mt-2 mb-2" />
      {sent ? (
        <CardBody>
          <CardTitle tag="h2">Request sent</CardTitle>
          <CardText>Thanks for applying for the SyncStage Early Access Program!</CardText>
          <CardText>Your dedicated SyncStage consultant will reach you within 48 hours.</CardText>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button tag="a" href="https://sync-stage.com/" color="outline-primary" className="btn-sm-block mb-2">
              Go to home page now
            </Button>
          </div>
          <CardText>... or you will be automatically redirected in 5 seconds.</CardText>
        </CardBody>
      ) : (
        <CardBody>
          <CardTitle tag="h2" className="fw-bold mb-1">
            {t('requestDemo.title')}
          </CardTitle>
          <CardText className="mb-3">{t('requestDemo.description')}</CardText>
          <Row className="mb-2">
            <Col md="12" lg="6">
              <StateButton active={currentTab === TABS.APPLY_FOR_CODE} onClick={handleTabChange(TABS.APPLY_FOR_CODE)}>
                {t('requestDemo.applyForCode')}
              </StateButton>
            </Col>
            <Col md="12" lg="6">
              <StateButton active={currentTab === TABS.SUBMIT_CODE} onClick={handleTabChange(TABS.SUBMIT_CODE)}>
                {t('requestDemo.alreadyHaveCode')}
              </StateButton>
            </Col>
          </Row>
          {currentTab === TABS.APPLY_FOR_CODE && <ApplyForCode setSent={setSent} />}
          {currentTab === TABS.SUBMIT_CODE && <SubmitCode accessCode={accessCode} />}
        </CardBody>
      )}
    </Card>
  );
};

export default RequestDemo;
