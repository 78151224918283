import toast from 'react-hot-toast';
import { useMutateCreateDefaultApp } from '@api/hooks/auth/authQuery';
import ToastContent from '@src/views/components/toastContent';
import { useSelectProject } from '../../project/hooks/useSelectProject';

export function useApplicationSetup() {
  const result = useMutateCreateDefaultApp();
  const { onSelectProject } = useSelectProject();

  const handleSetupEnvironment = () => {
    result.mutate(void 0, {
      onError: () => {
        toast((t) => <ToastContent t={t} success={false} header="login.failedCreatingEnvironment" />);
      },
      onSuccess: (data) => {
        onSelectProject({ projectUuid: data.project.uuid });
      },
    });
  };

  return { ...result, onSetupEnvironment: handleSetupEnvironment };
}
