import { Checkbox } from '@utils/components/form/Inputs';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Card, CardBody, CardHeader, Row } from 'reactstrap';
import { deleteProjectSchema } from './schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { PROJECTS_QUERY_KEY, useMutateDeleteProject } from '@api/hooks/project/projectQuery';
import { useOverlayContext } from '@utils/context/OverlayContext/useOverlayContext';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '@src/router/paths';
import ToastContent from '@src/views/components/toastContent/ToastContent';
import toast from 'react-hot-toast';

/**
 *
 * @param {Object} props
 * @param {Object} props.project
 * @param {Object} props.organization
 * @returns {JSX}
 */
function ProjectDeletion({ project, organization }) {
  const { t } = useTranslation();
  const { control, formState } = useForm({
    defaultValues: {
      accept: false,
    },
    resolver: yupResolver(deleteProjectSchema),
    mode: 'onChange',
  });

  const { mutateAsync } = useMutateDeleteProject();
  const [showLoading, hideLoading] = useOverlayContext();
  const client = useQueryClient();
  const navigate = useNavigate();

  const handleProjectDelete = () => {
    showLoading();
    mutateAsync({ projectUuid: project?.uuid })
      .then(() => {
        client.refetchQueries({
          queryKey: PROJECTS_QUERY_KEY({
            organizationUuid: organization?.uuid,
          }),
        });
        navigate(PATHS.PROJECTS.FULL_PATH);
        toast((t) => <ToastContent t={t} success={true} header="projects.successProjectDeleted" />);
      })
      .catch(() => {
        toast((t) => <ToastContent t={t} header="projects.failedProjectDeleted" />);
      })
      .finally(() => {
        hideLoading();
      });
  };

  return (
    <Card className="p-0">
      <CardHeader className="border border-bottom">
        <h4 className="m-0">{t('projects.deleteProject')}</h4>
      </CardHeader>
      <CardBody>
        <Row className="m-0 mt-2">
          <h4 className="text-warning p-0 mb-0">{t('general.warning')}</h4>
          <p className="text-warning p-0">{t('projects.deleteProjectSubtitle')}</p>
        </Row>
        <Row className="m-0 mt-1">
          <Checkbox control={control} name="accept" label={t('projects.deleteProjectConfirm')} />
        </Row>
        <Row className="m-0 form-check ps-0 mt-1">
          <Button
            className="w-auto m-0"
            color="danger"
            onClick={handleProjectDelete}
            disabled={!formState.isValid}
            data-testid="delete-project"
          >
            {t('projects.deleteProject')}
          </Button>
        </Row>
      </CardBody>
    </Card>
  );
}

export default ProjectDeletion;
