import Breadcrumbs from '@components/breadcrumbs';
import { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { useCostData } from './hooks/useCostData';
import CostTable from './costTable';
import UsageSummary from './usageSummary';
import UsagePeriod from './usagePeriod';
import { usePaginate } from '@utils/hooks/usePaginate';
import moment from 'moment';
import { getPeriodOption } from './helpers';
import { useCostDataExport } from './hooks/useCostDataExport';
import { FormProvider, useForm } from 'react-hook-form';

/**
 *
 * @typedef {object} Filters
 * @property {number} rowsPerPage
 * @property {SelectOption} application
 * @property {SelectOption} project
 */

const CostExplorer = () => {
  const { t } = useTranslation();
  const {
    pagination: { rowsPerPage, currentPage, skip },
    onPageChange,
    onPerPage,
  } = usePaginate();
  const formMethods = useForm({
    defaultValues: {
      project: { label: t('costExplorer.allProjects'), value: '' },
      application: { label: t('costExplorer.allProjects'), value: '' },
      rowsPerPage: { label: rowsPerPage, value: rowsPerPage },
      requestDates: getPeriodOption(moment()),
    },
  });

  const filters = formMethods.watch();

  const { data: usage } = useCostData({
    from: filters.requestDates.value.from,
    to: filters.requestDates.value.to,
    take: rowsPerPage,
    skip,
    projectUuid: filters.project.value,
    applicationUuid: filters.application.value,
  });

  const { onExport: onExportAsCSV } = useCostDataExport({
    from: filters.requestDates.value.from,
    to: filters.requestDates.value.to,
  });

  useEffect(() => {
    const { value: formRowsPerPage } = formMethods.watch('rowsPerPage');

    if (formRowsPerPage !== rowsPerPage) {
      onPerPage(formRowsPerPage);
    }
  }, [formMethods, onPerPage, rowsPerPage]);

  return (
    <Fragment>
      <Breadcrumbs title={''} data={[{ title: t('general.account') }, { title: t('costExplorer.title') }]} />
      <h4>{t('costExplorer.title')}</h4>
      <p>{t('costExplorer.description')}</p>
      <FormProvider {...formMethods}>
        <Row>
          <Col sm={8}>
            <UsageSummary usage={usage} />
          </Col>
          <Col sm={4}>
            <UsagePeriod />
          </Col>
        </Row>
        <Col sm={12}>
          <Card className="mt-1">
            <CardBody>
              <h4 className="mb-2">{t('costExplorer.detailedUsagePerUser')}</h4>
              <CostTable
                onExportAsCSV={onExportAsCSV}
                onPageChange={onPageChange}
                currentPage={currentPage}
                rowsPerPage={rowsPerPage}
                skip={skip}
                usage={usage}
              />
            </CardBody>
          </Card>
        </Col>
      </FormProvider>
    </Fragment>
  );
};

export default CostExplorer;
