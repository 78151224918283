import { AcceptanceRequestedAuthor, GetProvisioningCodeStatusResponse } from '@api/useApplicationApi.types';
import SubmitButton from '@src/views/components/buttons/SubmitButton/SubmitButton';
import Descriptions from '@utils/components/Descriptions';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Alert, ToastBody, ToastHeader } from 'reactstrap';
import { CONFIGURE_APP_MODES } from '../../consts';
import { AppValues, ConfigurationModalMode } from '../../types';
import { useCodeAcceptance } from './useCodeAcceptance';
import UILoader from '@components/ui-loader';

type CodeStatus = Omit<GetProvisioningCodeStatusResponse, 'acceptanceRequestedBy'> & {
  acceptanceRequestedBy: AcceptanceRequestedAuthor;
};

type Props = {
  appValues: AppValues;
  onChangeMode: (mode: ConfigurationModalMode) => void;
  codeStatus: CodeStatus;
};

export default function ProvisioningAcceptance({ appValues, onChangeMode, codeStatus }: Props) {
  const { t } = useTranslation();
  const { acceptCode, rejectCode, isAccepting, isRejecting, codeAccepted } = useCodeAcceptance({
    applicationUuid: appValues.uuid,
    provisioningCode: codeStatus.provisioningCode,
    onFinish() {
      onChangeMode(CONFIGURE_APP_MODES.GENERAL);
    },
  });

  const { acceptanceRequestedBy } = codeStatus;

  if (codeAccepted) {
    return <UILoader blocking className="modal-form-content" />;
  }

  return (
    <>
      <Descriptions title={t('applications.provisioningAcceptance.requestDetails')}>
        <Descriptions.Item label={t('applications.provisioningAcceptance.operatingSystem')}>
          <span data-testid="operating-system">{acceptanceRequestedBy.operatingSystem}</span>
        </Descriptions.Item>
        <Descriptions.Item label={t('applications.provisioningAcceptance.model')}>
          <span data-testid="model">{acceptanceRequestedBy.model}</span>
        </Descriptions.Item>
        <Descriptions.Item label={t('applications.provisioningAcceptance.ipAddress')}>
          <span data-testid="ip-address">{acceptanceRequestedBy.ipAddress}</span>
        </Descriptions.Item>
        <Descriptions.Item label={t('applications.provisioningAcceptance.timestamp')}>
          <span data-testid="acceptance-requested-at">{moment(codeStatus.acceptanceRequestedAt).toLocaleString()}</span>
        </Descriptions.Item>
        <Descriptions.Item label={t('applications.provisioningAcceptance.sdkVersion')}>
          <span data-testid="sdk-version">{acceptanceRequestedBy.sdkVersion}</span>
        </Descriptions.Item>
      </Descriptions>

      <Alert color="warning" className="my-2" data-testid="provisioning-warning">
        <ToastHeader className="alert-warning">{t('applications.provisioningAcceptance.warning')}</ToastHeader>
        <ToastBody>{t('applications.provisioningAcceptance.warningText')}</ToastBody>
      </Alert>

      <div className="d-flex justify-content-end gap-2">
        <SubmitButton
          isLoading={isAccepting}
          disabled={isRejecting}
          onClick={() => acceptCode({ applicationUuid: appValues.uuid, provisioningCode: codeStatus.provisioningCode })}
          color="primary"
          data-testid="accept-provisioning"
        >
          {t('general.accept')}
        </SubmitButton>
        <SubmitButton
          isLoading={isRejecting}
          disabled={isAccepting}
          onClick={() => rejectCode({ applicationUuid: appValues.uuid, provisioningCode: codeStatus.provisioningCode })}
          color="outline-secondary"
          data-testid="reject-provisioning"
        >
          {t('general.reject')}
        </SubmitButton>
      </div>
    </>
  );
}
